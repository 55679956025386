import { EventEmitter, Input, OnInit, Output, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { GDocService } from 'src/modules/app-common/services/g-doc/g-doc.service';
import { EventPerson } from '../../models/event/event-person.model';
import { GcalEvent } from '../../models/event/gcal-event.model';
import { CalendarService } from '../../services/calendar.service';
import { EventAnswerComponent } from '../event-answer/event-answer.component';

const searchRoute = environment.searchRoute
const themePath = environment.themePath;
@Component({
  selector: 'app-event-item',
  templateUrl: `./${themePath}/event-item.component.html`,
  styleUrls: [`./${themePath}/event-item.component.scss`]
})
export class EventItemComponent implements OnInit {

  public static instance : EventItemComponent

  constructor(private calendarService: CalendarService, private router: Router, private dialog: MatDialog ,private gdocService: GDocService ) {
    EventItemComponent.instance = this;
 
  }
  ngOnInit(): void {
   }
  @Input('eventItem') eventItem: GcalEvent


  linkCalendar = environment.calendarLink;
  async meetOptionClick(event: GcalEvent,response: string){
     event = await  this.calendarService.setMeetingResponse(event,response);


 

    this.emitState.emit({relaunchEventsReq: true})

    // this.ngZone.run(() => {
    //   this.events = []
    //   this.webEvents = []
    //  });
 
 
 
   }
 
   


  toLink(p){
    if(p!=null && p!=undefined){
      window.open(p, '_blank');

    }
  }

  eventSearch(p){
    this.router.navigate(searchRoute,{queryParams:{q:p, d: localStorage.host_domain}});

  }
  toEvents(){
    window.open(this.linkCalendar, '_blank');
  }

 
   @Output() emitState: EventEmitter<any> = new EventEmitter();

   async cancelAnswer(eventPerson: EventPerson){
     let response = environment.stringsFile.allStatus;
     eventPerson.selfStatus = "needsAction";
     eventPerson.setDisplayedSelfStatus(eventPerson.getSelfStatus());
 
   }

   acceptStatus = environment.stringsFile.acceptStatus;
   declineStatus = environment.stringsFile.declineStatus;
   maybeStatus = environment.stringsFile.maybeStatus;
 
   meetingOptions = [this.acceptStatus,this.declineStatus,this.maybeStatus]


   showStatus = false;
   answerMeeting(person: EventPerson){
    if(person.isSelf==true){
      const dialogRef = this.dialog.open(EventAnswerComponent, {
        width: "650px",
        data: {
          eventItem: this.eventItem
        },
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        this.emitState.emit({relaunchEventsReq: result?.relaunchEventsReq})
      });
      this.showStatus=true;

    }
   }

  openPopup(eventItem:GcalEvent){


 
    const dialogRef = this.dialog.open(EventAnswerComponent, {
      width: "650px",
      data: {
        eventItem: this.eventItem
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.emitState.emit({relaunchEventsReq: result?.relaunchEventsReq})
    });
  }
 
  

}
