import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { MaterialModule } from '../material/material.module';
import * as userContainers from './containers';
import * as userServices from './services';
import * as userModels from './models';


import { AppCommonModule } from '../app-common/app-common.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OauthPsoftClientModule } from 'oauth-psoft-client';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    ...userContainers.containers
  ],

  imports: [
    AppCommonModule,
    ReactiveFormsModule,
    CommonModule,
    UserRoutingModule,
    MaterialModule,
    FormsModule,
    OauthPsoftClientModule,
    TranslateModule,

  ],

  providers: [
    ...userServices.services,
    ...userModels.models,
    AppCommonModule
  ],
  exports: [UserRoutingModule, ...userContainers.containers]
})

export class UserModule { }
