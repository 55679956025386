import { SearchGdriveService } from "src/modules/gdrive/services/search-gdrive.service";
import { SearchGcalService } from "src/modules/gcal/services/search-gcal.service";
import { SearchGcontactService } from "src/modules/gcontact/services/search-gcontact.service";
import { SearchGcontact2Service } from "src/modules/gcontact2/services/search-gcontact2.service";
import { SearchGpeopleService } from "src/modules/gpeople/services/search-gpeople.service";
import { SearchGcsService } from "src/modules/gcs/services/search-gcs.service";
import { SearchGcontributorService } from "src/modules/gcontributor/services/search-gcontributor.service";

//ALL ENGINE SERVICES HERE
export const engineServices = [
    SearchGdriveService,
    SearchGcontactService,
    SearchGcontact2Service,
    SearchGcalService,
    SearchGpeopleService,
    SearchGcsService,
    SearchGcontributorService,
]

export * from "src/modules/gdrive/services/search-gdrive.service";
export * from "src/modules/gcal/services/search-gcal.service";
export * from "src/modules/gcontact/services/search-gcontact.service";
export * from "src/modules/gcontact2/services/search-gcontact2.service";
export * from "src/modules/gdrive/services/search-gdrive.service";
export * from "src/modules/gpeople/services/search-gpeople.service";
export * from "src/modules/gcs/services/search-gcs.service";
export * from "src/modules/gcontributor/services/search-gcontributor.service";