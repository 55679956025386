import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { APISce } from "src/modules/app-common/services";
// import { GoogleLoginService } from "src/modules/user/services";
import { Workspace } from "../models/workspace";
import { UserService } from "./user/user.service";

const BACKEND_URL = environment.BACKEND_URL;

@Injectable({
  providedIn: "root",
})
export class WorkplaceService {
  _workSpace: Workspace;
  withEvents = true;
  withRecentContacts = false;

  constructor(
    private api: APISce,
    private userservice: UserService) {
    this.withRecentContacts = environment.HP_CONTACTS;
    this.withEvents = environment.HP_EVENTS;
  }

  async loadMyWorkspace(fileInfos = null, fields = "*", withPath = false) {
    let workspace = await this.getWorkspace(fileInfos = null, fields = "*", withPath = false);
    this._workSpace = workspace;

    return this._workSpace
  }

  async getWorkspace(fileInfos = null, fields = "*", withPath = false) {
    let params = { withPath };
    if (fields) {
      params["fields"] = fields;
    }

    let url = `/workspace/home`;
    let options = await this.api.httpConfig(false);

    try {
      let data = await this.api.get(url, options, params)

      if (data["content"]['errors'] == undefined) {
        return data["content"];
      }
      else {
        // window.location.reload()
        // this.googleLoginService.signOut() // to be seen after // ag
      }
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }


  // Save data in cache
  _workSpaces: Workspace;

  async getWorkspaceData(fileInfos = null, fields = "*", withPath = false): Promise<Workspace> {
    let params = { withPath };
    if (fields) {
      params["fields"] = fields;
    }


    let url = `/workspace/home`;

    let options = await this.api.httpConfig(true, false);


    try {

      let data = await this.api.get(url, params, options);
      let mustLogin = false;
      if (data["content"]['errors'] != undefined) {
        data["content"]['errors'].forEach((error) => {
          if (error.widget == 'gcalendar')
            this.withEvents = false;
          else
            mustLogin = true;
        });
      }

      if (!mustLogin) {
        let ws = data["content"];
        this._workSpaces = new Workspace(ws, this.withEvents, this.withRecentContacts);


        if (this.withRecentContacts)
          this.userservice.cacheUsers(this._workSpaces.getRecentContacts());

        return this._workSpaces
      }
      else {
        // window.location.reload()
        // this.googleLoginService.signOut();  // iwa lla // to be seen after
      }
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }
}
