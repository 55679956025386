import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APISce } from 'src/modules/app-common/services';
import { GedFile, GedTask,IUserTaskData } from '../model/ged-task.model';

import { DebugLog } from "src/modules/app-common/services/log/log.service"
import { environment } from 'src/environments/environment';
import { TFileProperties } from "../model/ged-task.model";
import { filter, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/modules/user/services';
let console = DebugLog.build("Tasks","green");

@Injectable({
  providedIn: 'root'
})
export class TaskService
{

  constructor(
    private router: Router,
    private api: APISce,
    private auth: AuthService, 
  ) {

  }

  openDocument(task: GedTask, prefixUrl: string = null)
  {
    let url = prefixUrl + '/file/' + task.getUid();
    this.router.navigate([url], { queryParams: { d: localStorage.host_domain } });
  }

  async getTasks()
  {
    //let url = `/ged/documents/workflows/mytasks`;
    let url = `/workflow/mytasks`;

    try {

      let tasks : {content:{

        mytasks:{
          items: IUserTaskData[]
        },

        group_tasks:{
          items: IUserTaskData[]
        }
      }} = await this.api.get(url);

      let allTasks = tasks?.['content'];

      let index = 0;
      let tasksJson =
      {
        myTasks: (allTasks?.mytasks?.items || [])
          .map(t => new GedTask(t,index++, environment.stringsFile.taskForMe, 'my-task'))
          .filter(task => task.getUid() != null),

        groupTasks: (allTasks?.group_tasks?.items || [])
          .map(t => new GedTask(t,index++, environment.stringsFile.taskForMyTeam, 'task-team'))
          .filter(task => task.getUid() != null)
      }

      return tasksJson;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async getFiles(profile): Promise<GedFile[]>
  {
    const owner = { value: profile.email, html: profile.name };

    const search = '{"labels":{"html":"Flagged","value":"flag@flag.appProperties"},"owner":' + JSON.stringify(owner) + '}';
    
    const search64  = btoa(search);

    const params: any = {
      search64,
      all: true,
      withFolders: false,
      nbItems: 25
    };

    let files : {
      content:{
        files:TFileProperties[]     
      }               
    } = await this.api.get('/ged/documents/files/search/',
    params);
    
    let allFiles = files?.['content']['files'];
    let index = 0;
      let filesJson =
      {
        myFiles: (allFiles || [])
          .map(f => new GedFile(f,index++)),
      }

      return filesJson.myFiles;

  }


  async getFlaggedFiles(): Promise<GedFile[]> {
    try {
      const user = await this.auth.profileSubject.pipe(
        filter(Boolean),
        take(1) // Utilisez take(1) pour s'assurer que l'observable se termine après la première émission
      ).toPromise();
  
      const files = this.getFiles(user);
      return files;
    } catch (error) {
      console.error('Une erreur s\'est produite lors de la récupération des fichiers :', error);
      throw error; // Vous pouvez choisir de gérer l'erreur de manière appropriée ici
    }
  }
}