import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-doc-avator-creator',
    templateUrl: './doc-avator-creator.component.html',
    styleUrls: ['./doc-avator-creator.component.scss']
})
export class DocAvatorCreatorComponent {

    @Input()
    public photoUrl: string;

    @Input()
    public name: string;

    @Input()
    public icone: string = undefined; // Ajout de la propriété pour vérification

    @Input()
    public classIcone: {backgroundColor:string, textColor:string};


    public showInitials = false;
    public initials: string;
    public circleColor: string;

    tableColors: string[] = [
        "#FF5733", // Orange
        "#C70039", // Rouge foncé
        "#900C3F", // Bordeaux
        "#581845", // Violet foncé
        "#FFC300", // Jaune
        "#FF5733", // Orange
        "#DAF7A6", // Vert clair
        "#1287A5", // Bleu foncé
        "#FFC300", // Jaune
        "#900C3F", // Bordeaux
        "#FF5733", // Orange
        "#1287A5", // Bleu foncé
        "#900C3F", // Bordeaux
        "#FFC300", // Jaune
        "#FF5733", // Orange
    ];

    ngOnInit() {
        if (!this.photoUrl) {
            this.showInitials = true;
            this.createInititals()
        }
    }

    private createInititals(): void {
        let initials = "";

        for (let i = 0; i < this.name?.length; i++) {
            if (this.name.charAt(i) === ' ') {
                continue;
            }

            if (this.name.charAt(i) === this.name.charAt(i).toUpperCase()) {
                initials += this.name.charAt(i);

                if (initials.length == 2) {
                    break;
                }
            }

        }
        if (initials.length == 1){
            initials += this.name.charAt(1).toUpperCase();
        }

        const colIndex = (initials.charCodeAt(0) + initials.charCodeAt(1)) * 13 % this.tableColors.length;
        this.circleColor = this.tableColors[colIndex];

        this.initials = initials;
    }
}