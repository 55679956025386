import { DatePipe } from "@angular/common";
import { EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Component } from "@angular/core";
import { environment } from "src/environments/environment";
import { GedService } from "src/modules/app-common/services";
import { WorkflowService } from "src/modules/workflow/services";
import { WorkflowUserTask } from "src/modules/workflow/models";

import { GedTask,IUserTaskData } from "src/modules/tasks/model/ged-task.model"


const themePath = environment.themePath;
@Component({
  selector: "app-doc-workflow-historic",
  templateUrl: `./${themePath}/doc-workflow-historic.component.html`,
  styleUrls: [`./${themePath}/doc-workflow-historic.component.scss`],
})
export class DocWorkflowHistoricComponent implements OnInit
{
  @Input("path") path;
  gedDoc

  @Input() set _gedDoc(gedDoc) {
    this.gedDoc = gedDoc;
    // this.initData(this.currentDocId);
  }

  @Output() htState: EventEmitter<any> = new EventEmitter();

  isLoadingState = true;

  historiques = [];

  doc;
  currentDocId = localStorage.docId;

  isRoleUser = true;
  pending: any;
  workflowRoles = null;
  showWorkflow = true;
  showState = true;

  //current revision
  revision;

  //workflow variables
  currentTask: WorkflowUserTask;
  workflowTasks: WorkflowUserTask[];
  workflowState: string;

  isLoading = true;

  constructor(
    private gedService: GedService,
    public datepipe: DatePipe,
    private workflowService: WorkflowService
  )
  {
    this.isLoading = true;
    this.currentDocId = localStorage.docId;
  }

  ngOnInit(): void
  {
    if (this.gedDoc?.content?.data)
      this.initData(this.currentDocId,this.gedDoc.content.data);
  }

  async initData(currentDocId,docData)
  {
    if (this.gedDoc != undefined)
    {
      this.doc = docData;

      let workflow = await this.workflowService.getWorkflow(currentDocId);

      this.buildTasksRevisions(this.doc.oid,this.doc.uid);

      this.buildWorkflow(workflow);
    }

    //on data retrieval, remove the progression spinner
    this.isLoading = false;
  }

  async buildWorkflow(workflow)
  {
    this.workflowRoles = workflow.getRoles();
    this.workflowTasks = workflow.getTasks();
    this.workflowState = workflow.getCurrentState();

    if(this.workflowTasks)
      for (let task of this.workflowTasks)
      {
        const userOid = task.getUserOid();
        if(userOid)
        {
          let picture = await await this.workflowService.getUserPictureUrl(userOid);
          task.setPicture(picture);
        }

        this.historiques.push(task);
      }
  }

  lastVersion;
  revisions;
  tasks : GedTask[];

  async buildTasksRevisions(currentDocId,uid)
  {
    let revisions,tasks = null;

    // load tasks and revisions
    let history = await this.gedService.getDocHistory(uid);
    if(!history)
      return;

    {
      this.revisions = revisions = history.revisions;
      tasks = history.tasks;
    }

    // const revisions = await this.gedService.getDocRevisions(currentDocId);
    // this.revision = this.lastVersion = revisions && revisions[0] ||null;

    // create revision lookup by v.rev id
    let revisionById = {};
    revisions.forEach(rev => {
       const {version,revision} = rev.properties;
       const revId = ""+version+"."+revision;
       revisionById[revId] = rev;
    })

    // build tasks
    let index=0;
    this.tasks = (tasks || []).map(t => new GedTask(t,index++));

    // Sort the tasks array using the custom sorting function
    this.tasks = this.tasks.sort(compareClosingDate);

    // and attach revisions to tasks
    this.tasks.forEach(t => {
      const revId = t.getRevisionId();
      if(revisionById[revId])
        t.setRevisionDesc(revisionById[revId]);
    })

    this.htState.emit(this.tasks);
  }

  getTaskLabel(task) {
    return task.actionLabel;
  }

  getTime(revision)
  {
    if (!revision) {
      return '';
    }

    const date = new Date(revision.createdTime);
    const localizedDate = date.toLocaleDateString();
    const localizedTime = date.toLocaleTimeString();

    return `${localizedDate} ${localizedTime}`;
  }

  revisionDrive(link)
  {
    window.open(link, "_blank");
  }

  showFilrstletter(name) {
    let x = name.charAt(0);
    return x;
  }
}

// sort by task.date_closing.time, descending, and if no closing date set it last
function compareClosingDate(a, b)
{
  const dateA = a.data.date_closing && a.data.date_closing.time || a.data.date_creation && a.data.date_creation.time || 0;
  const dateB = b.data.date_closing && b.data.date_closing.time || b.data.date_creation && b.data.date_creation.time || 0;

  // Sort by date_closing in ascending order
  return dateA - dateB;
}

