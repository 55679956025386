import { Injectable } from "@angular/core";
import { RecentContact } from "../../models/recent-contact";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public static instance: UserService;
  _Users: RecentContact[] = [];

  _usersByEmail = {};

  constructor() {
    UserService.instance = this;
  }

  // user factory
  public static getInstance(): UserService {
    if (!UserService.instance)
      UserService.instance = new UserService();

    return UserService.instance;
  }

  async cacheUsers(users: RecentContact[]) {
    try {
      Object.values(users).forEach((user) => {
        this._Users.push(user);
        this._usersByEmail[user["email"]] = user;
      });
    } catch (error) {
      console.error("permissions", error);
    }
  }

  getUserByEmail(email): RecentContact {
    return this._usersByEmail[email] || null;
  }

  getCachedUsers(): RecentContact[] {
    return this._Users;
  }


}
