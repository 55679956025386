// ========== CLASSES USED BY SPACE TABLES TO DISPLAY FILES =============

import { ListItem } from "@interfaces/list-item";
import { TableColumn, TableRow } from '@interfaces/table';
import { GedFileContainer } from "../../models/space/space.model";
import { FileItem } from "../../models/file/FileItem.model";

export class ListItemFile extends FileItem implements ListItem
{
  // list items interface
  id: number;
  title: string;
  subtitle?: string;
  text?: string;
  rightText?: string;
  icon?: string;
  image?: string;
  imageWidth?: number;
  imageHeight?: number;
  iconColor?: string;
  color?: string;
  redirectTo?: string;
  menuItems?: {
     label: string;
     callback: Function;
  }[];

  space : GedFileContainer;

  constructor(file,fieldDescs,fnameId='name',iconLink48=null,space : GedFileContainer =null) {
    super(file,fieldDescs);
    this.space = space;

    let title = this.prop(fnameId) || '(no title)';
    let type = this.prop('type_document') || '';

    // set ListItem fields
    this.id = file.id
    this.title = title

    this.redirectTo = space.getFileUrl(file);

    // this.rightText: type,
    this.text = type;

    if(iconLink48)
      this.image = iconLink48;
  }

  setIcon(iconLink48) {
    this.image = iconLink48;
  }

  setLink(path : string) {
    this.redirectTo = path;
  }

  setMenuItems(menuItems)
  {
    this.menuItems = menuItems;
  }

  getIcon(iconLink) : string {
    return this.image;
  }
}

export class TableRowFile extends FileItem
{
  cols : TableCellFile[] = [];
  space : GedFileContainer; // Space or Directory

  constructor(file,fieldDescs,fnameId, columns, iconLink48,space : GedFileContainer)
  {
    super(file,fieldDescs);
    this.space = space;

    this.cols = columns.map(col =>
    {
      let cell = new TableCellFile(this, fnameId, col, iconLink48);

      return cell;
    });
  }

  getLink() {
    return this.space.getFileUrl(this.file);
  }

  columns() {
    return this.cols;
  }

  setMenuItems(menuItems)
  {
    this.cols[this.cols.length -1].setMenuItems(menuItems);
  }
}

export class TableCellFile implements TableRow
{
  id: string;
  value: string;
  subtitle: string;
  class: string;
  link?: string;
  iconClass?: string;
  iconImg?: string;
  bold?: boolean;
  isCircle?: boolean;

  menuItems?: {
     label: string;
     callback: Function;
  }[];

  constructor(row,fnameId, col, iconLink48)
  {
    let type = row.prop('type_document') || '';
    let fname = col.id;

    this.id = fname;

    if(col.id==='rating' && row.appProperties.nb_rates)
    {
      this.value = row.prop(fname) +' ' +'('+ row?.appProperties?.nb_rates + ')' ;
      this.link = row.getLink();
      this.subtitle =this.capitalizeFirstLetter(row?.properties?.type_document) ;
     }
     else
     {
      this.value = row.prop(fname).replace(/[-_]/g, ' ');
      this.link = row.getLink();
      this.subtitle = this.capitalizeFirstLetter(row?.properties?.type_document) ;
    }

    if(col.id==="modifiedTime"){
      this.value = this.formatDate(row?.properties?.modifiedTime);
     // console.log("rowrow",row)
    }


    if(fname==fnameId) {

      this.iconImg = iconLink48;
    }
  }
  capitalizeFirstLetter(str: string): string {
    if(!str)
      return "";

    const words = str.split(' ');

    if (words.length >= 2) {
      const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      return capitalizedWords.join(' ');
    } else if (words.length === 1) {
      return words[0].charAt(0).toUpperCase() + words[0].slice(1);
    } else {
      return '';
    }
  }
  setIcon(iconLink48) {
    this.iconImg = iconLink48;
  }

  setLink(path : string) {
    this.link = path;
  }

  setMenuItems(menuItems)
  {
    this.menuItems = menuItems;
  }

  getIcon(iconLink) : string {
    return this.iconImg;
  }


 formatDate(inputDate: string): string {
   if (!inputDate?.split)
     return "";

    const dateParts = inputDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const formattedDate = `${months[parseInt(month) - 1]} ${parseInt(day)}, ${year}`;

    return formattedDate;
  }
}
