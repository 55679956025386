import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GedService } from 'src/modules/app-common/services';

@Injectable({
  providedIn: 'root'
})
export class SearchGdriveService {

  constructor(private router: Router,
    private gedService: GedService ,) {
    SearchGdriveService.instance = this;
   }
   static instance: SearchGdriveService

   navigateTo(route, extras?)
   {
    (extras)? this.router.navigate(route, extras) : this.router.navigate(route)
   }
   async backToSpace(docId)
   {
    console.log("openspace",docId)

    /* let gedDocument = await this.gedService.getSpaceDoc(docId);
      if (gedDocument)
     {
       let url = "s/space/" +gedDocument['uid'];
        this.router.navigate([url]);
     }   */
   }

}
