import { Router } from '@angular/router';

import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GedService } from 'src/modules/app-common/services';
import { DateAdapter } from '@angular/material/core'; 
import { OneData } from 'src/modules/documents/models/one-data/one-data.model';

const accent = '#ff4081'
const primary = '#3f51b5'
const white = '#FFFFFF'
@Component({
  selector: 'app-space-doc-meta',
  templateUrl: './space-doc-meta.component.html',
  styleUrls: ['./space-doc-meta.component.scss']
})
export class SpaceDocMetaComponent implements OnInit, OnChanges {

  constructor(private dialog: MatDialog, private gedService: GedService, private _snackBar: MatSnackBar,
    public datepipe: DatePipe, private dateAdapter: DateAdapter<any>, private router: Router)
  { 

    }
  ngOnChanges(changes: SimpleChanges): void {

    this.onDocChange(changes.currentDocument.currentValue)
  }
    currentDocId;

  onDocChange(currentDoc){
    this.allData=[]
       this.path+=currentDoc.Name;
      this.currentDocId= currentDoc.id
      this.mimeType=currentDoc.mimeType;
  
      
      //this.docService.getTypeFromIcon(this.iconLink)
      if(this.mimeType=='application/vnd.google-apps.spreadsheet'){
        this.path+='.gsheet'
  
      }
      if(this.mimeType=='application/vnd.google-apps.document'){
        this.path+='.gdoc'
  
      }
      if(this.mimeType=='application/vnd.google-apps.presentation'){
        this.path+='.gpres'
  
      }
      if(this.mimeType=='application/vnd.google-apps.forms'){
        localStorage.setItem('path','forms')
        this.path+='.forms'
  
      }
    
    this.getDoc(this.path,this.currentDocId);
            
    //this.getBaseMeta(this.path,this.currentDocId);
 
    }
  @Input('document') currentDocument; 
  path='/'
  mimeType;

  doc;

  showState=true;

  oneData: OneData;
  allData= [];
  meta: any;
  chipContent=[];

  public ngOnInit(): void {

  }
  isLoadingState=true;
  typeDoc;
  getDoc(path,currentDocId){
    this.gedService.getDoc(currentDocId,path)
    .then((data)=>{
      let gedDoc = data.content.data;
      this.doc = data.content.data;
      this.typeDoc=this.doc.type_document.html
      let indexx = this.typeDoc.length-1;
      if(this.typeDoc[indexx] =="s"){
        this.typeDoc = this.typeDoc.slice(0,indexx)

      }
      this.getBaseMeta(this.path,this.currentDocId, gedDoc);
    });

  }

  getBaseMeta(path,currentDocId,gedDoc){
    this.gedService.getMeta(path,currentDocId)
    .then((data)=>{
      this.isLoadingState=false;

      //GET METADATA JSON
      this.meta = data;
      for (var key of Object.keys(this.meta)) {
        //SELECT METADATA WITH TAG = META
        if(!!this.meta[key]["x-tags"]){
          if(this.meta[key]["x-tags"] == "base_meta" || this.meta[key]["x-tags"].includes("base_meta")){

            let label = this.meta[key].label;
            this.oneData =new OneData(key ,label) ;
            
  
            if( gedDoc[key] instanceof Object == true) this.oneData.val = gedDoc[key].html
            else {this.oneData.val = gedDoc[key]}
            
            if(this.meta[key].type=='string') this.oneData.type = 'text'
            if(this.meta[key].type=='integer') this.oneData.type = 'number'
            if(this.meta[key].type=='date') {
              this.oneData.type = 'date'
              this.oneData.val = this.datepipe.transform(gedDoc[key], 'dd/MM/yyyy')
            }
            if(!!this.meta[key]["x-ui"])  {
              this.oneData.bgc = this.meta[key]["x-ui"]["color"];
              if( this.meta[key]["x-ui"]["class"] =='md-accent') this.oneData.bgc = accent;
              if( this.meta[key]["x-ui"]["class"] =='md-primary') this.oneData.bgc = primary;
  
  
              this.oneData.color= 'white';
            }
          this.allData.push(this.oneData)
  
           }

        } 

      
    }

          //if workflow is empty don't show card
          if(this.allData.length==0) this.showState=false;

          else this.showState=true;
    }); 
  }


  
}
