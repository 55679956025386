export interface ListItem {
   id: number;
   title: string;
   subtitle?: string;
   text?: string;
   rightText?: string;
   icon?: string;
   image?: string;
   imageWidth?: number;
   imageHeight?: number;
   iconColor?: string;
   color?: string;
   redirectTo?: string;
   menuItems?: {
      label: string;
      callback: Function;
   }[];
}

export function initListItem(options?: Partial<ListItem>): ListItem {
   const defaults = {
      id: -1,
      title: '',
      text: '',
      noShadow: true,
   };

   return {
      ...defaults,
      ...options,
   };
}
