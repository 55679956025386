<div *ngIf="!!actions" class="parentActions">

  <div class="actions "
    *ngFor="let a of actions" style="padding-left:5px; align-content: center; justify-content: center; display: inline;">
  <!--  <button mat-raised-button  [ngStyle]="{'backgroundColor' : a.button_class , 'color': a.text_class }" class="actionBtn"
    (click)="execAction(a)" >{{a.name | uppercase}}</button>
  
   -->



      <button auraButton  size="large" class="auraTooltip"
      label="{{a.name }}"
      (click)="execAction(a)" type="{{a.options.primary == false ? 'light' : 'primary'}}"
      [ngStyle]="{'backgroundColor' : a.button_class , 'color': a.text_class }"
      size="large"
      
      ></button>
  </div>
</div>
