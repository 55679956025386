import { FieldDesc } from "../field-desc/field-desc.model";
import { FormField } from "../form-field/form-field.model";

export class FormFieldEnum extends FormField{
    


	toString() {
		if(this.data()===null || typeof this.data() == "undefined")
			return '';
		return this.data()[this.desc().fieldTitle] || this.data().html || this.data().value || this.data();
	}

	update(data) {
		if(this.data()===null || typeof this.data() == "undefined")
		{
			this.set({value:'',html:''});
		}
		else 
		{
			if(data.value)
				this.data().value = data.value;

			if(data.html)
				this.data().html = data.html || data.value;
		}

		return this.data();
	}
}
