import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchGpeopleService {

  constructor() {
    SearchGpeopleService.instance = this;
  }
  static instance: SearchGpeopleService
}
