import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GedService } from 'src/modules/app-common/services';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from '../../services/document.service';
import { Link } from '../../models/link/link.model';
import { MatDialog } from "@angular/material/dialog";
import { environment } from 'src/environments/environment';
import { DocTypeAddComponent } from '../doc-type-add/doc-type-add.component';
import { LinkAdd } from '../../models/link-add/link-add.model';

const themePath = environment.themePath;


@Component({
  selector: 'app-doc-related',
  templateUrl: `./${themePath}/doc-related.component.html`,
  styleUrls: [`./${themePath}/doc-related.component.scss`]
})
export class DocRelatedComponent implements OnInit, OnChanges 
{

  currentDocId = localStorage.docId;
  doc;
  docUpdate;
  docType;
  doclinks;
  @Input('path') path;
  @Input("canEdit") canEdit;
  @Output() emitAdd: EventEmitter<any> = new EventEmitter();
  @Output() documentRelated: EventEmitter<any> = new EventEmitter();
  @Output() documentLinked: EventEmitter<any> = new EventEmitter();

  links = [];
  links2 = [];
  linkAdd: LinkAdd;
  showLinks = false;
  filteredLinks2: any = {
    title: "",
    data: []
  };
  parentUid
  LinkedDocuments: [] = []

  documentLinks: Link[] = [];
   gedDoc
  @Input() set _gedDoc(value) 
  {
    this.gedDoc = value;
    // this.getDoc()
  }


  constructor(private gedService: GedService,
    private router: Router,
    private docService: DocumentService,
    public dialog: MatDialog,
    private route: ActivatedRoute) 
  {

  }

  ngOnInit(): void 
  {
    this.currentDocId = localStorage.docId;
    //this.getDoc();

  }


 

  ngOnChanges(changes: SimpleChanges) 
  {
    
     // this.gedDoc = changes.gedDoc.currentValue
    this.getDoc();
   this.getLinks();

  }
  getDoc() 
  {
     if (this.gedDoc != undefined) 
    {
      this.doc = this.gedDoc.content.data;
        this.getLinks();
    }

  }
 

  getLinks() 
  {
    this.documentLinks = []
    let data = this.gedDoc.content.links;
    this.documentLinked.emit(data);
    this.isLoading = false;
    let data3 = { add: [] };
    let dataEmit =
    {
      Add: [],
      parentUid: ""
    };

    if (data != undefined && data != null) 
    {
      let data_Links = this.docService.filterLinks(data);
      let filteredLinks = [];
      data_Links.forEach((item) => 
      {
        let q = [
          ...new Map(item.data.map((obj) => [JSON.stringify(obj), obj])).values(),
        ];

        this.filteredLinks2.title = item.title;
        this.filteredLinks2.name = item.name;
        const res = q.filter((obj) => obj["name"] !== null);

        if (item.add) 
        {
          if (item.add[0].menuSection !== "") 
          {
            data3.add = item.add;
            if (item.add[0].parentUidFrom !== "current_doc") 
            {
              let data4 = item?.data || [];

              this.parentUid = (data4?.length != 0) ? data4[0]?.uid : null;

            }
            else 
            {
              this.parentUid = this.doc.uid;
            }

            dataEmit =
            {
              Add: item.add,
              parentUid: this.parentUid
            }
           // delete item["add"];
          }
          else 
          {
            this.parentUid = this.doc.uid;
          }
        }


        this.filteredLinks2.data = res;

        this.filteredLinks2.add = item.add;

        if (item.add) 
        {
          dataEmit =
          {
            Add: item.add,
            parentUid: this.parentUid
          }
        }


        this.filteredLinks2.data.sort((a, b) => (a.name > b.name ? 1 : -1));

        this.documentLinks.push(new Link(this.filteredLinks2));


        // this.setDisplayProperties(this.documentLinks)
      });



      const first = this.documentLinks.find((obj) => 
      {
        if (data3.add) 
        {
          if (data3.add[0]) 
          {
            if (obj.name === data3.add[0].menuSection) 
            {
              obj.add = data3.add;
            }
            if ( data3.add[0].name.includes(obj.name))
            {

                delete obj["add"];
            } 
          }
        }


      });
       if( dataEmit['Add']?.length != 0 && this.documentLinks?.length !=0 ){
         this.documentRelated.emit(true);
       }
     
      this.linkAdd = new LinkAdd(dataEmit)
      this.emitAdd.emit(this.linkAdd);
     


    }

    if (this.documentLinks.length == 0) 
    {
      this.showLinks = false;
    }
    else 
    {
      this.showLinks = true;
    }

    this.emitState.emit(this.showLinks);
  }


  @Output() emitState: EventEmitter<any> = new EventEmitter();

  isLoading = true;

  toLink(linkdoc) 
  {
    this.docService.toLink(linkdoc);
  }




  //Create new Document Type
  openDocumenttypeDialog(dataAdd): void 
  {
    const dialogRef = this.dialog.open(DocTypeAddComponent, {

      width: "500px",
      minWidth: "650px",
      height: "max-content",
      data: 
      {
        title: environment.stringsFile.newDoc,
        add: dataAdd,
        parentId: this.parentUid,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

}
