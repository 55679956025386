import { Component, Input, OnInit } from '@angular/core';
import { Workflow } from 'src/modules/workflow/models';
import { WorkflowService } from 'src/modules/workflow/services';

@Component({
  selector: 'app-doc-list-historic-approval-activity',
  templateUrl: './doc-list-historic-approval-activity.component.html',
  styleUrls: ['./doc-list-historic-approval-activity.component.scss']
})
export class DocListHistoricApprovalActivityComponent implements OnInit {

  @Input() tasks: Workflow[];
  

  constructor(private workflowService: WorkflowService) { }

  ngOnInit(): void {
    //this.getPhotoByMail(this.tasks[0].from_user?.email  )
  }

  async getPhotoByMail(mail: string): Promise<string | null> {
    let picture =  await this.workflowService.getImageByMail(mail);
    return picture;
  }

  getOrdinal(n: number): string {
    if (n >= 11 && n <= 13) {
      return 'th';
    }
    const remainder = n % 10;
    if (remainder === 1) {
      return 'st';
    } else if (remainder === 2) {
      return 'nd';
    } else if (remainder === 3) {
      return 'rd';
    } else {
      return 'th';
    }
  }

  transformTimestamp(timestamp: number): string {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const ordinal = this.getOrdinal(day);
    return `${month} ${day}${ordinal} ${year}`;
  }

}
