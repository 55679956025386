import { Component, Input, OnInit } from '@angular/core'; 

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class LHeaderComponent implements OnInit {
  @Input() breadcrumb: any[] = null;
  constructor() { }

  ngOnInit(): void {
  }

}
