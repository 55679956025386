import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ThemeService } from '@services/theme.service';
import { GDocService, GedService } from "src/modules/app-common/services";

const themePath = environment.themePath;

@Component({
  selector: 'app-toolbar',
  templateUrl: `./${themePath}/toolbar.component.html`,
  styleUrls: [`./${themePath}/toolbar.component.scss`]
})
export class ToolbarComponent implements OnInit ,OnChanges {
  @Input() title: string = '';  
  @Input() breadcrumb : any[] = null;
  
  appTitle : string = '';
  titleAttributeOk:string = '';

  libelle=environment.appName
  libelleTooltip=environment.appName;

  Mode: string = 'light';

  activeAccountMenu: boolean = false;
  activeSearch: boolean = false;
  activeNotifsMenu: boolean = false;
  currentName = localStorage.getItem('name');
  currentPhotoUrl = localStorage.getItem('photoUrl');

  constructor(
    private sidenavco : SidenavComponent ,  
    private theme: ThemeService,
    private titleService: Title,
    private gdocService: GDocService,
    private gedService: GedService ,
    private router: Router) {

      this.Mode = this.theme.current;
      this.initTitle();
      ToolbarComponent.instance = this;
      this.router.events.subscribe(() => {
        this.initTitle();
      });
  }
  static instance: ToolbarComponent

  ngOnChanges(changes: SimpleChanges): void {
 
  }
  
  async backToSpace(docId)
  {

    let gedDocument = await this.gedService.getSpaceDoc(docId);
    if (gedDocument)
    {
      let url = "s/space/" +gedDocument['uid'];
      this.router.navigate([url]);
    }   
  }
  navigateTo(route, extras?)
  {
   (extras)? this.router.navigate(route, extras) : this.router.navigate(route)
  }


  initTitle() {
    if(this.title)
    {
      this.titleAttributeOk = this.title;
      this.appTitle = this.title;
    }
    else if(!this.titleAttributeOk && !this.appTitle)
      this.appTitle = this.titleService.getTitle(); 
  }

  ngOnInit(): void {  
    this.initTitle();


  }

  toggleDisplay(){
    this.sidenavco.toggleDisplay();
  }

  toggleAccountMenu() {
    this.activeAccountMenu = !this.activeAccountMenu;
    this.activeNotifsMenu = false;
    this.activeSearch = false;
 }

 toggleSearch() {
    this.activeSearch = !this.activeSearch;
    this.activeAccountMenu = false;
    this.activeNotifsMenu = false;
 }

 toggleNotifsMenu() {
    this.activeNotifsMenu = !this.activeNotifsMenu;
    this.activeAccountMenu = false;
    this.activeSearch = false;
 }

 async onBreadCrumbClick($event) {
    if($event && $event.id)
    {
      await this.toFolder($event.id);
    }
  }

  filterBreadCrumbParent(parent) {
    return true;
    // return parent.parents && (parent.parents[0]['id'] != 'root');
  }

  async toFolder(parentId) 
  {
    //console.log('parentIdparentId',parentId)
    try 
    {
      let res = await this.gdocService.getMyDriveId();

      if (parentId == res) {
  
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["/s/space/root"]));
      } 
      else if (parentId == null) {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["/workspace/main/"]));
      }
      else 
      {
        this.router
        .navigateByUrl("/", { skipLocationChange: true })
        .then(() => this.router.navigate(["/s/space/" + parentId]));
      }      
    } 
    catch (error) {
      console.error(error.message || error);
    }
  }
}