import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName',
  standalone: true
})
export class ShortNamePipe implements PipeTransform {

  transform(firstName: string, lastName: string): unknown {
    return firstName + ' ' + lastName.charAt(0);
  }

}
