import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { DocType } from "src/modules/app-common/models/doc-type/doc-type.model";
import { GDocService, GedService } from "src/modules/app-common/services";
import { SpaceService } from "src/modules/spaces/services/space/space.service";
const themePath = environment.themePath;

@Component({
  selector: "app-doc-add",
  templateUrl: `./${themePath}/doc-add.component.html`,
  styleUrls: [`./${themePath}/doc-add.component.scss`],
})
export class DocAddComponent implements OnInit {
  showAddDoc: boolean = false;
  previewLink: string;
  temp = null;
  docType = {
    oid: "",
    title: "",
  };

  currentId;
  fileName: string;

  words;
  inGed = false;
  typeDoc;
  showAuto;
  autoUrl;
  showForm = false;
  testInput;
  tags = [];
  docTypeSelected = false;
  inputData: any;

  @Input() dontShowDocType: boolean = false; 
  @Input("canEdit") canEdit: boolean = false;
  @Input("iseditMeta") iseditMeta: boolean = false;
  @Output() emitState: EventEmitter<any> = new EventEmitter();
  showSpinner = false;

  constructor(private route: ActivatedRoute,
    private gedService: GedService,
    private router: Router,
    private gdocService: GDocService,
    private spaceService: SpaceService) {
  }

  ngOnInit(): void {
    // preload document types list
    this._initDocTypes();

    this.route.params.forEach((params) => {
      let id = params["docId"];
      this.currentId = id;

      this.gdocService.getFile(this.currentId).then((file) => {
        this.currentDoc = file;
        this.fileName = this.currentDoc.name;
      });
      if(this.dontShowDocType){
        this.showForm = true;
        this.docTypeSelected = true;
        this.setFormData('', false);
      }
    });
  }

  // ================= DOC TYPES FORM FIELD ================

  // load doc types list oid,title
  docTypes;
  async _initDocTypes() {
    try {
      if (!this.docTypes) {
        this.docTypes = await this.gedService.getDocTypes("");
      }
      return this.docTypes;
    }
    catch (error) {
      return null;
    }
  }

  _getDocTypeOidByName(docTypeName: string): string {
    // get selected type info
    try {
      for (let docType of this.docTypes) {
        if (docType.title == docTypeName) {
          return docType.oid;
        }
      }
    }
    catch (error) {
      return null;
    }
  }

  async onSelectedType(event?) {
    this.formFields = [];
    const type = event.value;
    let soid = null; // space oid, by default all space types.

    // get selected type info by name because mat autocomplete cant work w/ values
    let docTypeOid = this._getDocTypeOidByName(type);

    this.setFormData(docTypeOid, false);
    this.docTypeSelected = true;

  }

  filterType(options, value) {
    return options.filter((values) =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      values.toLowerCase().includes(value)
    );
  }

  // ======== SPACE FIELD ===============

  async getSpacebyType(type) {
    let space = this.spaceService.getSpacebySid(type);
    return space
  }

  // ======== ENUM LISTS ===============
  // typeList = [];

  doFilterField(field) {
    if (field.value == "") {
      this.fieldAutoList = [];
    }
    else {
      this.gedService
        .getAutocompleteList(field.value, field.auto.url)
        .then((res) => {
          let autoComplete = [];
          autoComplete = res;

          this.fieldAutoList = [];
          for (let option of Object.values(autoComplete)) {
            this.fieldAutoList.push(option);
          }

          this.filter(this.fieldAutoList, field.value);
        });
    }
  }

  filter(options, value) {
    if (!value || !value.toString)
      return [];

    value = value.toString().toLowerCase();
    return options.filter((values) =>
      values.html.toLowerCase().indexOf(value) === -1
    );
  }

  formFields = [];
  temporaryList = [];

  encodeMulKeys(keys) {
    return keys.length && ('|' + keys.join('|') + '|') || "";
  }

  checkedOptions = {}
  onChangeEnumsChecked(option, field, isChecked: boolean) {
    if (!this.checkedOptions[field.key])
      this.checkedOptions[field.key] = [];

    let tags = []

    if (isChecked) {
      this.checkedOptions[field.key].push(option)
    }
    else {
      this.checkedOptions[field.key] = this.checkedOptions[field.key].filter(x => x.value != option.key);
    }

    let values = [];
    let html = [];
    this.checkedOptions[field.key].forEach(option => {
      values.push(option.value);
      html.push(option.html);
    })

    if (!values.length)
      field.value = { value: '', html: '' };
    else
      field.value = {
        value: values.join('|'),
        html: html.join(',')
      }
  }


  checkedOptionsSelect = {}
  onSelectChange(option, field, event) {
    if (!this.checkedOptionsSelect[field.key])
      this.checkedOptionsSelect[field.key] = [];

    let tags = []

    if (event.source.selected) {
      this.checkedOptionsSelect[field.key].push(option)
    }
    else {
      this.checkedOptionsSelect[field.key] = this.checkedOptionsSelect[field.key].filter(x => x.value != option.key);
    }
    let values = [];
    let html = [];
    this.checkedOptionsSelect[field.key].forEach(option => {
      values.push(option.value);
      html.push(option.html);
    })

    if (!values.length)
      field.value = { value: '', html: '' };
    else
      field.value = {
        value: values.join('|'),
        html: html.join(',')
      }
  }

  initCheckBoxes(field, value) {
    let fname = field.key;
    this.checkedOptionsSelect[fname] = [];
    this.checkedOptionsSelect[fname].push(value)

    /*
    let values = value.value.split('|');
    values.forEach(v => {
      if(v)
        this.checkedOptions[fname].push(v);
    });
    */
  }

  isSelectedValue(option, field) {
    if (option.key == field.value || option.html == field.html)
      return true;

    return false;
  }

  async setFormData(docTypeOid, prefill) {
    let uid = this.currentId || null;
    console.log(prefill)
    this.gedService.getMetaByType(docTypeOid, false, true, uid, false, prefill).then((res) => {
      this.showForm = true;
      this.inputData = res;
      let data = res["data"];
      let metadata = res["metadata"]["fields"];

      for (let fname of Object.keys(metadata)) {
        let formField = this.createFormField(fname, data[fname], metadata[fname], docTypeOid);
        if (formField) {
          this.formFields.push(formField);
          this.temporaryList.push(formField);
        }
      }

    });
  }

  // create form field from server data/metadata
  createFormField(fname, data, fdesc, docTypeOid) {
    // display field ?
    let isShown = (fdesc["x-show"] != false && fdesc["show"] != false);
    if (!isShown)
      return null;

    let formField: DocType;

    formField = new DocType(fname, data, fdesc, this.gedService);

    let control = formField.getControl();

    if ((fname != "space" && fname != "template") && formField.getAuto() != null && formField.getControl() == "select") {
      formField.case = "select_dyn";
      this.gedService.getAutocompleteListSelect(formField.getUrlAuto())
        .then((autolist) => {

          let tempList = [];
          tempList = autolist || [];

          for (let item of tempList) {
            formField.fieldAutoList.push(item);
          }
        });
    }
    else if (formField.getAuto() != null && formField.getControl() == "checkbox") {
      formField.case = "checkbox_dyn";

      if (formField.getUrlAuto()) {
        this.gedService.getAutocompleteListSelect(formField.getUrlAuto())
          .then((autolist) => {
            let tempList = [];
            tempList = autolist || [];

            for (let item of tempList) {
              formField.fieldAutoList.push(item);
            }
          });
      }
    }
    else if (formField.getIsEnum() && !(formField.getIsMultiple())) {
      let value = (formField.getValue().value || '').toString();

      if (control == "checkbox") {
        formField.case = "checkbox";

        formField.setValue({
          value,
          html: formField.getValue().html
        });

        this.initCheckBoxes(formField, formField.getValue());
      }
      else {
        formField.case = "select";

        formField.setValue({
          value: formField.getValue().value.toLowerCase(),
          html: formField.getValue().html.toLowerCase()
        });
      }
    }

    else if (formField.getAuto() != null) {
      if (formField.getKey() == 'space' || formField.getKey() == 'template' || formField.getKey() == "for_type_document") {
        formField.case = "space_template";

        let Query = fdesc["x-dynamic-values"]['query']

        if (Query) {
          this.gedService.getAutocompleteList(formField.getValue(), formField.getUrlAuto(), docTypeOid, Query)
            .then(autolist => {
              let tempList = []
              if (autolist.length != 0) {
                //  formField.setValue(autolist[0]);
                formField.value = { ...autolist[0] }
                formField.setPreviewLink(autolist[0])
              }

              tempList = autolist || [];

              for (let item of tempList) {
                if (formField.getKey() == 'space') {
                  let spaces = this.getSpacebyType(item.html);
                }

                formField.fieldAutoList.push(item)
              }
              if (formField.getKey() == 'template') {
                this.previewLink = formField.getPreviewLink()
              }
            })
        }
      }
    }
    else if (formField.isAuto()) {
      formField.case = "autocomplete_dyn";

      this.gedService.getAutocompleteList(formField.getValue(), formField.getAuto())
        .then(autolist => {
          let tempList = []
          tempList = autolist || [];
          for (let item of tempList) {
            formField.fieldAutoList.push(item)
          }
        })
    }
    else {
      formField.case = "text";
      if (fname == "name") {
        formField.value = this.fileName || '';
      }
    }


    return formField;
  }

  onSelectedValue(field, value) {
    if (field.key == 'template') {
      for (let item of field.fieldAutoList) {
        if (value.html == item.html) {
          //field.value=item;
          // field.value=item;
          let id = item?.infos.id || null;
          this.previewLink = `https://drive.google.com/thumbnail?sz=w300&id=${id}`;
        }
      }
    }
  }

  onSelectedField(event: MatAutocompleteSelectedEvent, index) {
    for (let option of this.fieldAutoList) {
      if (option.html == event.option.value) {
        let temp =
        {
          name: this.formFields[index].key,
          value: option,
        };
      }
    }
  }

  fieldAutoList = [];

  currentDoc;
  name;
  code;
  async onAddtoGed(values) {
    this.showSpinner = true;
    let data = {};
    for (let item of this.formFields) {
      let key = item.key;
      /* if (item.auto == null) {
        data[key] = values[key];
      }
       */
      if (item.auto == null && !item.isEnum && item.control != "checkbox") {
        data[key] = values[key];
      }
      else if (item.case == "select") {
        let value = item.value.html;
        data[key] =
        {
          value: value,
          html: item.enumValues[value],
        };
      }
      else if (item.case == "checkbox") {
        let value = item.value.value;
        data[key] =
        {
          value: value,
          html: item.enumValues[value],
        };
      }
      else if (item.isEnum && (item.isMultiple) && item.control == "checkbox") {
        let keys = [];
        let datavalues = [];

        if (this.checkedOptionsSelect[key]) {
          keys = this.checkedOptionsSelect[key].map(function (obj) {
            return obj['key'];
          });

          datavalues = this.checkedOptionsSelect[key].map(function (obj) {
            return obj['value'];
          });
        }

        data[key] =
        {
          value: this.encodeMulKeys(keys),
          html: datavalues.join(','),
          values: this.checkedOptionsSelect[key]
        };

      }
      else if (!(item.isEnum) && (item.isMultiple) && item.control == "checkbox") {
        let keys = [];
        let datavalues = [];
        if (this.checkedOptionsSelect[key]) {
          keys = this.checkedOptionsSelect[key].map((obj) => {
            return obj['value'];
          });

          datavalues = this.checkedOptionsSelect[key].map((obj) => {
            return obj['html'];
          });
        }

        data[key] =
        {
          value: this.encodeMulKeys(keys),
          html: datavalues.join(','),
          values: this.checkedOptionsSelect[key]
        };

      }
      else {
        let ResData = await this.gedService
          .getAutocompleteList(values[key], item["auto"]["url"]);

        data[key] = ResData.find(item => item.html == values[key])
        if (ResData.length == 0) {
          this.gedService
            .getAutocompleteList('', item["auto"]["url"]).then(response => {
              data[key] = response.find(item => item.html == values[key])
            })
        }
      }
    }

    let docType;
    let typeData = await this.gedService.getDocTypes(values.docType?.toLowerCase())

    for (let item of typeData) {
      if (item.title.toLowerCase() == values.docType?.toLowerCase())
        docType = item;
    }

    let temp =
    {
      curTypeDoc: docType,
      data: values
    };

       this.gdocService.getFile(this.currentId).then((file) =>
      {
        this.currentDoc = file;

        this.gedService.addDocToGed(this.currentDoc, temp).then((res) =>
        {
          if (res!= undefined)
          {
            /*if (res.content?.uid)
            {
              let uid = res.content.uid;
              let  url = '/workspace/main/file/' +uid;
              this.router.navigate([url]);
            }
            else
            {
             */
              let curUrl = this.router.url;
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigate([curUrl]);
              });
           // }
          }
        });
      });  
  }

  cancelForm() {
    this.docType = {
      oid: "",
      title: "",
    };
    for (let item of this.formFields) {
      item.value = "";
    }
    this.docTypeSelected = false; 
    this.setFormData('', false);

  }

  callFormToEdit(){
    this.setFormData('', true);
  }



}
