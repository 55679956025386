import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState, OauthService } from 'oauth-psoft-client';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DebugLog } from 'src/modules/app-common/services/log/log.service';
const debug = DebugLog.build("Auth")
const BACKEND_URL = environment.BACKEND_URL;

// import window document, so that we can push events (pub/sub)
// import { DOCUMENT } from '@angular/common';
declare const document: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authStateSubject: BehaviorSubject<AuthState | null>;
  profileSubject: BehaviorSubject<Profile | null>;
  jwtkey = 'jwt'
  jwtIssKey = 'jwt-iss'

  constructor(private oauthService: OauthService,
    private httpClient: HttpClient,
    private router: Router
    //,document: Document
  ) {
    this.authStateSubject = new BehaviorSubject<AuthState | null>(null);
    this.profileSubject = new BehaviorSubject<Profile | null>(null);
    this.loadJWTFromLocalStorage()
    // Subscribe to changes in OauthService's stateSubject
    this.oauthService.stateSubject.pipe(

      tap(accessToken => {

        debug.log('Gtoken : ', accessToken)
      }),
      filter(accessToken => !!accessToken),

      switchMap(() => {

        return this.updateJwt()
        // return of(null).pipe(delay(10000))

      }), tap(() => {
        this.setJWTToLocalStrorage();

        // publish login event with token and profile
        this.sendEvent("onLogin",{token:this.token, jwt:this.jwt})

        debug.log('JWT : ', this.jwt)
      }),
      switchMap((response) => {
        if (response && response.content && response.content.profile) {
          this.updateProfileFromObj(response)
          return of(null)
        } else {
          return this.updateProfile()
        }
      }),

    ).subscribe();
  }

  refreshCurrentPage() {
    if (this.router.url.includes('/in/login')) {
      console.log('refresh page called')
      window.location.reload();
    }
  }

  // publish event to listeners (builtin event pub/sub)
  sendEvent(eventName="onLogin",data={}) {
    // Create a new custom event
    const customEvent = new CustomEvent(eventName,
      { detail: data }
    );

    // Dispatch the event on an element or the document
    document.dispatchEvent(customEvent);
  }

  updateJwt() {

    const accessToken = this.token
    const userInfo = {}
    return this.httpClient.post<any>(BACKEND_URL + '/ged/login/google', userInfo, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken)
    }).pipe(

      // get data from API
      map(res =>
        res && res.content &&
        res.content.userJWT
      ),

      // ignore if no jwt
      filter(jwt => !!jwt),

      // use jwt
      tap(
        jwt => {
          // pass to next
          this.authStateSubject.next(jwt);

          // refresh the page
          this.refreshCurrentPage()
        }
      )
    )
  }

  updateProfileFromObj(response: any) {

    this.profileSubject.next(response.content.profile)
  }
  updateProfile() {

    const accessToken = this.token

    const userinfoUrl = 'https://openidconnect.googleapis.com/v1/userinfo';

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken
    });

    return this.httpClient.get<any>(userinfoUrl, { headers })
      .pipe(
        // map data to object
        map(textOrObject => {
          return typeof textOrObject === 'string' ?
            JSON.stringify(textOrObject) :
            textOrObject
        }),

        // use profile
        tap((profile) =>
        {
          this.profileSubject.next(profile);

          // publish login event with token and profile
          this.sendEvent("onProfile",{profile});
        })

      );
  }

  logout() {
    this.oauthService.logout()
      .pipe(
        tap(() => {
          this.resetState()
          this.clearLocalStorage()
        }),
        take(1)
      )
      .subscribe()
  }
  clearLocalStorage() {
    localStorage.removeItem(this.jwtIssKey)
    localStorage.removeItem(this.jwtkey)
  }
  resetState() {
    this.profileSubject.next(null)
    this.authStateSubject.next(null)
  }

  get jwt(): AuthState {
    return this.authStateSubject.value
  }
  get token(): AuthState {
    return this.oauthService.token
  }
  getTokenAsync(): Promise<AuthState> {
    if (this.isExpired())
      return this.oauthService.refresh()
        .pipe(map(refresh => refresh['access_token'])).toPromise()
    return new Promise((resolve, reject) => {
      resolve(this.oauthService.token);
    });
  }

  getTokenWithObservable():Observable<AuthState> {
    if (this.isExpired()) {
      return this.oauthService.refresh()
      .pipe(map(refresh => refresh['access_token']));
    }
    return of(this.oauthService.token);
  }
  isExpired() {
    return this.oauthService.isIssExpired()
  }

  isConnected(): boolean {
    return !!this.isJWTInLocalstorage() && this.oauthService.isTokenInLocalstorage()
  }
  isJWTInLocalstorage() {
    const jwt = localStorage.getItem(this.jwtkey)
    const iss = localStorage.getItem(this.jwtIssKey)
    return !!jwt && !!iss
  }

  setJWTToLocalStrorage() {
    localStorage.setItem(this.jwtkey, this.jwt)
    localStorage.setItem(this.jwtIssKey, new Date().getTime().toString())
  }
  loadJWTFromLocalStorage() {
    const jwt = localStorage.getItem(this.jwtkey)
    if (jwt)
      this.authStateSubject.next(jwt)

  }

}
export class Profile {
  email?: string
  email_verified?: boolean
  family_name?: string
  given_name?: string
  hd?: string
  locale?: string
  name?: string
  picture?: string
  sub?: string
}
