import { Component, OnInit, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { AddDoctypeComponent } from 'src/modules/app-common/containers';
import { EventPerson } from '../../models/event/event-person.model';
import { GcalEvent } from '../../models/event/gcal-event.model';
import { MatDialog } from "@angular/material/dialog";

import { CalendarService } from '../../services/calendar.service';
import { WorkspaceMainComponent } from '../../containers';

const themePath = environment.themePath;
@Component({
  selector: 'app-event-answer',
  templateUrl: `./${themePath}/event-answer.component.html`,
  styleUrls: [`./${themePath}/event-answer.component.scss`]
})
export class EventAnswerComponent implements OnInit , OnChanges
{


  @Input('eventItem') eventItem

  static instance:EventAnswerComponent
  constructor(
    //public dialogRef: MatDialogRef<EventAnswerComponent>,
   // @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private calendarService: CalendarService,
    public dialog: MatDialog

    ) 
    {
      EventAnswerComponent.instance = this;
      

    }
  ngOnChanges(changes: SimpleChanges): void 
  {
    this.tempAnswer = this.eventItem?.getDisplayStatus()
   }

  @Input() isOpen: boolean = true;
  @Output() close = new EventEmitter<any>();
  @Output() emitState: EventEmitter<any> = new EventEmitter();

  closeModal() 
  {
    this.close.emit();   
  }

  ngOnInit(): void 
  {
  }


  async cancelAnswer(eventPerson: EventPerson)
  {
    let response = environment.stringsFile.allStatus;
    eventPerson.selfStatus = "needsAction";
    eventPerson.setDisplayedSelfStatus(eventPerson.getSelfStatus());

  }

  tempAnswer = ""

  prepareAnswer(answer)
  {
    this.tempAnswer = answer;
     this.typeBtn = "primary";
    this.activeSubmitButton = true;
  }

  async meetOptionClick(event: GcalEvent,response?: string)
  {
   // console.log("clickedon",event,response)
    response = this.tempAnswer;
   if (response != undefined)
    event = await  this.calendarService.setMeetingResponse(event,response);


 
  //  this.dialogRef.close({relaunchEventsReq: true})

  this.emitState.emit({relaunchEventsReq: true})
  this.closeModal() 
  WorkspaceMainComponent.instant.loadWorkSpaceData();

    // this.ngZone.run(() => {
    //   this.events = []
    //   this.webEvents = []
    //  });
 
 
 
   }

   showStatus = false;
   acceptStatus = environment.stringsFile.acceptStatus;
   declineStatus = environment.stringsFile.declineStatus;
   maybeStatus = environment.stringsFile.maybeStatus;
   meetingOptions = [this.acceptStatus,this.declineStatus,this.maybeStatus]
   activeSubmitButton = false;
   typeBtn  = "light";
  
  linkCalendar = environment.calendarLink;


  onCancelClick()
  {
    //this.dialogRef.close();
  }
  onCancel(): void
  {
   // this.dialogRef.close();
  }

  toLink(p)
  {
    if(p!=null && p!=undefined){
      window.open(p, '_blank');

    }
  }

  addMeetingNote()
  {
   // this.dialogRef.close();
    this.openDocumenttypeDialog()
  }

  //Create new Document Type event
  openDocumenttypeDialog(): void 
  {
    const dialogRef = this.dialog.open(AddDoctypeComponent, {
      width: "500px",
      minWidth: "650px",
      height: "max-content",
      data: {
        title: environment.stringsFile.newDoc,
        documentName: "",
        eventItem: this.eventItem
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }
}
