import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DateService} from 'src/modules/app-common/services';
import { environment } from "src/environments/environment";
import { TableColumn, TableRow } from '@interfaces/table';
import { TaskService } from 'src/modules/tasks/services/task.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GedFile } from 'src/modules/tasks/model/ged-task.model';
import { EmailToNamePipe } from 'src/modules/explorer/pipes/email-to-name.pipe';
const themePath = environment.themePath;
const CURRENT_URL = '/t/all/view/table'

@Component({
  selector: 'app-flagged-content',
  templateUrl: './flagged-content.component.html',
  styleUrls: ['./flagged-content.component.scss']
})
export class FlaggedContentComponent implements OnInit {

    displayedColumns: string[] = ['Name', 'Owner', 'Type', 'State'];
    columnsToDisplay: string[] = this.displayedColumns.slice();
    columns: TableColumn[];
    fllagedContentTableData: TableRow[][] = [];

    @ViewChild('myTemRef') myTemRef!: TemplateRef<any>;
  
    isLoading = true;
  
    constructor(
      public dateService: DateService,
      private taskService: TaskService,
      private translate: TranslateService,
      private emailToNamePipe: EmailToNamePipe,
      private router: Router      
      )
    {
    }
  
    async ngOnInit()
    {
      this.getFllagedContent();
    }
  
    _(s : string) : string {
      return this.translate.instant(s);
    }

    async getFllagedContent()
    {
      this.isLoading = true;
  
      let fllagedFilesResponse = await this.taskService.getFlaggedFiles()
      this.isLoading = false;

      this.columns = this.getTableColumns();
  
      this.fllagedContentTableData = this.prepareTable(fllagedFilesResponse);
    }
  
  
    prepareTable(files: GedFile[]): TableRow[][]
    {
      let fileTable: TableRow[][] = []
  
      for (let f of files)
      {
  
        fileTable.push( this.getDisplayDataTable(f, CURRENT_URL));

      }
  
      return fileTable;
    }
  
  
    transform(tags: string, at: number) {
      if (tags && tags.length && tags.length > at) {
        return tags.substring(0, at - 3).concat('...');
      }
      else {
        return tags
      }
    }
  
    // prepare infos for table view
    getDisplayDataTable(f: GedFile, prefixURL?: string) : TableRow[]
    {
        const owner= this.transform(
          this.emailToNamePipe.transform(f?.data?.properties?.responsible) ||
          f?.data?.properties?.owner, 30)

        let dataTable : TableRow[] =
        [
          {
              id: 'title',
              value: f.getName(),
              iconImg: f.getIcone(),
              link: prefixURL+'/flagged/file/'+f.getUid(),
          },
          {
            id: "flag",
            html: this.myTemRef
          },
          {
            id: 'tags',
            value: this.transform(
              (f.getTags() || '').split(',').join(', ')
              , 30)
          },
          {
            id: 'owner',
            value: owner
          },
          {
            id: 'doctype',
            value: f.getDocType() || ""
          },
          {
            id: 'lastEdit',
            value: f.getModifiedTime(),
          },
          {
              id: 'rating',
              value: f.getRating(),
          },
          {
              id: 'menu',
              value: '',
              menuItems:
              [
                {
                  label: this._('Ouvrir'),
                  callback: (row) => this.openDocument(f),
                }
              ]
          }
        ];
  
        return dataTable;
    }

 
    getTableColumns() : TableColumn[]
    {
      let cols : TableColumn[] =
      [
        {
          id: 'title',
          type: 'TextWithIcon',
          width: 320,
        },
        {
          id: "flag",
          label: "",
          type: "HTML",
          width: 100,
        },
        {
          id: "tags",
          //label: "Tags",
          width: 110,
        },
        {
          id: 'owner',
          type: 'Text',
          width: 110,
          //label: this._('Owner'),
          //label: "",
          //canSort :true
        },
        {
          id: "doctype",
          //label: "Doc type",
          type: "Text",
          width: 110,
          //canSort: true,
        },
        {
          id: "lastEdit",
          //label: "Last edit",
          type: "Date",
          width: 110,
          //canSort: true,
        },
        {
          id: "rating",
          //label: "Rating",
          type: "Text",
          width: 110,
          class: "yellow-text",
          //canSort: true,
        },
  
        {
          id: 'menu',
          type: 'Menu',
        },
      ];
  
      return cols;
    }

    
    openDocument(file : GedFile) 
    {
      let url = CURRENT_URL + '/flagged/file/' + file.getUid();
      this.router.navigate([url], { queryParams: { d: localStorage.host_domain } });
    }
  
}
