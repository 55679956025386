import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DocumentService } from 'src/modules/documents/services/document.service';
import { DocDialogData } from '../../models/doc-dialog-data/doc-dialog-data.model';
import { GDocService } from '../../services';

import { SpaceService } from "src/modules/spaces/services";
import { Space } from "src/modules/spaces/models/space/space.model";


@Component({
  selector: 'app-add-doc',
  templateUrl: './add-doc.component.html',
  styleUrls: ['./add-doc.component.scss']
})
export class AddDocComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddDocComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocDialogData,
    private gdocService: GDocService, private router: Router, private snackBar: MatSnackBar,
    private documentService: DocumentService ,     private route: ActivatedRoute,

    ) {}

  onCancel(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
  }

  onCreateDoc(documentName){
   
    this.gdocService.createDoc(documentName,this.data.mimeType,this.data.parentId)
    .then(data=>
    {
      let url = this.documentService.getRoutebyMimeType(this.data.mimeType);

      // TODO wrap in doc model and request URL from model, for consistency...
      url = '/workspace/main/file/'+data.id;
     
      localStorage.path = this.data.mimeType
     
      this.router.navigateByUrl(url);  
      this.dialogRef.close();     
    })
  }



	newDocPrompt(mimeType,typeName,ev) {
	}

  

}
