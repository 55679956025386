import { DateService, GedService } from "src/modules/app-common/services";

const primary = "#3f51b5";

export class MetaDocument {
    meta;
   value;
   label;
   background;
   color;
   type;
   fieldAutoList = []
   dataType : string;
   showAuto : boolean;
   autoUrl =null;
   autoComplete;
   enumValues = [];
   selectedValues = [];
   isEnum= false;
   fieldTitle = null;
   fieldValue = null; 
   "field-title";
   "field-value";

   uiDetails;
   control
 
   constructor(xtag,doc,meta) {
        this.setMeta(xtag);
        this.setLabel(meta);
        this.setBackGround(meta);
        this.setColor(meta);
        this.setType(meta);
        this.isFieldAuto(meta,doc,xtag);
        this.isFieldEnum(meta);
        
        //  this.setAutoUrl();
         this.setAutoComplete();
         this.setControl(meta)

         this.setEnumValues(meta);
         this.setFieldAutoList(meta);
        //  this.setFieldtitle();
        //  this.setFieldvalue
       
         this.setValue(doc);
         
    }

   isAuto
   isFieldAuto(meta,doc,xtag){
    this.isAuto = meta["x-dynamic-values"]? true: false;
    if(this.isAuto){
        this.setShowAuto(true)
        this.setAutoUrl(meta);
        this.setFieldTitle(meta);
        this.setFieldValue(meta)
        this.setDataType("object",doc,xtag);

    }
   }

   setDataType(dataType,doc?,xtag?){
    this.dataType = dataType;
    // if(this.dataType =="object"){
    //     if (doc[xtag]?.html == "-" || doc[xtag]?.html == null || doc[xtag]?.value == "-" || doc[xtag]?.value == null) {
    //         this.value = { html: "-", value: "-" };
    //     }
    // }
   }

   setShowAuto(boolValue){
    this.showAuto = boolValue;
   }
   setControl(meta){
    this.control = meta?.control || null;
   }

   getEnumValues(){
       return this.enumValues;
   }
   getControll(){
    return this.control;
}

   setEnumValues(meta){
   
      if(this.isFieldEnum(meta)){
        let enumValuesJSON = meta?.enum

        if(this.control === 'checkbox'){
        this.enumValues  = meta?.enum
        } 
        else if(this.control != 'checkbox')
            this.enumValues = Object.keys(enumValuesJSON).map(key=>({value: key,html:enumValuesJSON[key]}))
       }  
 
   }

   isFieldEnum(meta){
       this.isEnum = (meta?.enum)? true:false
       if(this.isEnum) this.dataType = 'enum';
       return this.isEnum;
   }
 
   getMeta() {
     return this.meta;
   }
   getValue() {
     return this.value;
   }
     getLabel() {
     return this.label;
   }  
   getBackGround() {
     return this.background;
   }
   getColor(){
       return this.color;
   }
   getType(){
       return this.type;
   }
  
   getSelectedValues(){
    return this.selectedValues;
    }

   getAutoUrl(){
       return this.autoUrl;
 
   }
   getAutoComplete(){
       return this.autoComplete;
   }
   getFieldtitle(){
       return this["field-title"];
   }
   getFieldvalue(){
       return this["field-value"];
   }
 
 
    
 
 
   ////setter
 
 
   setMeta(data) {
       this.meta = data
   }
   setValue(data) {
       this.value= data;
      
      /*  if(this.isEnum  &&  this.control !="checkbox"){
           let enumvaluehtml = {html: this.enumValues.find(item=>item.html===data || item.value===data)?.html || '-',
           value:this.enumValues.find(item=>item.html===data || item.value===data)?.value|| '-'}
           
           this.value = enumvaluehtml || '-';
          

       } */
       if(this.isAuto &&  this.control =="checkbox"){
        this.value =data?.value

       }

       if(this.isEnum &&  this.control =="checkbox"){
      
        
        var array =data?.value?.split('|').join(' ').substr(1).slice(0, -1);
       
         const split_string = array?.split(" ");
        

         let checkBox =[]
        
            if(split_string){
                let tc =    Object.values(split_string).forEach((item)=>{

                    checkBox.push(this.filterCheckbox(item))


                })
                checkBox?.sort((a, b) => (a > b ? 1 : -1));

                    this.value =checkBox?.toString();
                   

            }  
            this.value ==="-";
       }

       if (this.type=="date" && this.value != "-") {
         let d = new Date(this.value);
        d = DateService.instance.transformTime(d);
        this.value = d;
      }

   }
    setLabel(data) {
       this.label= data['label']
   } 

filterCheckbox(value){
   

    const labels =     this.enumValues[value] ;
    return labels
}

   setBackGround(metaValue) {
    this.background = (metaValue["x-ui"]?.["background"])? metaValue["x-ui"]["background"]:"white";
    if(this.background =="blue") this.background = primary;
       this.background ="white";
   }
   setColor(metaValue){
        this.color = (metaValue["x-ui"]?.["color"])? metaValue["x-ui"]["color"]:"black";
        if(this.color =="blue"){
            this.background = primary;
        }

   }
   setType(data){
    this.type= this.setTypeByList(data["type"]);
   }

   setTypeByList(typeLabel){
    if(typeLabel=="date") return "date";
    if(typeLabel=="number") return "number";
    return "text";

   }
   
   setAutoUrl(metaValue){
         this.autoUrl = metaValue["x-dynamic-values"]["url"];
 
   }
   setAutoComplete(){
         this.autoComplete = [];
   }
   setFieldtitle(){
    this["field-title"] = null;
   }
   setFieldvalue(){

    this["field-value"] = null;
   }

   setFieldTitle(metaValue){
    this.fieldTitle = metaValue["x-dynamic-values"]["field-title"];
   }
   setFieldValue(metaValue){

    this.fieldValue = metaValue["x-dynamic-values"]["field-value"];
   }

   getFieldAutoList() {
    return this.fieldAutoList;
  }
   setFieldAutoList(metaValue ) 
   {

    if(this.autoUrl !=null && this.control== "select")
    {

      let key = this.label;

       
        
        GedService.instance.getAutocompleteListSelect(this.autoUrl)
        .then(autolist=>
          {
            let tempList =[];
    
            tempList = autolist || [];
            for(let item of tempList)
            {
              this.fieldAutoList.push(item)              
            }
        })
      }
   
  } 

  
 }
