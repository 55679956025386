import {
  Component,
  Input,
  OnInit,
  OnChanges,
} from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { GDocService, GedService } from "src/modules/app-common/services";
import { SpaceHomeComponent } from "../../containers";
import { SpaceService } from "src/modules/spaces/services";
import { TranslateService } from '@ngx-translate/core';

import { environment } from "../../../../environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Meta } from "../../models/meta/meta.model";
import { PDocEventsService } from "src/modules/p-doc-envents/p-docs-events.service";

const themePath = environment.themePath;
@Component({
  selector: "app-space-desc",
  templateUrl: `./${themePath}//space-desc.component.html`,
  styleUrls: [`./${themePath}/space-desc.component.scss`],
})
export class SpaceDescComponent implements OnInit, OnChanges
{
  @Input() placeholder: string;
  @Input("currentSpace") currentSpace;
  @Input("canEditSpace") canEditSpace;

  constructor(
    private router: Router,
    private gedService: GedService,
    private route: ActivatedRoute,
    private gdocService: GDocService,
    private _snackBar: MatSnackBar,
    private drawer: SpaceHomeComponent,
    private spaceService: SpaceService,
    private translate: TranslateService,
    private pDocsEventsService: PDocEventsService,

  ) { }

  chipContent: Meta[] = [];
  fieldAutoList = [];
  formFields = [];

  contentMeta: Meta[] = [];

  material_icons: string[] = environment.material_icons;
  colors_ged: string[] = environment.colors_ged;

  formValues = [];
  TypeValues
  inGed = true;
  showContent = true;
  isShow = false;
  oidSpace;
  meta;
  typemains;
  dropdowniTem;
  spaceId;
  driveIfo;

  // i8n
  _(s: string): string {
    return this.translate.instant(s);
  }

  ngOnChanges(): void
  {
    this.onDocChange(this.currentSpace);
  }

  async onDocChange(currentSpace) {
    if (!currentSpace) {
      this.inGed = false;
      return;
    }

    let gedFolder = currentSpace;
    this.oidSpace = gedFolder?.data?.oid;

    if (gedFolder != null) {
      this.inGed = true;

      if (gedFolder["metadata"]) {
        this.meta = gedFolder["metadata"]["fields"];
      } else if (gedFolder["children"] && gedFolder["children"]["metadata"]) {
        this.meta = gedFolder["children"]["metadata"]["fields"];
      } else {
        return;
      }

      let values = gedFolder["data"];
      let i = 0;

      for (var key of Object.keys(this.meta)) {
        if (this.meta[key]["x-show"] != false) {
          let data = this.meta[key];
          let folder = gedFolder["data"][key];

          this.chipContent.push(new Meta(key, folder, data));

          if (!!this.meta[key]["x-ui"]) {
            this.chipContent[i].background = this.meta[key]["x-ui"]["color"];
            this.chipContent[i].color = "white";
          }
          if (this.chipContent[i].auto != null) {
            let chipKey = this.chipContent[i].value;

            let typeS = await this.gedService.getAutocompleteList(
              "",
              this.chipContent[i].auto.url
            );

            let typeOid = chipKey && chipKey.value || chipKey;

            this.TypeValues = typeS.find((item) => item.value == typeOid);

            if (this.TypeValues) {
              this.chipContent[i].value = this.TypeValues["html"];
            } else {
              this.formValues[i] = this.chipContent[i].value;
            }
          } else {
            this.formValues[i] = this.chipContent[i].value;
          }

          i++;
        }
      }
    }
  }


  /*   async onDocChange(currentSpace) {
      let gedFolder = currentSpace;
      this.oidSpace = gedFolder?.data?.oid;

      if (gedFolder != null) {
        this.inGed = true;

        if (gedFolder["metadata"]) {
          this.meta = gedFolder["metadata"]["fields"];
        } else {
          this.meta = gedFolder["children"]["metadata"]["fields"];
        }

        let values = gedFolder["data"];
        let i = 0;

        for (var key of Object.keys(this.meta)) {
          if (this.meta[key]["x-show"] != false) {
            let data = this.meta[key];
            let folder = gedFolder["data"][key];

            this.chipContent.push(new Meta(key, folder, data));

            if (!!this.meta[key]["x-ui"]) {
              this.chipContent[i].background = this.meta[key]["x-ui"]["color"];
              this.chipContent[i].color = "white";
            }
            if (this.chipContent[i].auto != null) {
              let chipKey = this.chipContent[i].value;

              let typeS = await this.gedService.getAutocompleteList(
                "",
                this.chipContent[i].auto.url
              );

              let typeOid = chipKey && chipKey.value || chipKey;

              this.TypeValues = typeS.find((item) => item.value == typeOid);

              if (this.TypeValues) {
                this.chipContent[i].value = this.TypeValues["html"];
              } else {
                this.formValues[i] = this.chipContent[i].value;
              }
            } else {
              this.formValues[i] = this.chipContent[i].value;
            }

            i++;
          }
        }
      }
      else {
        this.inGed = false;
        console.log("folder doesnt exist in GED!!!");
      }

    } */

  ngOnInit(): void {
    // this.access_tokenString = this.gdocService.getCurrentToken().toString();
    this.route.params.forEach((params) => {
      let id = params["spaceId"];
      this.spaceId = id;

      //  this.getSpaceDesc(id);
    });
  }

  filterr(options, value, field) {
    let currentValue = options.filter(
      (item) => item.html.toLowerCase() == value.toLowerCase()
    );

    return currentValue;
  }

  doFilterField(field) {
    if (field.value == "") {
      this.fieldAutoList = [];
    } else {
      this.gedService
        .getAutocompleteList(field.value, field.auto.url)
        .then((res) => {
          let autoComplete = [];
          autoComplete = res;
          this.fieldAutoList = [];
          for (let option of Object.values(autoComplete)) {
            this.fieldAutoList.push(option);
          }
          let currentValue = this.filterr(
            this.fieldAutoList,
            field.value,
            field
          );
          let indexOf = this.formFields.findIndex(
            (field) => field.value == currentValue.html
          );
          if (indexOf >= 0) this.formFields[indexOf].autoValue = currentValue;

          this.typemains = currentValue[0];
        });
    }
  }

  onUpdatespaceDesc(form) {
    let driveIfo =
    {
      oid: this.oidSpace,
      uid: this.spaceId,
    };

    if (this.TypeValues && this.TypeValues["value"]) {
      form["main_type"] =
      {
        value: this.TypeValues["value"],
        html: this.TypeValues["html"]
      };
    }
    else if (this.typemains) {
      form["main_type"] = this.typemains;
    }
    this.gedService.updateSpaceMeta(driveIfo, form, this.typemains)
      .then(async (res) => {
        this.pDocsEventsService.updateSpaceEvent(
          form.name
        );
        await this.spaceService.clearCache();
        let currentUrl = this.router.url;
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this.router.navigateByUrl(currentUrl);
          });
      })

    this._snackBar.open(this._("Space updated!"), null, {
      duration: 2000,
    });

    this.showContent = !this.showContent;
  }

  closeDrawer() {
    this.drawer.closeDrawer();
  }

  isShowIcon($event) {
    this.isShow = true;
  }
  isShowIconDes($event) {
    this.isShow = false;
  }

  selectedHoliday: string;
  eventSelection(event) {
    this.selectedHoliday = event.date;
  }

  annuler(){
    this.showContent=!this.showContent
  }
}
