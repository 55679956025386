import { TableColumn } from "@interfaces/table";
import { SearchEngine } from "src/modules/engines/models/search-engine";
import { gcs_config } from "../config/gcs_config";
import { GcsItem } from "./gcs-item";
import *as gcsHandlers from "./gcs-types.index"

export class SearchGcsEngine extends SearchEngine {
    searchGcsService: SearchGcsEngine;
    facets = [];


    constructor(searchGcsService){
        super();
        this.searchGcsService = searchGcsService;

    }

    
    setEngine(){
        this.engine = "gcs";
    }

    
    setResults(engineResponse){
        let jsonResults = engineResponse?.results || []
        let genericGcsItems = jsonResults.map(item=>({item: item,genericItem: new GcsItem(item),handler: ''}));
        genericGcsItems.forEach(obj=>obj.handler=(gcs_config.datasources_item_config.find(element=>element.ds==obj.genericItem.getDatasource()).handlerName))
        let results = genericGcsItems.map(obj=>{return new gcsHandlers[obj.handler](obj.item)})
        this.results = results;

        this.setDisplayProperties(this.results);
        this.getColumns();
        return this.results;

    }
    displayProperties;

    setDisplayProperties(results){
        this.displayProperties = results.map(item=>item.getDisplayProperties())
    }

    columns: TableColumn[]
    getColumns(){
        this.columns = [
            {
              id: 'name',
              type: 'TextWithIcon'
            },
            {
              id: 'details',
              type: 'Text',
              label: 'Details'
            },
            {
               id: 'Menu',
               type: 'Menu',
               label: 'Actions',
            }
         ];

        return this.columns;
    } 


    getDisplayProperties(){
        return this.displayProperties;
    }

    getResults(){
        return this.results;
    }

    setEngineOptions(){
        this.engineOptions = {engine: this.getEngine()}
    }

    setFacets(engineResponse){
        let jsonFacets = engineResponse;

        return this.facets;
    }

    getFacets(){
        return this.facets;
    }

    


}