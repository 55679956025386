import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchGcontributorService {

  constructor() {
    SearchGcontributorService.instance = this;
  }
  static instance: SearchGcontributorService
}
