import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconAsset',
  pure: true,
  standalone: true
})
export class IconAssetPipe implements PipeTransform {

  transform(iconName: string): unknown {
    let path = "/assets/icons/"
    if (iconName.endsWith('svg'))
      return path + iconName;
    return path + iconName + '.svg';
  }

}


// input : home-active
// or 
// input : home-active.svg

//output: /assets/icons/home-active.svg