import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const $theme = environment.themeUI;

@Component({
   selector: 'app-layout',
   templateUrl: $theme + '/layout.component.html',
   styleUrls: [$theme + '/layout.component.scss'],
})
export class LayoutComponent implements OnInit {
   title: string = '';
   isOn: boolean = false;

   constructor(private titleService: Title, private router: Router) {
      this.router.events.subscribe(() => {
         this.title = this.titleService.getTitle();
      });
   }

   togglesidebar() {
      this.isOn = !this.isOn;
      document
         .getElementById('root')
         ?.classList.toggle('activesidemenu', this.isOn);
   }

   ngOnInit(): void {
      this.title = this.titleService.getTitle();
   }
}
