import { GcsCalendar } from "./gcs-calendar";
import { GcsGdrive } from "./gcs-gdrive";
import { GcsGmail } from "./gcs-gmail";
import { GcsGroup } from "./gcs-group";
import { GcsPerson } from "./gcs-person";
import { GcsSite } from "./gcs-site";
import { GcsTangram } from "./gcs-tangram";
import { GcsWorkplace } from "./gcs-workplace";

export const gcsTypes = [
    GcsCalendar,
    GcsGmail,
    GcsGroup,
    GcsPerson,
    GcsSite,
    GcsTangram,
    GcsWorkplace,
    GcsGdrive
];

export * from "./gcs-calendar";
export * from "./gcs-gmail";
export * from "./gcs-group";
export * from "./gcs-person";
export * from "./gcs-site";
export * from "./gcs-tangram";
export * from "./gcs-workplace";
export * from "./gcs-gdrive";
