import { GcsItem } from "./gcs-item";

export class GcsGdrive extends GcsItem{
    constructor(gcsItem){
        super(gcsItem);
    }

    setIconLink(gcsItem) {
        this.iconLink = '/assets/google_icons/docs_icon.svg';
    }
}