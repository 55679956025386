import { IconAssetPipe } from "./pipes/icon-asset.pipe";
import { AuraxAvatarRoundedComponent } from "./../aurax/avatar-rounded/avatar-rounded.component";
import { AuraxBtnIconComponent } from "./../aurax/btn-icon/btn-icon.component";
import { LHeaderComponent } from "./components/layout/header/header.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppCommonRoutingModule } from "./app-common-routing.module";
import { MaterialModule } from "../material/material.module";

import * as commonContainers from "./containers";
import * as commonComponents from "./components";
import { BreadCrumbComponent } from "./components/bread-crumb/bread-crumb.component";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";
import { PluralTranslatePipe } from "./pipes/plural-translate.pipe";
import { pipes } from "./pipes";
import { DesignSystemModule } from "src/design-system/design-system.module";
import { SpacesListComponent } from "./components/spaces-list/spaces-list.component";
import { WokrflowComponent } from "./components/wokrflow/wokrflow.component";
import { RouterModule } from "@angular/router";
import { NewlineToBrPipe } from "./pipes/new-line-to-br.pipe";

@NgModule({
  declarations: [
    ...commonContainers.containers,
    ...commonComponents.components,
    PluralTranslatePipe,
    NewlineToBrPipe,
    LHeaderComponent,
    BreadCrumbComponent,
    WokrflowComponent,
  ],
  exports: [
    ...commonContainers.containers,
    ...commonComponents.components,
    pipes,
    FormsModule,
    TranslateModule,
    DesignSystemModule,
    ReactiveFormsModule,
    BreadCrumbComponent,
    WokrflowComponent,
    LHeaderComponent,
  ],
  imports: [
    CommonModule,
    AppCommonRoutingModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DesignSystemModule,
    IconAssetPipe,
    AuraxBtnIconComponent,
    AuraxAvatarRoundedComponent,
    SpacesListComponent,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class AppCommonModule {}
