import { Component, OnInit } from '@angular/core';
// import { Title } from '@angular/platform-browser';

@Component({
   selector: 'app-notfound',
   templateUrl: 'bootstrap/notfound.component.html',
   styleUrls: ['bootstrap/notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {
   constructor() {
      // this.titleService.setTitle('Not Found');
   }

   ngOnInit(): void {}
}
