import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-tickets',
   templateUrl: 'bootstrap/tickets.component.html',
   styleUrls: ['bootstrap/tickets.component.scss'],
})
export class TicketsComponent implements OnInit {
   constructor(private _location: Location) {}
   goback() {
      this._location.back();
   }

   ngOnInit(): void {}
}
