import { DateService } from './date/date.service';
import { GDocService } from './g-doc/g-doc.service';
import { GedService } from './ged/ged.service';
import { APISce } from './http/api.service';
import { RouteService } from './route/route.service';

export const services = [
    GedService,
    GDocService,
    DateService,
    APISce,
    RouteService
];

export * from './http/api.service';
export * from './date/date.service';
export * from './g-doc/g-doc.service';
export * from './ged/ged.service';
