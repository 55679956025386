import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchGcontact2Service {

  constructor() {
    SearchGcontact2Service.instance = this;
  }
  static instance: SearchGcontact2Service
}
