import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { skip, switchMap } from "rxjs/operators";

import { GedService } from "src/modules/app-common/services";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-doc-rating",
  template: `
  <aura-input-rating class="rating" color="#F2BA00" id="ratingElement"
    [formControl]="rateFormControl"></aura-input-rating>

    <span *ngIf="documentrate" class="ratingTool" title="Rating = {{ documentrate.rating }}, votes = {{ documentrate.nb_rates }}">{{ documentrate.rating }} ({{ documentrate.nb_rates }})</span>
  `,
  styles: [`
  /* Add a tooltip to the rating element */
  
  .ratingTool[title]:hover::after {
    content: attr(title);
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 14px;
    right: 76px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
  }
  
  .ratingTool[title]:hover::after {
    opacity: 1;
  }
  .rating{
    font-size: large;
    color : red;
  }
  `],
})
export class DocRatingComponent implements OnChanges, OnDestroy, OnInit {

  readonly rateFormControl: FormControl = new FormControl(0);

  documentrate: { rating: number, nb_rates: number };

  private _curentuid: string;
  private _rateFormControlSubscription?: Subscription;

  constructor(
    private readonly _gedService: GedService,
    private translate: TranslateService,
    private readonly _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  @Input("gedDoc") gedDoc: any;

  _(s: string): string {
    return this.translate.instant(s);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.gedDoc) {
      console.log("documentrate", this.documentrate)
      this._curentuid = changes.gedDoc.currentValue?.content?.data?.uid;
      if(!this._curentuid){
        this._curentuid = this.route.params["_value"]["docId"];

      }
      await this._updateWithCurrentRate(this._curentuid);
      const ratingElement = document.getElementById('ratingElement');

      // Add an event listener to update the tooltip text
      ratingElement?.addEventListener('mouseover', function () {
        // Replace the tooltip text with the dynamic values
        const ratingValue = '{{ this.documentrate.rating }}'; // Replace with your actual rating value
        const numberOfVotes = '{{ documentrate.nb_rates }}'; // Replace with your actual number of votes

        // Set the tooltip text to include the rating value and number of votes
        ratingElement.style.setProperty('--tooltip-content', `"Rating = ${ratingValue}, votes = ${numberOfVotes}"`);
      });

      // Reset the tooltip text on mouseout
      ratingElement?.addEventListener('mouseout', function () {
        ratingElement.style.removeProperty('--tooltip-content');
      });

    }
  }

  ngOnInit(): void {



    this._rateFormControlSubscription
      = this.rateFormControl.valueChanges.pipe(
        skip(1),
        switchMap((rate: number) => {
          return this._gedService.rateDocument(this._curentuid, rate);


        })
      ).subscribe(_ => {
        let curUrl = this.router.url;
        this.router.navigate(['']).then((navigated: boolean) => {
          if (navigated) {
            this.router.navigateByUrl(curUrl);
            this._snackBar.open(this._("rating sent"), '', {
              duration: 3000
            });
          }
        });

      });
  }

  ngOnDestroy(): void {
    this._rateFormControlSubscription?.unsubscribe();
  }





  private async _updateWithCurrentRate(uid): Promise<void> {
    const res: { rating: number, nb_rates: number } = await this._gedService.getRatingDoc(uid);
    this.documentrate = res;

    const rate: number = res.rating;

    this.rateFormControl.patchValue(+rate, {
      emitEvent: false
    });


  }

}
