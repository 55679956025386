import { environment } from "src/environments/environment";
import { DocumentService } from "src/modules/documents/services";

const ownerMe = environment.defaultStringFile.ownerMe;
const folder = environment.defaultStringFile.folderst;

const tomorrow = environment.stringsFile.tomorrow;
const Lastday = environment.stringsFile.lastDay;
const confirm = environment.stringsFile.confirmed;

export class SpaceContent {
  name: string;
  id;
  iconLink: string;
  Owner: string;
  type: string;
  state: string;
  thumbnailLink;
  properties;

  date;
  mimeType;
  client;
  parents;
  constructor(item) {
    this.setId(item);
    this.setName(item);
    this.setIcon(item);
    this.setOwner(item);
    this.setIcon(item);
    this.setState(item);
    this.setMimeType(item);
    this.setType(item);
    this.setThumbnailLink(item);
    this.setDate(item);
    this.setProperties(item);
    this.setClient(item);
    this.setParents(item);
  }

  getProp(p) {
    return this[p];
  }

  getParents(): string {
    return this.name;
  }

  getName(): string {
    return this.name;
  }

  getId() {
    return this.id;
  }

  getIcon(): string {
    return this.iconLink;
  }

  getOwner(): string {
    return this.Owner;
  }

  getMimeType() {
    return this.mimeType;
  }

  getType(): string {
    return this.type;
  }

  getState(): string {
    return this.state;
  }

  getThumbnailLink() {
    return this.thumbnailLink;
  }

  getDate() {
    return this.date;
  }

  getClient() {
    return this.client;
  }
  getProperties() {
    return this.properties;
  }

  /*******setter */

  setName(item) {
    this.name = item.name;
  }

  setParents(item) {
    this.parents = item.parents || null;
  }
  setId(item) {
    this.id = item.id;
  }
  setIcon(item) {
    let icon = DocumentService.instance.getIconbyMimeType(item.iconLink, item);
    if (icon) {
      this.iconLink = icon;
    }
    else
      this.iconLink = item.iconLink;
  }

  setMimeType(item) {
    this.mimeType = item.mimeType;
  }

  setOwner(item) {

    if (item.owners) {
      if (item.owners[0]["me"] == true) {
        this.Owner = ownerMe;
      } else {
        if (item.owners[0].displayName.length != 0) {
          if (!!item.owners && item.owners.length != 0)
            this.Owner = item?.owners[0]?.displayName;
        } else {
          this.Owner = null;
        }
      }
    }
    else {
      if (item.properties) {
        //   console.log('item search', item)
        ///this.Owner = item?.properties?.owner; .indexOf("Template")
        if (item?.properties?.owner) {
          if (item?.properties?.owner.indexOf('@') !== -1) {
            this.Owner = item?.properties?.owner.split('@')[0];
          }
          else
            this.Owner = item?.properties?.owner || null;

        }
        else this.Owner = null;
      } else {
        this.Owner = null;
      }
    }

  }

  setType(item) {
    if (this.getMimeType() == "application/vnd.google-apps.folder") {
      if (item["properties"]?.["type_document"]) {
        this.type = item["properties"]?.["type_document"].charAt(0).toUpperCase() + item["properties"]?.["type_document"].slice(1);
      }
      else if (item["properties"]?.["type_folder"]) {
        this.type = item["properties"]?.["type_folder"].charAt(0).toUpperCase() + item["properties"]?.["type_folder"].slice(1);
      }
      else
        this.type = folder;
    }
    else
      this.type = item["properties"]?.["type_document"] || "";

  }

  setState(item) {
    this.state =
      item["properties"]?.["Etat"] || item["properties"]?.["state"] || "";
  }
  hasState() {
    return this.state !== "" && this.state !== "0";
  }

  setThumbnailLink(item) {
    if (this.getMimeType() == "application/vnd.google-apps.folder") {
      this.thumbnailLink = "";
    }
    else
      this.thumbnailLink =
        "https://drive.google.com/thumbnail?sz=w360&id=" + item.id;
  }

  setDate(item) {
    let start = item.modifiedTime
    let d = new Date(start);
    let options
    let options2
    let rel = this.relative(d);

    let s;
    options = { hour12: false, hour: '2-digit', minute: '2-digit' };
    options2 = { month: "short", day: "numeric", year: 'numeric' };

    if (rel == 'today') {
      s = d.toLocaleTimeString('fr-Fr', options);
    }
    else if (rel == 'LastDay') {
      s = Lastday + ' ' + d.toLocaleTimeString('fr-Fr', options);
    }
    else if (rel == 'tomorrow') {
      s = tomorrow + ' ' + d.toLocaleTimeString('fr-Fr', options);
    }
    else
      s = d.toLocaleDateString('fr-Fr', options2);


    this.date = s
  }

  setClient(item) {
    this.client = item?.["properties"]?.["client"] || "";
  }
  setProperties(item) {

    this.properties = item?.["properties"] || "";
  }


  tomorrow;
  lastDay

  relative(d: Date) {
    let now = new Date();
    this.lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    this.tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    if (d.toDateString() === now.toDateString())
      return 'today';

    if (this.tomorrow.getFullYear() == d.getFullYear() && this.tomorrow.getMonth() == d.getMonth() && this.tomorrow.getDate() == d.getDate()) {
      return "tomorrow";
    }

    if (this.lastDay.getFullYear() == d.getFullYear() && this.lastDay.getMonth() == d.getMonth() && this.tomorrow.getDate() == d.getDate()) {
      return "LastDay";
    }

    return 'other';
  }

  static build(data): SpaceContent {
    if (data.iconLink.includes("folder"))
      return new SpaceFolderItem(data);
    else
      return new SpaceFileItem(data);
  }
}

export class SpaceFileItem extends SpaceContent {
  isFolder() { return false; }
}

export class SpaceFolderItem extends SpaceContent {
  isFolder() { return true; }
}

