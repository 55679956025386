import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchGenericRoutingModule } from './search-generic-routing.module';
import { MaterialModule } from '../material/material.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { SpacesModule } from '../spaces/spaces.module';
import * as searchContainers from './containers';
import * as searchComponents from './components'

import { DocumentsModule } from '../documents/documents.module';


@NgModule({
  declarations: [
    searchContainers.containers, searchComponents.components
  ],
  imports: [
    AppCommonModule,
    CommonModule,
    SearchGenericRoutingModule,
    MaterialModule,
    DocumentsModule,
    SpacesModule
  ],
  exports: [
    SearchGenericRoutingModule,
    searchContainers.containers, searchComponents.components
  ]
})
export class SearchGenericModule { }
