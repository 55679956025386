import { FormFieldDate } from "../form-field-date/form-field-date.model";
import { FormFieldEnum } from "../form-field-enum/form-field-enum.model";
import { FormField } from "../form-field/form-field.model";

export class FieldDesc {
    public tags?: any;
    private _ui?: any;
    public fieldTitle?: string;
    public show: boolean;
    public enumValues?: any;
    public type: any;

	constructor(desc,fname) {
		for(var k in desc) this[k]=desc[k];
		this['fname']=this['name']=fname;

		if(desc['x-tags'])
			this.tags = desc['x-tags'];
		else
			this.tags = [];
		if(desc['x-ui'])
			this._ui = desc['x-ui'];
		else
			this._ui = {};

		if(desc['x-dynamic-values'])
		{
			this.enumValues = desc['x-dynamic-values'];
			this.fieldTitle = this.enumValues['field-title'] || "html";
		}
		else
			this.fieldTitle = 'html';

		if(typeof desc['x-show'] != "undefined")
			this.show = desc['x-show'];
	}

	isHidden() { return this.show === false;}
	hasTag(t)  { return this.tags.includes(t); }

	ui(type,deft)  { 
		return typeof this._ui[type]!= "undefined" ? this._ui[type] : deft; 
	}

	buildField(n,data) {
		let v = data[n];
		if(v===null)
			v='';

		if(this.type=='date')
			return new FormFieldDate(v,this);

		let html;
		if(data[n+'__html'])
		{
			html = data[n+'__html'];
			let ftitle = this.fieldTitle;
			v = {value:v};
			v[ftitle] = html;
		}
		else
			html = null;

		if(this.enumValues)
			return new FormFieldEnum(v,this);

		return new FormField(v,this);
	}
}

