import { stringFile } from "src/assets/strings/strings";
import { TableColumn, TableRow } from "src/design-system/core/interfaces/table";
import { SearchGdriveService } from "../services/search-gdrive.service";
import { SearchResultItem } from "src/modules/engines/models/search-engine-result";
import { DocumentService } from "src/modules/documents/services";
import { environment } from "src/environments/environment";
import { IAppProperties, Document, IDocumentProperties, Tag, DrivePermission } from "./gdrive-back.model";

/**
 * Represents an item in Google Drive search results.
 */
export class GdriveItem implements SearchResultItem {
    item: Document;
    properties: IDocumentProperties;
    displayProperties: TableRow[];
    appProperties: IAppProperties;

    /**
     * Constructs a new instance of the GdriveItem class.
     * @param item - The item data.
     */
    constructor(item: Document) {
        this.item = item || {};
        this.properties = {
            ...(item.appProperties || {}),
            ...(item.properties || {})
        };
    }

    /**
     * Retrieves a property by name.
     * @param n - The property name.
     * @param deft - The default value if the property is not found. Default is null.
     * @returns The value of the property or the default value.
     */
    prop(n: string, deft: any = null): any {
        return this.properties[n] || deft;
    }

    // Getters for commonly used item properties
    get name(): string {
        return this.item.name || this.item.properties.name;
    }

    getName(): string {
        return this.item.name || this.item.properties.name;
    }

    getCreatedTime(): string {
        return this.item.createdTime;
    }

    getModifiedTime(): string {
        return this.item.modifiedTime;
    }

    getIconLink(): string {
        let iconLink = DocumentService.instance.getIconbyMimeType(this.item.iconLink);
        if (iconLink) {
            return iconLink;
        } else {
            return this.item.iconLink || this.item.properties.icon_link;
        }
    }

    get uid(): string {
        return this.item.id;
    }

    getUid(): string {
        return this.item.id || this.item.uid;
    }

    getMimeType(): string {
        return this.item.mimeType;
    }

    getTypeDocument(): string {
        return this.prop('type_document', '');
    }

    get parents(): string {
        return (this.item.parents) ? this.item.parents[0] : 'root';
    }

    getParents(): string {
        return (this.item.parents) ? this.item.parents[0] : 'root';
    }

    getParentId(): string {
        return (this.item.parents) ? this.item.parents[0] : 'root';
    }

    get webViewLink(): string {
        return this.item.webViewLink;
    }

    getWebViewLink(): string {
        return this.item.webViewLink;
    }   

    getOwner(): {displayName: string, emailAddress: string} {
        return this.item?.lastModifyingUser;
    }

    getPermissions(): DrivePermission[] {
        return this.item.permissions;
    }

    getProperties(): IDocumentProperties {
        return this.properties;
    }

    getType(): string {
        return this.item.type;
    }

    getFlag(): Tag {
        return this.properties?.flag ?? this.appProperties?.flag;
    }

    getState(): Tag {
        return this.properties?.state ?? this.properties?.stateid;
    }

    /**
     * Gets the display properties of the item for rendering.
     * @param urlPrefix - The URL prefix for constructing links. Default is an empty string.
     * @returns An array of display properties.
     */
    getDisplayProperties(urlPrefix = ''): any[] {
        var self = this;

        return this.displayProperties ||
            (
                this.displayProperties =
                [
                    {
                        id: 'name',
                        value: this.getName(),
                        iconImg: this.getIconLink(),
                    },
                    {
                        id: 'lastModified',
                        value: this.getModifiedTime(),
                    },
                    {
                        id: 'type',
                        value: this.getTypeDocument(),
                    },
                    {
                        id: 'state',
                        value: null,
                    },
                    {
                        id: 'Menu',
                        value: '',
                        menuItems:
                            [
                                {
                                    label: stringFile.stringsJson['folderstr'],
                                    callback: () => {
                                    },
                                },
                                {
                                    label: stringFile.stringsJson['openParent'],
                                    callback: () => {
                                        SearchGdriveService.instance.navigateTo(['/s/space/' + self.getParents()])
                                    }
                                },
                                {
                                    label: stringFile.stringsJson['fichier'],
                                    callback: () => {
                                        window.open(self.getWebViewLink(), "_blank")
                                    }
                                }
                            ]
                    }
                ]
            );
    }

    columns: TableColumn[];

    /**
     * Gets the columns for displaying in a table.
     * @returns An array of table columns.
     */
    getColumns(): TableColumn[] {
        this.columns =
            [
                {
                    id: 'name',
                    type: 'TextWithIcon',
                    width: 310,
                },
                {
                    id: 'Flags',
                    type: 'HTML',
                    width: 100,
                },
                {
                    id: "Tags",
                    label: "Tags",
                    width: 110,
                },
                {
                    id: "owner",
                    label: "Creator",
                    type: "Text",
                    width: 110,
                    canSort: true,
                },
                {
                    id: 'type',
                    type: 'Text',
                    label: 'Type',
                    width: 110,
                },
                {
                    id: 'lastModified',
                    type: 'Date',
                    label: 'Last Modified',
                    width: 110,
                },
                {
                    id: 'rating',
                    type: 'Text',
                    label: 'Rating',
                    width: 110,
                },
                {
                    id: 'Menu',
                    type: 'Menu',
                    label: 'Actions',
                }
            ];

        return this.columns;
    }

    getTags(): string {
        return this.prop('tags');
    }

    /**
* Détermine la classe associée à un ensemble de tags.
* @param tags Le tableau de tags à évaluer.
* @returns La classe associée aux tags ('flag', 'foundational', 'verify') ou null si aucun tag correspondant n'est trouvé.
*/
    getClassFlaged(tags: Tag[]): string | null {
        if (tags[0] && tags[0] == "flag") {
            return "flag";
        } else if (tags[1] && (tags[1] == "Published" || tags[1] == "published")) {
            return "foundational";
        } else if (tags[0] && tags[0] == "verify") {
            return "verify";
        }
        return null;
    }

    /**
     * Obtient le libellé associé à un ensemble de tags.
     * @param tags Le tableau de tags à évaluer.
     * @returns Le libellé associé aux tags ('Flagged', 'Foundational', 'Verified') ou null si aucun tag correspondant n'est trouvé.
     */
    getLabelFlag(tags: Tag[]): string | null {
        if (tags[0] && tags[0] == "flag") {
            return environment.stringsFile.flagged;
        } else if (tags[1] && (tags[1] == "Published" || tags[1] == "published")) {
            return environment.stringsFile.fondational;
        } else if (tags[0] && tags[0] == "verify") {
            return environment.stringsFile.verified;
        }
        return null;
    }

    /**
     * Compte le nombre de tags 'flag', 'verify' et 'Published' présents.
     * @param tags Le tableau de tags à évaluer.
     * @returns '+1' si à la fois 'flag' et 'Published' ou 'verify' et 'Published' sont présents, sinon null.
     */
    countFlags(tags: Tag[]): string | null {
        if (tags[0] && tags[1]?.toLowerCase() == "published") {
            return "+1";
        } else {
            return null;
        }
    }

    /**
     * Obtient le texte du tooltip en fonction des tags fournis.
     * @param tags Le tableau de tags à évaluer.
     * @returns Le texte du tooltip basé sur les tags fournis.
     */
    getTooltipText(tags: Tag[]): string {
        let tooltip = "";

        if (tags[0] && tags[0] === "flag") {
            tooltip = environment.stringsFile.flagged;
        }

        if (tooltip && tags[1]) {
            tooltip += ", ";
        }

        if (tags[1] && tags[1]?.toLowerCase() === "published") {
            tooltip += environment.stringsFile.fondational;
        }

        if (tooltip && tags[0] && tags[0] === "verify") {
            tooltip += ", ";
        }

        if (tags[0] && tags[0] === "verify") {
            tooltip += environment.stringsFile.verified;
        }

        return tooltip;
    }
    /**
        * Truncates a string of tags to a specified length.
        * @param tags - The string of tags.
        * @param at - The length to truncate at.
        * @returns The truncated string.
        */
    transform(tags: string, at: number): string {
        if (tags && tags.length && tags.length > at) {
            return tags.substring(0, at - 3).concat('...');
        } else {
            return tags;
        }
    }
}

/**
* Represents a file in Google Drive.
*/
export class GFile extends GdriveItem {
    getTypeDocument(): string {
        return this.prop('type_document', '');
    }
}

/**
* Represents a folder in Google Drive.
*/
export class GFolder extends GdriveItem {
    getTypeDocument(): string {
        return this.prop('type_document', 'Folder');
    }
}
