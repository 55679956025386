export class LinkAdd {
    Add;
    parentUid

    constructor(obj){
      this.setAdd(obj)
      this.setParentUid(obj)
    }

    getAdd(){
        return this.Add
     }

     getParentUid(){
        return this.parentUid
     }

     setAdd(obj){

        this.Add = obj.Add
     }

     setParentUid(obj){
        this.parentUid = obj.parentUid
     }
}
