import { TableColumn } from "src/design-system/core/interfaces/table";
import { SearchResultItem } from "src/modules/engines/models/search-engine-result";
import { SearchEngine } from "src/modules/engines/models/search-engine";
// import { search_config } from "src/environments/config/search_config";
import { SearchGdriveService } from "../services/search-gdrive.service";
import { GdriveFolder } from "./gdrive-folder";
import { SearchGdriveFacet } from "./search-gdrive-facet";

import { stringFile } from "src/assets/strings/strings";
import { GdriveDocumentNoSpace } from "./gdrive-document-noSpace";

export class SearchGdriveEngine extends SearchEngine {
    searchGdriveService: SearchGdriveService;
    facets = [];

    strings = stringFile.stringsJson;

    constructor(
        searchGdriveService?,
        clientContexts?
    ) {
        super();
        this.searchGdriveService = searchGdriveService;
    }

    setEngine() {
        this.engine = "gdrive";
    }

    setResults(
        engineResponse, // data returned by this engine
        prefixUrl = '' // search prefix URL for document
    ): SearchResultItem[] {
        let items = engineResponse?.items;

        // folders
        let folders = (items?.folders || [])
            .map(item =>
                new GdriveFolder(item));

        // documents
        let files = (items?.files || [])
            .map(item =>
                new GdriveDocumentNoSpace(item, true));


        this.results = [...files, ...folders];

        this.setDisplayProperties(this.results, prefixUrl);

        this.getColumns();

        return this.results;
    }

    _(s: string): string {
        return this.strings[s] || s;
    }

    displayProperties;
    setDisplayProperties(
        results: SearchResultItem[],
        prefixUrl = '') {
        this.displayProperties = results
            .map((item: SearchResultItem) => {
                return item.getDisplayProperties(prefixUrl);
            });
    }

    columns: TableColumn[];

    getColumns() {
        this.columns =
            [
                {
                    id: 'name',
                    type: 'TextWithIcon',
                    width: 310,
                },
                {
                    id: 'Flags',
                    type: 'HTML',
                    width: 100,
                },
                {
                    id: "Tags",
                    label: "Tags",
                    width: 110,
                },
                {
                    id: "owner",
                    label: "Creator",
                    type: "Text",
                    width: 110,
                    canSort: true,
                },

                {
                    id: 'type',
                    type: 'Text',
                    label: 'Type',
                    width: 110,
                },
                {
                    id: 'lastModified',
                    type: 'Date',
                    label: 'Last Modified',
                    width: 110,
                },
                {
                    id: 'rating',
                    type: 'Text',
                    label: 'Rating',
                    width: 110,
                },
                {
                    id: 'Menu',
                    type: 'Menu',
                    label: 'Actions',
                }
            ];

        return this.columns;
    }

    getDisplayProperties() {
        return this.displayProperties;
    }
    getResults() {
        return this.results;
    }

    setEngineOptions() {
        this.engineOptions = { engine: this.getEngine() }
    }

    setFacets(engineResponse) {
        // get list of facets declared by space (in backend)
        let spacesInfos = engineResponse?.infos?.spaces;
        if (!spacesInfos) {
            if (engineResponse.code == 403)
                this.authError(
                    {
                        code: engineResponse.code,
                        message: engineResponse.message || ''
                    });
        }

        // extract facet values from results
        // NB. future version should not depend on results for populating facets?
        let facetsByType = {}

        for (let sid in spacesInfos) {
            let spaceInfos = spacesInfos[sid];

            // get facets from document type
            let facetIdList = spaceInfos.facets?.fields;
            let filterLabels =
                [
                    "type_document",
                    "space"
                ];

            let facetIds: Array<string> = (facetIdList || '')?.split(',');

            filterLabels.forEach(item => {
                facetIds.unshift(item)
            })


            let facetFields = spaceInfos.facetInfos?.fields || {};
            facetIds.forEach(fname => {
                if (!facetFields[fname]) {
                    facetFields[fname] =
                    {
                        // FY : todo , get I8n labels instead...
                        label: this.labelFromId(fname),
                        type: 'string'
                    }
                }
            });

            facetsByType[sid] = [];

            facetIds.forEach(fname => {
                let label = facetFields[fname]?.label;

                let facet = new SearchGdriveFacet(
                    {
                        type: sid, // type => list of facets
                        id: fname,
                        label,
                        values: new Set()
                    });

                facetsByType[sid].push(facet);
                this.facets.push(facet);

                return facet;
            });
        }

        // add standard facets
        let defaultFacets = [];
        facetsByType[''] = [];
        ['type'].forEach(label => {
            let facet = new SearchGdriveFacet({
                type: '',
                label,
                values: new Set()
            });

            facetsByType[''].push(facet);
            defaultFacets.push(facet);
            this.facets.push(facet);
        });

        // extract values for each facet from results
        let results = this.getResults();

        this.processResults(results, facetsByType, defaultFacets)

        /*
        this.facets.forEach(f=>
            f.setValuesFromResults(f?.type,
                results));
        */

        // remove facets with no value in results
        this.facets = this.facets
            .filter((f: SearchGdriveFacet) => f.hasValues());

        //this.facets.forEach((f : SearchGdriveFacet) => f.sort());

        return this.facets;
    }

    labelFromId(fname) {
        return fname
            .replace('_', ' ')
            .split(' ')
            .map(n =>
                n.charAt(0).toUpperCase() + n.slice(1)
            )
            .join(' ');
    }

    processResults(results, facetsByType, defaultFacets) {
        results.forEach(item => {
            if (item.properties?.sid) {
                defaultFacets.forEach(facet => {
                    facet.addValue(item);
                });

                let sid = item.properties?.sid;
                if (sid) {
                    sid = sid.replace('main.', '');

                    if (sid && facetsByType[sid]) {
                        facetsByType[sid].forEach(facet => {
                            facet.addValue(item);
                        })
                    }
                }
            }
        });
    }

    authError(code) {
    }

    getFacets() {
        return this.facets;
    }
}
