import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GDocService } from 'src/modules/app-common/services';
import { DocumentService } from 'src/modules/documents/services/document.service';
import { WorkplaceService } from '../../services/workplace.service';
import {SearchService} from '../../../search/services';
import { environment } from 'src/environments/environment';
import { initListItem, ListItem } from 'src/design-system/core/interfaces/list-item';

const themePath = environment.themePath;
const searchRoute = environment.searchRoute

@Component({
  selector: 'app-people',
  templateUrl:  `./${themePath}/people.component.html`,
  styleUrls: [ `./${themePath}/people.component.scss`]
})
export class PeopleComponent implements OnInit, OnChanges  {

  constructor(private gdocService: GDocService , private route: ActivatedRoute, private searchService: SearchService,
    private router: Router, private documentService: DocumentService, private workspaceService: WorkplaceService) { }
    keywords;
    searchResults=[];
    contactsLink = environment.contactLink;
    gmailSearch = environment.gmailSearchLink;
    peoples = environment.stringsFile.peoples;

    @Input('Peoples') people


    displayProperties:ListItem[] = [];


  ngOnInit(): void {
   // this.loadContacts();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    Object.values(this.people).forEach(item=> {
      this.setDisplayProperties(item['displayProperties']);

    })
   }



   setDisplayProperties(results){
    this.displayProperties.push(results[0])
  
}


 /*  loadContacts(){
    this.workspaceService.getRecentContacts().then(res=>{
      this.people = res;
    
    })
  } */

  toMailSearch(mail){
    window.open(this.gmailSearch+mail, '_blank');
  }

  toSearchViaPeople(keyword){
    this.router.navigate(searchRoute,{queryParams: {q:keyword}});

  }


  toContacts(){
    window.open(this.contactsLink, '_blank');
  }


  emails: ListItem[] = [
    initListItem({
       id: 1,
       title: 'Security Alert',
       subtitle: 'Abdel ame',
       text: 'lorem ipsum dollar text ipsum',
       rightText: '04:30PM',
       image: '/assets/images/user-1.png',
    }),
    initListItem({
       id: 2,
       title: 'Security Alert',
       subtitle: 'Abdel ame',
       text: 'lorem ipsum dollar text ipsum',
       rightText: '04:30PM',
       image: '/assets/images/user-1.png',
    }),
    initListItem({
       id: 3,
       title: 'Security Alert',
       subtitle: 'Abdel ame',
       text: 'lorem ipsum dollar text ipsum',
       rightText: '04:30PM',
       image: '/assets/images/user-1.png',
    }),
    initListItem({
       id: 4,
       title: 'Security Alert',
       subtitle: 'Abdel ame',
       text: 'lorem ipsum dollar text ipsum',
       rightText: '04:30PM',
       image: '/assets/images/user-1.png',
    }),
    initListItem({
       id: 5,
       title: 'Security Alert',
       subtitle: 'Abdel ame',
       text: 'lorem ipsum dollar text ipsum',
       rightText: '04:30PM',
       image: '/assets/images/user-1.png',
    }),
 ];

}
