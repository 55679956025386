import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { WorkspaceMainComponent } from './containers';
import { DocumentPageComponent } from '../documents/containers/document-page/document-page.component';

const routes: Routes = 
[
  { path: 'main', 
    component: WorkspaceMainComponent, 
    data:{ title: environment.stringsFile.workspacePage}
  },
  
  {
    path: "main/file/:docId",
    component: DocumentPageComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkspaceRoutingModule { }
