<div class="container" *ngIf="noModelExist">  
    <div class="spinner-div">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="container" *ngIf="showFirstModel">
    <div class="icon-container">
        <!--<img [src]="'/assets/images/' + image + '.svg'" />-->

        <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" fill="none">
            <path
                d="M58.7812 57.75C58.7812 58.5705 58.4553 59.3574 57.8751 59.9376C57.2949 60.5178 56.508 60.8438 55.6875 60.8438H10.3125C9.49199 60.8438 8.70508 60.5178 8.12489 59.9376C7.5447 59.3574 7.21875 58.5705 7.21875 57.75C7.21875 56.9295 7.5447 56.1426 8.12489 55.5624C8.70508 54.9822 9.49199 54.6562 10.3125 54.6562H55.6875C56.508 54.6562 57.2949 54.9822 57.8751 55.5624C58.4553 56.1426 58.7812 56.9295 58.7812 57.75ZM58.7812 37.125V45.375C58.7812 46.7425 58.238 48.054 57.271 49.021C56.304 49.988 54.9925 50.5312 53.625 50.5312H12.375C11.0075 50.5312 9.69597 49.988 8.72898 49.021C7.762 48.054 7.21875 46.7425 7.21875 45.375V37.125C7.21875 35.7575 7.762 34.446 8.72898 33.479C9.69597 32.512 11.0075 31.9688 12.375 31.9688H25.6472L21.8651 14.3189C21.5753 12.966 21.5917 11.5654 21.913 10.2197C22.2343 8.8739 22.8524 7.617 23.7221 6.54093C24.5918 5.46486 25.6911 4.59685 26.9395 4.00041C28.1879 3.40397 29.5539 3.09419 30.9375 3.09375H35.0625C36.4463 3.09381 37.8126 3.4033 39.0613 3.99957C40.3101 4.59585 41.4097 5.46381 42.2797 6.53993C43.1497 7.61605 43.768 8.87309 44.0895 10.219C44.4109 11.565 44.4273 12.9658 44.1375 14.3189L40.3528 31.9688H53.625C54.9925 31.9688 56.304 32.512 57.271 33.479C58.238 34.446 58.7812 35.7575 58.7812 37.125ZM31.9688 31.9688H34.0312L38.0892 13.0221C38.1857 12.571 38.1801 12.104 38.0728 11.6553C37.9655 11.2067 37.7592 10.7877 37.469 10.429C37.1788 10.0704 36.8121 9.7812 36.3957 9.5826C35.9794 9.384 35.5238 9.28104 35.0625 9.28125H30.9375C30.4764 9.28142 30.0212 9.38467 29.6051 9.58343C29.189 9.7822 28.8226 10.0715 28.5328 10.43C28.2429 10.7886 28.0368 11.2075 27.9296 11.656C27.8225 12.1044 27.8169 12.5712 27.9134 13.0221L31.9688 31.9688ZM52.5938 38.1562H13.4062V44.3438H52.5938V38.1562Z"
                fill="#0083F6" />
        </svg>
        <i class="fas fa-icon"></i>
    </div>

    <div class="title">
        {{titleOfActions}}
        <div *ngIf="dataState?.state_infos?.actions_help" matTooltip="{{dataState?.state_infos?.actions_help}}" [matTooltipPosition]="'above'">
            <img class="align-text-bottom icon-info me-3" src="/assets/images/icon-info.svg" />
        </div>
    </div>

    <div class="description">
        {{description}}
    </div>

    <div class="actions">
        <div class="spinner-div">
            <mat-spinner *ngIf="isLoading"></mat-spinner>
        </div>
        <app-doc-actions *ngIf="!isLoading" [path]="path" (emitState)="showActionsEvent($event)"
            (infoActions)="getInfosStep($event)" [_gedDoc]="gedDoc"></app-doc-actions>
    </div>

</div>
<div class="container2" *ngIf="showSecondModel">
    <div class="section">
        <!-- Section 1 -->
        <!--<div class="left-section">
            <div class="left-title">{{titleOfActions}}</div>
            <div class="left-description">{{description}}</div>
            <div class="actions">
                <div class="spinner-div"  *ngIf="isLoading"  style="margin-left:12em;">
                    <mat-spinner></mat-spinner>
                </div>
                <app-doc-actions *ngIf="!isLoading" [path]="path" (emitState)="showActionsEvent($event)" (infoActions)="getInfosStep($event)"
                    [_gedDoc]="gedDoc"></app-doc-actions>
            </div>
            <hr class="divider">
        </div>-->

        <div class="left-section">
            <div *ngIf="dataState?.currentRole?.actor">
                <div class="conteneur">
                    <div class="title-approver">{{'Approver' | translate}} </div>
                    <span class="etiquette" [ngStyle]="{
                        'background-color': dataState?.state_infos?.background_color,
                        'color':  dataState?.state_infos?.color
                      }" class="etiquette">  <i class="{{dataState?.state_infos?.icon_cls}}"></i>
                        <span>{{dataState?.state_infos?.status}}</span></span>
                    
                </div>

                <div class="user-profile">

                    <!--<div class="profile-image">
                  <img [src]="'pumaoma@example.com'" alt="User Image">
                </div>-->
                    <app-doc-avator-creator [name]="dataState?.currentRole?.actor"
                        [icone]="dataState?.state_infos?.icon_cls"
                        [classIcone]="{'backgroundColor':backgroundColor, 'textColor':dataState?.state_infos?.color}"></app-doc-avator-creator>
                    <div class="user-details">
                        <div class="user-name">{{dataState?.currentRole?.actor}}</div>
                        <div class="user-role">
                            {{dataState?.state_infos?.title}}{{getDateNotPending(dataState?.tasks[0]?.date_creation?.time)}}
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="dataState?.tasks.length !== 0">
                <hr class="divider">
                <div class="title-approver">{{'ApprovalActivity' | translate}}</div>
                <app-doc-list-historic-approval-activity
                    [tasks]="dataState?.tasks"></app-doc-list-historic-approval-activity>
            </div>
        </div>
    </div>
</div>

<div class="actions boutons-fixes" *ngIf="showSecondModel">
    <div class="bottom-title">{{dataState?.state_infos?.actions_title ?? titleOfActions }} <img class="align-text-bottom icon-info me-3"
            src="/assets/images/icon-info.svg" *ngIf="dataState?.state_infos?.actions_help" matTooltip="{{dataState?.state_infos?.actions_help}}" [matTooltipPosition]="'above'" /></div>
    <div class="spinner-div" *ngIf="isLoading" style="margin-left:12em;">
        <mat-spinner></mat-spinner>
    </div>
    <app-doc-actions *ngIf="!isLoading" [path]="path" (emitState)="showActionsEvent($event)"
        (infoActions)="getInfosStep($event)" [_gedDoc]="gedDoc"></app-doc-actions>
</div>