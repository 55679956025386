import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
   selector: 'app-new-document',
   templateUrl: 'bootstrap/new-document.component.html',
   styleUrls: ['bootstrap/new-document.component.scss'],
})
export class NewDocumentComponent implements OnInit {
   @Input() isOpen: boolean = false;
   @Output() close = new EventEmitter<any>();

   doctypes: { label: string; value: string }[] = [
      {
         label: 'Pdf',
         value: 'pdf',
      },
      {
         label: 'Excel',
         value: 'excel',
      },
      {
         label: 'Word',
         value: 'word',
      },
   ];
   departs: { label: string; value: string }[] = [
      {
         label: 'Department 1',
         value: 'dep-1',
      },
      {
         label: 'Department 2',
         value: 'dep-2',
      },
      {
         label: 'Department 3',
         value: 'dep-3',
      },
   ];
   spaces: { label: string; value: string }[] = [
      {
         label: 'Space 1',
         value: 'spc-1',
      },
      {
         label: 'Space 2',
         value: 'spc-2',
      },
      {
         label: 'Space 3',
         value: 'spc-3',
      },
   ];

   Step_1: FormGroup;
   Step_2: FormGroup;

   steps: {
      stepOrder: number;
      title: string;
   }[] = [
      {
         stepOrder: 1,
         title: 'Type of Document',
      },
      {
         stepOrder: 2,
         title: 'Create New document',
      },
      {
         stepOrder: 3,
         title: 'Select department',
      },
      {
         stepOrder: 4,
         title: 'Choose Space',
      },
      {
         stepOrder: 5,
         title: 'Select Template',
      },
      {
         stepOrder: 6,
         title: 'New Document',
      },
   ];
   currentStep: number = 1;
   title: string = this.steps[this.currentStep - 1].title;

   constructor() {}
   closeModal() {
      this.close.emit();
      this.currentStep = 1;
   }
   nextStep() {
      this.currentStep++;
      this.title = this.steps[this.currentStep - 1].title;
   }
   prevStep() {
      this.currentStep--;
      this.title = this.steps[this.currentStep - 1].title;
   }
   ngOnInit(): void {
      this.Step_1_Form();
      this.Step_2_Form();
   }
   // Steps Forms
   Step_1_Form() {
      this.Step_1 = new FormGroup({
         doctype: new FormControl('', [Validators.required]),
      });
   }
   Step_2_Form() {
      this.Step_2 = new FormGroup({
         doctype: new FormControl('', [
            Validators.required,
            Validators.minLength(2),
         ]),
      });
   }
}
