import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DrivesData } from 'src/modules/spaces/models/drives-data/drives-data.model';
import { WorkplaceService } from 'src/modules/workspace/services/workplace.service';

@Component({
  selector: 'app-doc-recent',
  templateUrl: './doc-recent.component.html',
  styleUrls: ['./doc-recent.component.scss']
})
export class DocRecentComponent implements OnInit {
  workSpace: any;
  filesdata: DrivesData[];

  @Output() documentSelected = new EventEmitter();


  constructor(
    private workspaceService: WorkplaceService,
  ) { }

  ngOnInit(): void {
    this.loadWorkSpaceData()
  }



  async loadWorkSpaceData() {

    let recentFiles = [];

    let workspace = await this.workspaceService.getWorkspaceData();

    this.workSpace = workspace;
    recentFiles = this.workSpace.getRecentFiles();

    this.filesdata = recentFiles?.map((drive: any) => {
      const driveData = new DrivesData();
      Object.assign(driveData, { ...drive });
      driveData.setMimeType(drive.mimeType);

      return driveData;

    })
  }


  selectDocument(item) {
    this.documentSelected.emit(item);

  }

}
