import { FieldDesc } from "../field-desc/field-desc.model";

export class FormRecord {
    private _fields: any;
    private _groups: any;
    private _data: any;
    private _metadata: any;


    

	constructor(data=null) {
		this._fields={};
		this._groups={};
		this.setData(data);
	}

	setData(data) {

		if(!data)
			return;

		if(data.data)
		{
			this._data = data.data;
		}
		if(data.metadata)
		{
			let fdescs = {};
			const meta = data.metadata && data.metadata.fields || data.metadata;
			for (let p in meta)
			{
				const fdesc = new FieldDesc(meta[p],p);
				const field = fdesc.buildField(p,this._data);
				this._fields[p] = field;

				field.forEachTag((tag,field) => {
					this.addToGroup(tag,p,field);
				});
									
				fdescs[p] = fdesc;
			}
			this._metadata = { fields:fdescs };
		}
	}

	updateData(data) {
		for (let fname in data)
		{
			let field = this.field(fname);
			if(field)
				field.update(data[fname]);
		}
	}

	addToGroup(tag,n,field) {
		this._groups[tag] = (this._groups[tag] || {});
		this._groups[tag][n]=field;
	}

	fields(tags=null,skipHidden=true,key='name',value='value') {
		let fields = this._fields;
		if(!tags)
			return fields;

		let found = {};
		const atags = tags.split(",");
		for (let fname in fields)
		{
			const field = fields[fname];
			const fdesc = field.desc();

			if(skipHidden && fdesc.isHidden())
				continue;

			const matchTags = atags.filter(tag=>fdesc.hasTag(tag));
			if(matchTags.length)
				found[fdesc[key]]=field[value]();
		}
	
		return found;
	}

	field(fname) {
		return this._fields[fname] || null;
	}

	fieldsData(display=false) {
		if(!display)
			return this._data;

		let data = {};
		for (let n in this._data)
		{
			if(n.endsWith('__html'))
				continue;

			try
			{
				const field = this.fieldData(n,true);
				if(field)
					data[n] = field;				
			}
			catch (e)
			{
			}
		}
		return data;
	}

	fieldData(n, display=false) {
		if(!display)
			return this._data[n];

		const v = this._data[n];

		if(!v || typeof v.value == "undefined")
			return  v;
		else
			return v.html;
	}

	metadata() {
		return this._metadata;
	}

	data(){
		return this._data;
	}
}

