import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GDocService } from 'src/modules/app-common/services';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private router: Router, private gdocService: GDocService) { }

  ngOnInit(): void {
    this.router.navigate(['/workspace/main/']);
  }

  backHome(){
   
    /* this.gdocService.getMyDriveId().then(res=>{
     
    }) */
  }

}
