import { FormField } from "./form-field.model";

export class FormFieldDate extends FormField {

  /**
   * Update the value of the form field with the provided input.
   * @param {string} v - The input value to update the form field.
   * @returns {string} - The updated value of the form field.
   */
  updateDate(v : string) {
    try {
      if (!v){
        return (this._data = "");
      } 
      let date = new Date(v);
      this._data = date.toISOString();
      return this._data;
    } catch (error) {
      return (this._data = "");
    }
  }

  /**
   * Get the value of the form field.
   * @param {boolean} native - Optional. Set to true to return the value as a native Date object.
   * @returns {string|Date|null} - The value of the form field. If the value is null, it returns null.
   */
  getValue(native : boolean = false) {
    if (this._data === null){
        return null;
    } 
    if (native && (typeof this._data === 'string' || typeof this._data === 'number')){
      return new Date(this._data);
    }
    else{
      return this._data;
    }
  }

  /**
   * Convert the form field value to a string representation.
   * @returns {string} - The string representation of the form field value.
   */
   // @ts-ignore
  /*toString() : string{
    if (!this._data) {
        return "- / - / -";
    }
    let [y, m, d] = this._data.split("T")[0].split("-");
    return d + " / " + m + " / " + y;
  }*/
}
