import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FolderMeta } from '../../models/folder/folder-meta.model';
import { GedService, SpaceInfos } from 'src/modules/app-common/services';
import { Router } from '@angular/router';
import { SpaceService } from '../../services';
import { NgForm } from '@angular/forms';
import { PDocEventsService } from 'src/modules/p-doc-envents/p-docs-events.service';

@Component({
  selector: 'app-space-new',
  templateUrl: './space-new.component.html',
  styleUrls: ['./space-new.component.scss']
})
export class SpaceNewComponent implements OnInit {

  @Input() isOpen: boolean = false;
  @Output() close = new EventEmitter<any>();
  @ViewChild('form') public formSpace: NgForm;
  @Input("groupeName") groupeName;
  formFields: any;
  fieldAutoList = {};
  isLoading: boolean = false;
  inputData: any;
  constructor(
    private gedService: GedService,
    private router: Router,
    private spaceService: SpaceService,
    private pDocsEventsService: PDocEventsService,


  ) {
  }

  ngOnInit(): void {
    this.loadForm();
  }

  closeModal() {
    this.close.emit();
    this.formSpace?.form.reset();
    this.formSpace?.form.clearValidators();

  }


  async loadForm() {
    this.formFields = [];

    let form = await this.gedService.getNewSpaceForm();
    this.inputData = form;
    this.inputData.data= {...this.inputData.data, 'groupName':this.groupeName};

    if (!form || !form.metadata)
      return null;

    let fieldDescs = form.metadata['fields'];

    // console.log("responsemetadata",fieldDescs)

    for (let fname in fieldDescs) {
      let fdesc = fieldDescs[fname];

      let field = new FolderMeta(fname, form.data[fname], fdesc);

      if (!field.isHidden()) {
        // add form field

        if (this.groupeName && field.key === "groupName") {
          field.value = this.groupeName;
        }
        this.formFields.push(field);
      }
    }

  }

  async doFilterField(field: any): Promise<void> {
    try {
      if (field.value === '') {
        this.fieldAutoList[field.key] = [];
      }
      else {
        const res = await this.gedService.getAutocompleteList(field.value, field.auto.url);

        const autoComplete = Array.from(res);
        this.fieldAutoList[field.key] = autoComplete;

        const currentValue = this.filterr(autoComplete, field.value, field);
        const indexOf = this.formFields.findIndex((f) => f?.value === currentValue?.html);

        if (indexOf >= 0) {
          this.formFields[indexOf].autoValue = currentValue;
        }
      }
    }
    catch (error) {
      console.error('Error filtering field:', error);
    }
  }

  filterr(options, value, field) {
    let currentValue = options.find(option => option.html.toLowerCase() == value.toLowerCase());
    return currentValue;
  }


  async createSpace(values: any): Promise<void> {
    try {
      this.isLoading = true;

      const spaceinfos: SpaceInfos = {};

      for (const field of this.formFields) {
        spaceinfos[field.key] = field.auto === null ? field.value : field.autoValue;
      }

      const space = await this.gedService.createSpace(values);

      if (space) {
        await this.spaceService.clearCache();
        this.router.navigate(['/s/space', space?.['uid']]);
      }
    } catch (error) {
      console.error('Error creating space:', error);
    }
    finally {
      this.isLoading = false;
      this.pDocsEventsService.createSpaceEvent(
        values.name
      );
    }
  }

}
