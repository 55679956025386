import { DatePipe } from '@angular/common';
import { Input, OnInit, ViewChild } from '@angular/core';
import { Component} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatStepper } from '@angular/material/stepper';
import { GedService } from 'src/modules/app-common/services';
import { WorkflowService } from 'src/modules/workflow/services';
import { OneData } from '../../models/one-data/one-data.model';
import { Workflow,WorkflowRole, WorkflowUserTask } from 'src/modules/workflow/models';

const accent = '#85BFF2'
const primary = '#3f51b5'

@Component({
  selector: 'app-doc-workflow',
  templateUrl: './doc-workflow.component.html',
  styleUrls: ['./doc-workflow.component.scss']
})
export class DocWorkflowComponent implements OnInit
{
  @Input("path") path;
  @Input("gedDoc") gedDoc;

  //stepper variables
  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  @ViewChild("stepper") public stepper: MatStepper;

  //workflow variables
  currentTask: any;
  workflowTasks: any;
  workflowState: any;
  showContent = true;

  //getting chip meta data
  oneData: OneData;
  //oneData2 : OneData;
  allData= [];
  meta: any;
  doc;
  currentDocId = localStorage.docId;

  isRoleUser=true;
  pending:WorkflowRole ;
  workflowRoles: WorkflowRole[] = null ;
  showWorkflow=true;
  showState=true;

  constructor(
      //private gedService: GedService,
      private _formBuilder: UntypedFormBuilder,
      public datepipe: DatePipe,
      private workflowService: WorkflowService)
  {
    // todo : inject as input ?
    this.currentDocId = localStorage.docId;

    if(!!this.stepper)
      this.stepper.selectedIndex = 1;

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });

    this.secondFormGroup = this._formBuilder.group(
    {
      secondCtrl: ['', Validators.required]
    });

    this.getWorkflow(this.currentDocId);
  }

  ngOnInit(): void
  {
    this.getDoc();
    this.getBaseMeta();
  }

  getDoc()
  {
    this.doc = this.gedDoc.content.data;
  }

  /*-------------------------------------------------THE ON EVENTS ------------------------------------------------------------*/

  isLoading=true;
  isLoadingState=true;

  workflow: Workflow;

  getWorkflow(currentDocId)
  {
    this.workflowService.getWorkflow(currentDocId)
    .then((workflow : Workflow)=>
    {
      //on data retrieval, remove the progression spinner
      this.isLoading=false;

      this.workflow = workflow;

      this.workflowRoles = workflow?.getRoles();
      this.workflowTasks = workflow?.getTasks();
      this.workflowState= workflow?.getCurrentState();

      //if workflow is empty don't show card
      if(!workflow.hasWorkflow())
        this.showWorkflow=false;
      else
      {
        this.showWorkflow=true;
        this.currentTask = workflow.getCurrentTask();
        this.pending = workflow.getPendingRole();
      }
    });
  }

  editWorkflow(values)
  {
    this.showContent=!this.showContent;
  }

  getBaseMeta()
  {
    this.isLoadingState = false;
    let document = this.gedDoc;
    //GET METADATA JSON
    this.meta = document?.['content'].metadata.fields;

    //GET METADATA JSON
    for (var key of Object.keys(this.meta))
    {
      //SELECT METADATA WITH TAG = META
      if (!!this.meta[key]["x-tags"])
      {
        if (this.meta[key]["x-tags"] == "base_meta" || this.meta[key]["x-tags"].includes("base_meta"))
        {
          let label = this.meta[key].label;
          this.oneData = new OneData(key, label);

          if (this.doc[key] instanceof Object == true)
          {
            this.oneData.val = this.doc[key].html ;
          }
          else
          {
            this.oneData.val = this.doc[key] ;
          }

          if (this.meta[key].type == 'string')
          {
            this.oneData.type = 'text' ;
          }
          if (this.meta[key].type == 'integer')
          {
            this.oneData.type = 'number';
          }

          if (this.meta[key].type == 'date')
          {
            this.oneData.type = 'date' ;

            this.oneData.val = this.datepipe.transform(this.doc[key], 'dd/MM/yyyy') ;
          }

          if (!!this.meta[key]["x-ui"])
          {
            this.oneData.bgc = this.meta[key]["x-ui"]["color"];
            if (this.meta[key]["x-ui"]["class"] == 'md-accent')
            {
              this.oneData.bgc = accent;
            }
            if (this.meta[key]["x-ui"]["class"] == 'md-primary')
            {
              this.oneData.bgc = primary;
            }
            this.oneData.color = 'white';
          }

          this.allData.push(this.oneData);
        }
      }
    }

    if (this.allData.length == 0)
    {
      this.showState = false;
    }
    else
    {
      this.showState = true;
    }
  }

  showActions=true;
  doSomething(showActions)
  {
    this.showActions = showActions;
  }
}
