export const  Colors =[
    "#BC1A2D",
    "#9B1C1F",
    "#ED5261",
    
    "#6500ED",
    "#3D00B1",
    "#3D62A3",
    "#4A6FBF",
    "#467CBB",
    "#6483F9",
    "#5694DE",
    
    "#6388CE",
    "#4A9CC9",
    "#B733D1",
    "#8158F3",
    "#F1B800",
    "#A98014",
    "#39485F",
    "#1C2024",
    
    "#5CBF81",
    "#4EBE99",
    "#3CA151",
    "#4AB258",
    "#4DDD00"		
    
]