// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// ============ FORM MODEL ===========
class FieldDesc {
  constructor(desc, fname) {
    for (var k in desc) this[k] = desc[k];

    this["fname"] = this["name"] = fname;

    if (desc["x-tags"]) this.tags = desc["x-tags"];
    else this.tags = [];

    if (desc["x-ui"]) this._ui = desc["x-ui"];
    else this._ui = {};

    if (desc["x-control"]) this._control = desc["x-control"];

    if (desc["control"]) this._control = desc["control"];

    if (desc["x-values"]) {
      this.selectValues = desc["x-values"];
    }

    if (desc["x-dynamic-values"]) {
      this.enumValues = desc["x-dynamic-values"];
      this.fieldTitle = this.enumValues["field-title"] || "html";
    } else if (desc["enum"]) {
      const listItems = Object.keys(desc["enum"]).map((key, index) => ({
        label: desc["enum"][key as keyof typeof desc["enum"]],
        value: key,
      }));

      this.enumValues = { data: listItems };
      this.fieldTitle = "html";
    } else this.fieldTitle = "html";

    if (typeof desc["x-show"] != "undefined") this.show = desc["x-show"];

    this.required =
      typeof desc["required"] != "undefined"
        ? desc["required"] == "true" || desc["required"] === true
        : false;
  }

  enums() {
    return this.enumValues.data;
  }

  getEnum(v) {
    let e;

    if (this.enumValues && this.enumValues.data && this.enumValues.data.length)
      this.enumValues.data.forEach((t) => {
        if (t.value == v) e = t;
      });

    return e;
  }

  setFieldData(data: any) {
    if (this.selectValues) {
      this.selectValues = { ...this.selectValues, data };
      return;
    }
    this.enumValues = { ...this.enumValues, data };
  }

  isHidden() {
    return this.show === false;
  }
  isRequired() {
    const r = this.required === true;
    return r || null;
  }
  hasTag(t) {
    return this.tags.includes(t);
  }
  ui(type, deft) {
    return typeof this._ui[type] != "undefined" ? this._ui[type] : deft;
  }

  buildField(n, data) {
    let v = data[n];
    if (v === null) v = "";

    if (this.type == "date") {
      this.control = "date";
      return new FormFieldDate(v, this);
    }

    if (this.type == "color") {
      this.control = "color";
      return new FormFieldDate(v, this);
    }

    if (this.type == "icon") {
      this.control = "icon";
      return new FormFieldDate(v, this);
    }

    let html;
    if (typeof data[n + "__html"] != "undefined") {
      html = data[n + "__html"] || "";
      let ftitle = this.fieldTitle;
      v = { value: v };
      v[ftitle] = html;
    } else html = null;

    if (this.enum) {
      this.control = this._control || "enum";
      return new FormFieldEnum(v, this);
    }

    if (this.selectValues) {
      this.control = this._control || "select";
      return new FormFieldEnum(v, this);
    }

    if (this.enumValues) {
      this.control = this._control || "autocomplete";
      return new FormFieldEnum(v, this);
    }

    this.control = this._control || "text";
    return new FormField(v, this);
  }
}

class FormField {
  constructor(data, desc) {
    this._desc = desc;
    this.set(data);
  }

  async init(fields = null, params = null, cb = null) {
    return this._data;
  }

  data() {
    return this._data;
  }

  desc() {
    return this._desc;
  }

  control() {
    return this._desc._control || "text";
  }

  set(v) {
    this._data = v;
    return this._data;
  }
  update(v) {
    this._data = v;
    return this._data;
  }

  // to be used by record.fields(tags,true,'name','field')
  field() {
    return this;
  }

  value() {
    if (this._data === null) return null;

    if (this._data.hasOwnProperty("value")) return this._data.value;

    return this._data;
  }

  toString() {
    if (this._data === null || typeof this._data == "undefined") return "";
    return this._data.html || this._data.value || this._data;
  }

  forEachTag(cb) {
    const tags = this._desc.tags;
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      cb(tag, this);
    }
  }
}

class FormFieldDate extends FormField {
  update(v) {
    try {
      if (!v) return (this._data = "");

      let date = new Date(v);
      // let date = Date.parse(v);
      this._data = date.toISOString();
      return this._data;
    } catch (error) {
      return (this._data = "");
    }
  }

  toString() {
    if (!this._data) return "- / - / -";

    let [y, m, d] = this._data.split("T")[0].split("-");
    return d + " / " + m + " / " + y;
  }
}

class FormFieldEnum extends FormField {
  constructor(data, desc) {
    if (typeof data != "object") data = { value: data, html: "" };

    super(data, desc);
  }

  static build(data, enums, fname, label = "") {
    let desc = new FieldDesc({ type: "string", label, enum: enums }, fname);
    return new FormFieldEnum(data, desc);
  }

  getEnum() {
    const v = this.value();
    return this._desc.getEnum(v);
  }

  async init(fields = null, params = null, cb = null) {
    if (
      this._desc.control == "select" &&
      this._desc.enumValues &&
      this._desc.enumValues.url
    ) {
      // get url
      let url = this._desc.enumValues.url;

      // add query params
      if (this._desc.enumValues.query) {
        let query = this._desc.enumValues.query;
        for (let p in query) {
          const f2 = query[p];
          if (fields[f2]) {
            const v = fields[f2] ? fields[f2].value() : "";
            url += "&" + p + "=" + v;
          } else if (typeof params[f2] != "undefined") {
            const v = params[f2] || "";
            url += "&" + p + "=" + v;
          }
        }
      }

      if (cb) {
        const data = await cb(url);
        this._desc.enumValues.data = data;
        if (data.length == 1) {
          this._data.value = data[0].value;
          this._data.html = data[0].html;
        }
        return this._data;
      } else return this._data;
    } else return this._data;
  }

  toString() {
    if (this._data === null || typeof this._data == "undefined") return "";

    if (typeof this._data != "object") return this._data;

    if (this._desc.enum) {
      let v = this.value();
      return this._desc.enum[v] || v;
    }

    return (
      this._data[this._desc.fieldTitle] ||
      this._data.html ||
      this._data.value ||
      "-"
    );
  }

  update(data) {
    if (this._data === null || typeof this._data == "undefined") {
      this._data = { value: "", html: "" };
    }

    if (data === null && this._data) {
      this._data.value = "";
      this._data.html = "";
    }

    if (data && typeof data.value != "undefined") {
      this._data.value = data.value || "";

      if (data.html) this._data.html = data.html || data.value || "";
      else if (this._data.value) {
        const enu = this._desc.getEnum(this._data.value);
        this._data.html = enu.html;
      }
    }

    if (data && typeof data.value == "undefined") {
      this._data.value = data || "";
      const enu = this._desc.getEnum(this._data.value);
      this._data.html = enu.html;
    }

    return this._data;
  }
}

export class FormRecord {
  constructor(data = null, source = "doc", params = null, loadDataCB = null) {
    this._fields = {};
    this._groups = {};
    this._params = params;
    this._source = source;
    this.loadDataCB = loadDataCB;

    this.setData(data, loadDataCB);
  }

  setData(data, loadDataCB) {
    if (!data) return;

    if (data.data) {
      this.data = data.data;
    }

    if (data.metadata) {
      let fdescs = {};
      const meta = (data.metadata && data.metadata.fields) || data.metadata;
      if (this._source === "doc") {
        for (let p in meta) {
          let xtag = meta[p]["x-tags"] || [];
          if (xtag.includes("meta")) {
            const fdesc = new FieldDesc(meta[p], p);
            const field = fdesc.buildField(p, this.data);
            this._fields[p] = field;

            field.forEachTag((tag, field) => {
              this.addToGroup(tag, p, field);
            });

            fdescs[p] = fdesc;
          }
        }
      } else {
        for (let p in meta) {
          const fdesc = new FieldDesc(meta[p], p);
          const field = fdesc.buildField(p, this.data);
          this._fields[p] = field;

          field.forEachTag((tag, field) => {
            this.addToGroup(tag, p, field);
          });

          fdescs[p] = fdesc;
        }
      }

      this._metadata = { fields: fdescs };

      if (this._source === "doc") {
        for (let p in meta) {
          let xtag = meta[p]["x-tags"] || [];

          if (xtag.includes("meta")) {
            (async () => {
              const v = await this._fields[p].init(
                this._fields,
                this._params,
                loadDataCB
              );
              if (v && typeof v.value != "undefined") {
                this.data[p].value = v.value;
                this.data[p].html = v.html;
                console.log("Updated field " + p + " = " + v);
              }
            })();

            this.data[p] = this._fields[p].data();
          }
        }
      } else {
        for (let p in meta) {
          (async () => {
            const v = await this._fields[p].init(
              this._fields,
              this._params,
              loadDataCB
            );
            if (v && typeof v.value != "undefined") {
              this.data[p].value = v.value;
              this.data[p].html = v.html;
              console.log("Updated field " + p + " = " + v);
            }
          })();

          this.data[p] = this._fields[p].data();
        }
      }
    }
  }

  updateData(data, init = true) {
    // update values
    for (let fname in data) {
      let field = this.field(fname);
      if (field) {
        field.update(data[fname]);
        this.data[fname] = this._fields[fname].data();
      }
    }

    // init enum data
    if (init)
      for (let fname in data) {
        let field = this.field(fname);
        if (field && field.init)
          field.init(this._fields, this._params, this.loadDataCB).then((v) => {
            this.data[fname] = v;
          });
      }
  }

  addToGroup(tag, n, field) {
    this._groups[tag] = this._groups[tag] || {};
    this._groups[tag][n] = field;
  }

  fields(tags = null, skipHidden = true, key = "name", value = "value") {
    let fields = this._fields;
    if (!tags) return fields;

    let found = {};
    const atags = tags.split(",");
    for (let fname in fields) {
      const field = fields[fname];
      const fdesc = field.desc();

      if (skipHidden && fdesc.isHidden()) continue;

      const matchTags = atags.filter((tag) => fdesc.hasTag(tag));
      if (matchTags.length) found[fdesc[key]] = field[value]();
    }

    return found;
  }

  field(fname) {
    return this._fields[fname] || null;
  }

  fieldsData(display = false) {
    if (!display) return this.data;

    let data = {};
    for (let n in this.data) {
      if (n.endsWith("__html")) continue;

      try {
        const fdata = this.fieldData(n, true);
        if (typeof fdata != "undefined") data[n] = fdata;
      } catch (e) {
        console.error("error on field " + n);
      }
    }
    return data;
  }

  fieldData(n, display = false) {
    if (!display) return this.data[n];

    const v = this.data[n];

    if (!v || typeof v.value == "undefined") return v;
    else return v.html;
  }

  metadata() {
    return this._metadata;
  }

  getData(): [] {
    return this.data;
  }

  getMetaKeys(): [] {
    return Object.keys(this._fields);
  }
}
