import { Router } from '@angular/router';

import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateService, GedService } from 'src/modules/app-common/services';
import { DateAdapter } from '@angular/material/core'; 
import { environment } from 'src/environments/environment';
import { MetaDocument } from '../../models/meta/meta-document.model';

const accent = '#ff4081'
const primary = '#3f51b5'
const white = '#FFFFFF'

@Component({
  selector: 'app-space-doc-desc',
  templateUrl: './space-doc-desc.component.html',
  styleUrls: ['./space-doc-desc.component.scss']
})
export class SpaceDocDescComponent implements OnInit,OnChanges {

  @Input('document') currentDocument; 

  formValues=[];
  path = '/';
  currentDocId;
  showContent = true;
  showMeta=true;
  showAuto=false;
  isLoading=true;
  doc;
  responseDoc;
  docTemp;

  meta: any;

  chipContent:MetaDocument[]=[];
  contentMeta :MetaDocument[]=[];

 colors_ged: string[] = environment.colors_ged;

mimeType;

  constructor(private gedService: GedService, private _snackBar: MatSnackBar,
    public datepipe: DatePipe, private dateAdapter: DateAdapter<any>, private dateService: DateService)
  { 

    }
  ngOnChanges(changes: SimpleChanges): void {
    this.onDocChange(changes.currentDocument.currentValue)
  }

  onDocChange(currentDoc){
    this.chipContent=[]
    this.path+=currentDoc.Name;
    this.currentDocId= currentDoc.id
    this.mimeType=currentDoc.mimeType;

    
    //this.docService.getTypeFromIcon(this.iconLink)
    if(this.mimeType=='application/vnd.google-apps.spreadsheet'){
      this.path+='.gsheet'

    }
    if(this.mimeType=='application/vnd.google-apps.document'){
      this.path+='.gdoc'

    }
    if(this.mimeType=='application/vnd.google-apps.presentation'){
      this.path+='.gpres'

    }
    if(this.mimeType=='application/vnd.google-apps.forms'){
      localStorage.setItem('path','forms')
      this.path+='.forms'

    }
  
  this.getDoc(this.path,this.currentDocId);

  }

  ngOnInit(): void {
  
  }

  /*------------------------------------------------- THE GETS ------------------------------------------------------------*/

  
  OnDateChange(eventvalue,i): void {
    
    this.formValues[i]=eventvalue;
}

  /*------------------------------------------------- THE GETS ------------------------------------------------------------*/

  typeDoc;
  getDoc(path,currentDocId){
    this.gedService.getDoc(currentDocId,path)
    .then((data)=>{
      this.responseDoc = data;
      let gedDoc= data.content.data;
      this.doc = data.content.data;
      this.typeDoc=this.doc.type_document.html
      this.getMetas(this.path,this.currentDocId,gedDoc);

      let indexx = this.typeDoc.length-1;
      if(this.typeDoc[indexx] =="s") this.typeDoc = this.typeDoc.slice(0,indexx)

    });

  }


  getMetas(path, currentDocId, gedDoc){
    this.gedService.getMeta(path,currentDocId)
    .then((meta)=>{
      //on data retrieval, remove the progression spinner
      this.isLoading=false;

      this.meta = meta;
      let i = 0;
      for (var key of Object.keys(this.meta)){
        if(this.meta[key]["x-tags"] == "meta"){

          let data = this.meta[key]
           let doc = gedDoc[key] || '-';
        this.chipContent.push(new MetaDocument(key , doc ,data))
            
          
          if(this.meta[key]["x-dynamic-values"]){
            this.getAutocompleteMeta(key,i);
            if(this.chipContent[i].value=='-') 
            this.chipContent[i].dataType='object'
          }
          if(this.chipContent[i].dataType!='object'){}
          if(this.chipContent[i].dataType=='object'){
            if(gedDoc[key]?.html=='-' || gedDoc[key]?.html==null
              || gedDoc[key]?.value=='-' || gedDoc[key]?.value==null){
                gedDoc[key]=null;
                this.chipContent[i].value={html:"-",value:"-"}
              }
            }
            
          if(this.meta[key]["type"]=='date'){
            this.chipContent[i].type ='date'
            let d = new Date(this.chipContent[i].value) ; 
            d=this.dateService.transformTime(d)

            this.chipContent[i].value= d;
           

          }
          else{
            if(this.meta[key]["type"]=='number') this.chipContent[i].type ='number'
            else{ this.chipContent[i].type ='text'}
            
          }

          if(!!this.meta[key]["x-ui"]){
            this.chipContent[i].background = this.meta[key]["x-ui"]["color"];
            if (this.chipContent[i].background=='blue') this.chipContent[i].background=primary
            if (this.chipContent[i].color=='blue') this.chipContent[i].color=primary
            this.chipContent[i].color= 'white'
          }
          
            this.formValues[i]=this.chipContent[i].value;
          
          i++  
        }
      }

      if(this.chipContent.length==0) this.showMeta=false;

      else this.showMeta=true;

    }
  )
  }

  getAutocompleteMeta(key,i){
        this.chipContent[i].showAuto=true;
        this.chipContent[i].autoUrl=this.meta[key]["x-dynamic-values"]["url"];
        this.chipContent[i]["field-title"]=this.meta[key]["x-dynamic-values"]["field-title"];
        this.chipContent[i]["field-value"]=this.meta[key]["x-dynamic-values"]["field-value"];      
  }


  doFilter(i:number) {
    if(this.formValues[i].html=='-'){}
    else if(this.formValues[i].html=='' || this.formValues[i].html==' '){
      this.chipContent[i].autoComplete=[]
      this.formValues[i]={html: "-",value: "-"};

    }
    else{
      this.gedService.getAutocompleteList(this.formValues[i].html,this.chipContent[i].autoUrl)
      .then(res=>{  
        this.chipContent[i].autoComplete=res;
        for(let item of this.chipContent[i].autoComplete){
          if(this.formValues[i].html == item.html) this.formValues[i]=item;
          else this.filter(item,i)
        }
      })   
    }

  }
  
  filter(values,i: number) {
    return values.filter(option => 
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      option.toLowerCase().includes(this.formValues[i].html)
      )
  }
  onInputChange(i){
  }

  onUpdateMeta(form){
   
    
    for(let i in this.formValues){
      let key = this.chipContent[i].meta;
      this.docTemp = this.doc;

      if(this.chipContent[i].dataType!="object"){
        this.chipContent[i].value = this.formValues[i]
        this.docTemp[key]=this.chipContent[i].value         
      }
      else{
        if(this.formValues[i].html=="-") {
          this.chipContent[i].value={html:"-",value:"-"}
          this.docTemp[key]=null
        }

        else{

          this.chipContent[i].value=this.formValues[i]
          this.docTemp[key]=this.formValues[i]


        }
      }


    }
 
    
    this.gedService.updateMetadata(this.responseDoc, this.docTemp)
    .then(res=>{
    })  
    this._snackBar.open("Document Updated!", null, {
      duration: 2000,
    });
    
    this.showContent=!this.showContent;  

  }

  
}
