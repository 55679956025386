import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GDocService, GedService } from 'src/modules/app-common/services';
import { DocumentPageComponent } from '../../containers';
import { DocumentService } from '../../services/document.service';
import { DocTypeAddComponent } from '../doc-type-add/doc-type-add.component';

@Component({
  selector: 'app-doc-sidebar',
  templateUrl: './doc-sidebar.component.html',
  styleUrls: ['./doc-sidebar.component.scss']
})
export class DocSidebarComponent implements OnInit ,OnChanges {


  @Input('path') path;
  white="#FFF";
  @Input('documentCurent') documentCurent;


  constructor(private route: ActivatedRoute, private gedService: GedService, private gdocService: GDocService, public dialog: MatDialog,private changeDetector: ChangeDetectorRef,
    private docPage: DocumentPageComponent, private router: Router,private docService: DocumentService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if( changes.documentCurent){
 
      this.isDocinGed(this.docId);
      
     }  }

  ngOnInit(): void {
    this.route.params.forEach(params => {
      this.docId= params["docId"]
      //this.isDocinGed(this.docId);
    })

    
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
  docId;


  isDocinGed(docId){
    let gedDocument =   this.documentCurent
       if (
         !!gedDocument &&
         gedDocument != null &&
         typeof gedDocument == "object"
       ) {
         this.doc = gedDocument;
          this.inGed = true;
       } else {
          this.inGed = false;
       }
 
  }

  toggleBar(){
     this.docPage.toggleGedBar();

  }
  parentId;
  
  async backClicked() {

    this.parentId = await this.gdocService.getDocumentParent(this.docId);
    if(this.parentId) this.router.navigate(['/s/space/'+this.parentId],{queryParams:{ d: localStorage.host_domain}})

  }
  

  openInDrive(){
    //replace drive url with the real folder url
    
    this.route.params.forEach(params => {
      let id= params["docId"]

      this.gdocService.getFile(id)
      .then()
      .then(data=>{
        this.doc=data;
        
         window.open(this.doc.webViewLink, "_blank");

      })
    })
  }

  detachFromGed(){
    this.docService.detachFromGed()
  }

  deleteDoc(){
   this.docService.deleteDoc(this.inGed)
 
  }

  doc=null;
  inGed=false;
  showLinks=true;
  doSomething(showLinks){
    this.showLinks=showLinks;
  }
  dataAdd = null
    getData(data){

      this.dataAdd =data  

}



 //Create new Document Type
 openDocumenttypeDialog(): void {
  const dialogRef = this.dialog.open(DocTypeAddComponent, {
    width: "500px",
    minWidth: "650px",
    height: "max-content",
    data: {
      title: environment.stringsFile.newDoc,
      add: this.dataAdd?.Add,
      parentId: this.dataAdd?.parentUid,
    },
  });
  dialogRef.afterClosed().subscribe((result) => {});
}

  @Output() emitState: EventEmitter<any> = new EventEmitter();

  updateMetas(event){
    if(event.reloadDoc){
      if(event.reloadDoc==true){
        this.isDocinGed(this.docId)
      }
    }
  }

  updateDoc(event){
    if(event.reloadDoc){
      if(event.reloadDoc==true){
        this.isDocinGed(this.docId)
        this.emitState.emit({reloadDoc:true})
      }
    }
  }
}
