import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Page } from 'src/design-system/core/interfaces/page';
import { environment } from 'src/environments/environment';

const $theme = environment.themeUI;

@Component({
   selector: 'app-left-aside',
   templateUrl: $theme + '/left-aside.component.html',
   styleUrls: [$theme + '/left-aside.component.scss'],
})
export class LeftAsideComponent implements OnInit {
   @Input() isOn: boolean = false;
   @Output() closeSideMenu = new EventEmitter();
   isDocModalOpen = false;

   pages: Page[] = [
      {
         pagename: environment.stringsFile.homeSideNav,
         path: '/',
         icon: 'gg-home-alt',
      },
      {
         pagename: environment.stringsFile.homeSideNav,
         path: '/tasks',
         icon: 'gg-play-list-check',
      },
      {
         pagename: 'Groups',
         path: '/groups',
         icon: 'gg-organisation',
         subpages: [
            {
               pagename: 'Marketing',
               path: '/groups/marketing',
               icon: 'gg-organisation',
            },
            {
               pagename: 'Projects',
               path: '/groups/projects',
               icon: 'gg-organisation',
            },
            {
               pagename: 'Clients',
               path: '/groups/clients',
               icon: 'gg-organisation',
            },
            {
               pagename: 'Sales',
               path: '/groups/sales',
               icon: 'gg-organisation',
            },
            {
               pagename: 'Proposal',
               path: '/groups/proposal',
               icon: 'gg-organisation',
            },
         ],
      },
      {
         pagename: 'Analytics',
         path: '/analytics',
         icon: 'gg-trending',
      },
      {
         pagename: 'Users',
         path: '/users',
         icon: 'gg-user-list',
      },
      {
         pagename: 'Support',
         path: '/support',
         icon: 'gg-support',
         isLastItem: true,
      },
   ];
   constructor() {}

   ngOnInit(): void {}
   togglesidebar() {
      this.closeSideMenu.emit();
   }
   close() {
      this.isDocModalOpen = false;
   }
   open() {
      this.isDocModalOpen = true;
   }
   onClick(event: any) {
      event.currentTarget.classList.toggle('');
   }
}
