import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APISce } from 'src/modules/app-common/services';


const BACKEND_URL = environment.BACKEND_URL;


@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(
    private api: APISce,
  ) {
  }



  engineOptions: any = [
    { engine: 'gcontact2' },
    { engine: 'gcontact' },
    { engine: 'gpeople' },
    { engine: 'gcal' },
    { engine: 'gdrive' },
    { engine: 'ged' }
  ];

  async callSearch(url: string, data: {}) {
    let options = this.api.httpConfig();
    return this.api.post(url, data, options)
  }

  async searchRes(keywords: string, fromFolderId: string = null) {
    let url = `/search/query`;

    if (fromFolderId) {
      url += `&folderId=${fromFolderId}`;
    }

    let data: any =
    {
      engineOptions: [],
      pageSize: 25,
      query: ''
    };

    data.engineOptions.push(...this.engineOptions);
    data.query = keywords;

    let response = await this.callSearch(url, data);

    let searchResults: any =
    {
      ged: [],
      gdrive: [],
      gcontact: [],
      gcontact2: [],
      gpeople: [],
      gcal: [],
      facets: [],
    };

    let facets;
    let ged = [];
    let gdrive = [];
    let gcontact = [];
    let gcontact2 = [];
    let gpeople = [];
    let gcal = [];

    if (response?.['content']?.['groups'].length > 0 && response?.['errors'] == undefined) {
      let enginesRes = response?.['content']?.['groups'] || [];

      enginesRes.forEach((engineData) => {
        let engineId = engineData?.['engine'];

        if (engineId == 'gdrive') {
          //console.log('filterListes',item)
          let folders = engineData['items']?.['folders'] || [];
          let files = engineData['items']?.['files'] || [];
          let infos = engineData['infos'] || [];

          gdrive.push(...folders);
          gdrive.push(...files);
          facets = infos;

          searchResults.gdrive.push(...gdrive);
          searchResults.facets.push(facets);
        }
        else if (engineId == 'ged') {
          //console.log('filterListes',item)
          let folders = engineData['items']?.['folders'] || [];
          let files = engineData['items']?.['files'] || [];
          let infos = engineData['infos'] || [];

          gdrive.push(...folders);
          gdrive.push(...files);
          facets = infos;

          searchResults.gdrive.push(...gdrive);
          searchResults.facets.push(facets);
        }
        else if (engineId == 'gcal') {
          let events = engineData['items'] || [];
          gcal.push(...events)
          searchResults.gcal.push(...gcal);
        }
        else if (engineId == 'gcontact') {
          let contacts = engineData['results'] || [];
          gcontact.push(...contacts)
          searchResults.gcontact.push(...gcontact);
        }
        else if (engineId == 'gcontact2') {
          let contacts2 = engineData['results'] || [];
          gcontact2.push(...contacts2)
          searchResults.gcontact2.push(...gcontact2);
        }
        else if (engineId == 'gpeople') {
          let peoples = engineData['people'] || [];
          gpeople.push(...peoples)
          searchResults.gpeople.push(...gpeople);
        }
      });
    }
    else if (response?.['errors']) {
      response?.['errors'].forEach((engineError) => {
        let engineId = engineError?.['engine'];

        if (engineId == 'gdrive' || engineId == 'gcal') {
          console.warn("Search engine error => signout", engineId, engineError);
          // this.googleLoginService.signOut(); // to be seen after // ag
        }
      });
    }

    return searchResults;
  }
}
