import { environment } from "src/environments/environment";

const tomorrow = environment.stringsFile.tomorrow;
const confirm = environment.stringsFile.confirmed;

export class Event {
  summary;
  htmlLink;
  date;
  hangoutLink;
  status;
  constructor(event) {
      this.setSummary(event)
      this.setHtmlLink(event)
      this.setDate(event)
      this.setHangoutLink(event)
      this.setStatus(event)
  }

  getSummary() {
    return this.summary;
  }
  getHtmlLink() {
    return this.htmlLink;
  }
  getDate() {
    return this.date;
  }
  getHangoutLink() {
    return this.hangoutLink;
  }
  getStatus() {
    return this.status;
  }
  setSummary(event) {
    this.summary = event.summary;
  }
  setHtmlLink(event) {
    this.htmlLink = event.htmlLink;
  }
  
  setDate(event) {
    let start = event.start.dateTime;
    let d = new Date(start);
    let options;
    let options2
    let rel = this.relative(d);
      let s ;
       options = { hour12: false  , hour: '2-digit', minute: '2-digit'  };
        options2 = {   month: "long", day: "numeric"};
    if(rel == 'today')
    {
    
      s =  d.toLocaleTimeString('fr-Fr', options) ;
    }
     

   else if(rel == 'tomorrow'){
      s =  tomorrow +' ' + d.toLocaleTimeString('fr-Fr', options) ;
    }else
    
       s = d.toLocaleDateString('fr-Fr', options2) + " à " + d.toLocaleTimeString('fr-Fr', options);
       
    
    this.date = s
  }
  
  setHangoutLink(event) {
    //console.log("setHangoutLink",event)
    this.hangoutLink = event.hangoutLink;
  }
  setStatus(event) {

    if(event.status == "confirmed"){
      this.status = confirm;
    }
      else{
        this.status = event.status
      }
     
  }









  relative(d: Date) {
    let now = new Date();

    if(d.toDateString() === now.toDateString())
        return 'today';

    var tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    if (tomorrow.getFullYear() == d.getFullYear() && tomorrow.getMonth() == d.getMonth() && tomorrow.getDate() == d.getDate()) {
        return "tomorrow"; // date2 is one day after date1.
    }

    return 'other';
  }



}
