
// =============== WORKFLOW DATA RECEIVED FROM SERVER =========

import { WorkflowFileAction } from "src/modules/documents/models";
import { TTaskRoleFrom } from "src/modules/tasks/model/ged-task.model";

export type TaskUser = {
  html : string,
  value
}

interface WorkflowDate {
  value: string;
  time: number;
}

export type InfosStepTask = {
  description: string;
  titleOfActions: string;
  icon: string;
}

/** workflow task data received from server */
interface WorkflowTaskData
{
  /** action executed */
  action: string;

  date_creation: WorkflowDate;
  time: number;
  value: string;
  email: string;

  from_user: TaskUser;

  item_data: string;

  /** resource id */
  item_oid: string;

  message: string;

  /** task id */
  oid: string;

  /** permission for the action, usually empty */
  permission: string;

  /** role id */
  role: string;

  /** task state */
  state: "pending" | "completed";

  /** is it a task for approval */
  type: "submit" | "approval";

  /** user having executed the action */
  user_oid: TaskUser;

  /** workflow id@package */
  workflow: string;


}

/** workflow role data received from server */
export interface WorkflowDataRole
{
  date_action: WorkflowDate;
  file_oid: string;
  function: { html : string, value : string};
  index: string;
  oid: string;
  project_role: { html : string, value : string};
  role: { html : string, value : string};
  role_user: { html : string, value : string};
  signature: string;
  signed_by: { html : string, value : string};
}

/** workflow role information with id, title, list of states */
export interface IWorkflowRoleInfo
{
    id :string;
    title: string;
    states: string[];
    description?:string
}

/** workflow action data received from server */
export interface WorkflowActionData
{
  action?: string;
  id?: string;
  name: string;

  /** display options */
  options?: {
    in_menu?:boolean,
    in_buttons?:boolean,
    display_steps?:boolean,
    color?:string,
    icon_cls?:string
  };

  /** action description */
  description?: string;

  dialog?:any;
}

export class WorkflowAction implements WorkflowActionData
{
  action: string;
  iconCls: string;
  inButtons: boolean;
  inMenu: boolean;
  name: string;

  options: {
    in_menu?:boolean,
    in_buttons?:boolean,
    color?:string,
    icon_cls?:string,
  };


  /** action description */
  description?: string;

  dialog?:any;

  button_class?:string;
  text_class?:string;
  role: string;

  constructor(
    data : WorkflowActionData,
    roleName : string = "default")
  {
    this.action = data.action || data.id;

    // display options
    this.options = data.options || {}
    this.iconCls = this.options.icon_cls;
    this.inButtons = this.options.in_buttons == undefined ? true : this.options.in_buttons;
    this.inMenu = !!this.options.in_menu;

    this.name = data.name;
    this.description = data.description;
    this.dialog = data.dialog;

    this.role = roleName;
  }

  setColors(colors : {primary,white,black} )
  {
    const {primary,white,black} = colors;
    if (!!this.button_class)
    {
      var color = this.button_class
      if(color=="primary")
      {
        this.button_class = primary;
        this.text_class = white;
      }
      else
      {
        if(color=="alert")
        {
          this.button_class = white;
          this.text_class = black;
        }
        else
        {
          this.button_class = white;
          this.text_class = primary;
        }
      }
    }
  }

  setRole(role:string) {
    this.role = role;
  }

  hasDialog() : boolean
  {
    return !!this.dialog;
  }
}

/** workflow action data received from server */
export interface WorkflowData
{
  state;

  /** display options */
  options?: {
    can_edit?:boolean,
    steps?:boolean,
    color?:string,
    icon_cls?:string
  };

  roles : { items };
  tasks : { items: WorkflowTaskData[] };
  actions : Record<string,WorkflowActionData>;
}

// =============== MODELS =================

/** Workflow user task model */
export class WorkflowUserTask
{
  item : WorkflowTaskData;
  state : string;
  iconLink : string;
  version : string;
  picture : string;
  action  : string;
  userName : string;
  dateCompletion;
  task_state? : string;

  /** true if task is still pending */
  pending : boolean;

  constructor(
    item: WorkflowTaskData,
    picture = null)
  {
    this.item = item;
    this.setState(item);
    this.setIconLink(item);

    this.setPicture(picture);
    this.setAction(item);
    this.setUserName(item);
    this.setDateCompletion(item);

    this.pending = !this.isPending();
  }

  getFromUser() : TaskUser{
    return this.item.from_user;
  }

  isPending() : boolean
  {
    // NB. bug in data retreived : the state is always "pending",
    // so check if a user has validated the task instead..
    return this.item.state == "pending" && !this.item.user_oid?.value;
  }

  getState() {
    return this.state;
  }

  getRoleName() {
    return this.item.role;
  }

  getIconLink() {
    return this.state;
  }

  getPicture() {
    return this.picture;
  }
  getAction() {
    return this.action;
  }
  getUserName() {
    return this.userName;
  }
  getUserOid() {
    return this.item.user_oid.value;
  }

  getDateCompletion() {
    return this.dateCompletion;
  }
  getVersion() {
    return this.version;
  }

  setState(item) {
    this.state = item.workflow_state;
  }
  setIconLink(item) {
    this.state = item.workflow_state;
  }

  setPicture(picture) {
    this.picture = picture;
  }
  setAction(item) {
    this.action = item.action;
  }
  setUserName(item) {
    this.userName = item.user_oid.html;
  }
  setDateCompletion(item) {
    this.dateCompletion = item.date_completion?.value;
  }

  setVersion(item) {
    this.version = item.version;
  }
}

/** Workflow role model */
export class WorkflowRole
{
  role_user : {
    html: string,
    value
  }

  data;

  task : WorkflowUserTask;

  /** true if role is the current one to execute in the workflow */
  isPending: boolean = false;

  /** true if role has been executed */
  isDone: boolean = false;

  /** true if role is current */
  isCurrent: boolean = false;

  /** role description */
  description: string;

  name : string;

  constructor(data : IWorkflowRoleInfo)
  {
    this.data = data;
    this.name = this.getName();
    this.description = this.getDescription();
  }

  setIsDone(done=true) {
    this.isDone = done;
    this.isPending = !done;
    this.isCurrent = false;
  }

  setIsCurrent() {
    this.isDone = false;
    this.isPending = true;
    this.isCurrent = true;
  }

  /** checks if the role has this state in the workflow */
  hasState(stateName : string)
  {
    return this.data.states?.includes(stateName);
  }

  getId() {
    return this.data.id;
  }

  getName() {
    return this.data.title;
  }

  getRoleName() {
    return this.data.name;
  }

  getUser() : TaskUser {
    return this.role_user;
  }

  getUserName() : string {
    return this.role_user?.html;
  }

  getUserId() {
    return this.role_user?.value;
  }

  getActionDate() {
    return this.data.task?.getDateCompletion();
  }

  getDescription() {
    return this.data.description
  }

  attachTask(task : WorkflowUserTask) {
    this.task = task;
  }
}

/** Workflow model */
export class Workflow
{  
  /** state of workflow*/
  state: string;

  /** state of workflow*/
  actionLabel: string;

  /** object received from server */
  data : WorkflowData;

  /** has the workflow any role, if not, no workflow */
  withWorkflow: boolean;

  tasks: WorkflowUserTask[];
  currentTask: WorkflowUserTask;

  /** user for current task to be executed */
  currentUser : TaskUser;

  /** role for current task's user to be executed */
  currentRole : TTaskRoleFrom;

  /** workflow steps/roles */
  roles : WorkflowRole[];

  /** current role name for the workflow */
  currentRoleName: string;

  /** current role to be executed in the workflow */
  pendingRole : WorkflowRole;

  /** list of actions */
  actions : WorkflowAction[];

  date_creation;

  state_infos;  

  options;


  constructor(data : WorkflowData)
  {
    this._init(data);
  }

  getCurrentState() : string {
    return this.data.state;
  }

  getOptions() : any {
    return this.data.options || {steps: true};
  }

  /** has the workflow any role? */
  hasWorkflow() : boolean
  {
    return this.withWorkflow;
  }

  /** has the workflow any role? */
  hasRoles() : boolean
  {
    return this.withWorkflow;
  }

  /** has the workflow any role? */
  hasActions() : boolean
  {
    return this.actions?.length>0;
  }

  /** get all tasks
   * @param [reverseOrder=true] reverse chronological order ?
   */
  getTasks(reverseOrder=true) : WorkflowUserTask[]
  {
    if(this.tasks)
      if(reverseOrder && this.tasks.reverse)
        return this.tasks.reverse();
      else
        return this.tasks;
  }

  /** current task to be executed */
  getCurrentTask() : WorkflowUserTask
  {
    return this.currentTask || null;
  }

  /** get all roles */
  getRoles() : WorkflowRole[] {
    return this.roles;
  }

  /** get current role */
  getPendingRole() : WorkflowRole {
    return this.pendingRole || null;
  }

  /** init model from data received from server */
  _init(data : WorkflowData) : void
  {
    this.data = data;

    this.withWorkflow = data?.roles?.items?.length>0;

    const currentState = this.getCurrentState();

    // ========  ACTIONS =======
    // get actions
    this.actions = [];
    for (const a in data.actions)
    {
      this.actions.push(new WorkflowAction(data.actions[a],this.currentRoleName));
    }

    if(!this.withWorkflow)
      return;

    // =========  TASKS ========
    // set tasks and get pending task
    this.tasks = (data?.tasks?.items || [])
      .map(tdata =>
      {
        let task = new WorkflowUserTask(tdata,null);

        if(task.isPending())
          this.currentTask = task;

        return task;
      });

    // user for current task
    if(this.currentTask)
    {
      this.currentUser = this.currentTask.getFromUser();
      let currentUserId = this.currentUser.value;
      this.currentRoleName = this.currentTask.getRoleName();
    }
    else
    {
      // no pending task
      this.currentUser = null;
      let currentUserId = null;
      this.currentRoleName = null;
    }

    // =========  ROLES ========

    // set roles and get role for pending task
    let isDone = true; // this.currentTask ? true : false; // if no task, no role has been done..

    this.roles = (data?.roles?.items || [])
      .map((rdata : IWorkflowRoleInfo) =>
      {
        let role = new WorkflowRole(rdata);

        // if role is attached to current state, this role is then pending.
        // before, other roles are "done", and other ones are also pending.
        if(role.hasState(currentState))
        // if(this.currentRoleName && role.getRoleName() == this.currentRoleName)
        {
          this.pendingRole = role;
          this.pendingRole.isPending = true;

          role.setIsCurrent();

          // only first roles before pending one is "active" (in blue in the stepper)
          // ==> following roles are still pending...
          isDone = false;
        }
        else
          role.setIsDone(isDone);

        return role;
      });

  }

  getActions() {
    return this.actions;
  }

  
}
