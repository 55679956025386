
import { FieldDescEnum } from "./field-desc-enum.model";
import { TFieldData } from "./field-record.model";
import { FormField } from "./form-field.model";

/**
 * Represents a form field of enum type.
 */
export class FormFieldEnum extends FormField {
  // @ts-ignore  
  desc: FieldDescEnum;
  typedValue: string;

  /**
   * Constructs a new instance of FormFieldEnum.
   * @param {TFieldData} data - The initial data for the form field.
   * @param {FieldDescEnum} desc - The description of the field.
   */
  constructor(data: TFieldData, desc: FieldDescEnum) {
    if (typeof data != "object") data = { value: data, html: "", disabled: false };

    super(data, desc);

    this.desc = desc;
  }

  /**
   * Builds a new instance of FormFieldEnum.
   * @param {TFieldData} data - The initial data for the form field.
   * @param {Array} enums - The enum values for the field.
   * @param {string} fname - The field name.
   * @param {string} label - Optional. The label for the field.
   * @returns {FormFieldEnum} - The newly created FormFieldEnum instance.
   */
  static build(data: TFieldData, enums: [], fname: string, label: string = "") {
    let desc = new FieldDescEnum({ type: "string", label, enum: enums }, fname);
    return new FormFieldEnum(data, desc);
  }


  /**
   * Get the value of the form field.
   * @param {boolean} native - Optional. Set to true to return the value as a native Date object.
   * @returns {string|null} - The value of the form field. If the value is null, it returns null.
   */
  // @ts-ignore
  value(native: boolean = false) {
    if (this._data === null) return null;
   // return this._data;

    if (this._data.hasOwnProperty && this._data.hasOwnProperty("value") && native) {
      return this.getFieldvalue(this._data);
    } else if (this._data.hasOwnProperty && this._data.hasOwnProperty("html") && !native) {
      return this.getFieldHtml(this._data);
    } else if (this._data.hasOwnProperty && this._data.hasOwnProperty("label")) {
      return this.getFieldLabel(this._data);
    } 

    return this._data;
  }

   /**
   * Get the value of the form field dans le cas de select.
   */
  getValueEnum(){
    if (this._data === null) return null;
    return this.getFieldvalue(this._data);
  }

  /**
   * Get the enum value associated with the form field value.
   * @returns {*} - The enum value associated with the form field value.
   */
  getEnum() {
    const v = this.value();
    return this.desc.getEnum(v);
  }

  /**
   * Initializes the form field with optional fields, parameters, and a callback function.
   * @param {Object|null} fields - Optional. The fields object.
   * @param {Object|null} params - Optional. The parameters object.
   * @param {Function|null} cb - Optional. The callback function.
   * @returns {*} - The initialized form field data.
   */
  // @ts-ignore
  async init(fields: {} = null, params: {} = null, cb) {
    if (this.desc.mustPreload()) {
      const url = this._buildUrl(fields, params);

      if (cb) {
        const data = await cb(url);
        this.desc.updateEnumList(data);

        if (data.length == 1) {
          // this.update(data[0]);
        }
      }
    }

    return this._data;
  }


  /**
   * Builds the URL for the form field based on the description, fields, and parameters.
   * @param {Object} fields - The fields object.
   * @param {Object} params - The parameters object.
   * @returns {string} - The URL for the form field.
   */
  _buildUrl(fields: any, params: any) {
    let url = this.desc.getUrl();
    let query = this.desc.queryParams();
    if (query) {
      for (let p in query) {
        const f2 = query[p];
        const v = fields[f2] ? fields[f2].value() : params[f2] || "";
        url += `&${p}=${v}`;
      }
    }

    return url;
  }

  /**
   * Converts the FormFieldEnum instance to its string representation.
   * @returns {string} - The string representation of the FormFieldEnum instance.
   */
  // @ts-ignore
  toString() {
    if (this._data == null) {
      return "";
    }

    if (typeof this._data != "object") {
      return this._data;
    }

    let v = this.value();
    let s = this.desc.getEnumLabel(v);

    return (
      s ||
      this._data[this.desc.fieldTitle] ||
      this._data.html ||
      v ||
      "-"
    );
  }



  /**
    * Updates the data of the FormFieldEnum instance based on the provided data.
    * @param {any} data - The data to update the FormFieldEnum with.
    * @returns {object} - The updated data object.
    */
  // @ts-ignore
  /*update(data)
  {
    if (this._data == null)
    {
      this._data = { value: "", html: "" };
    }

    if (data === null && this._data)
    {
      this._data.value = "";
      this._data.html = "";
    }

    if (data && typeof data.value != "undefined")
    {
      this._data.value = data.value || "";

      if (data.html)
      {
        this._data.html = data.html || data.value || "";
      }
      else if (this._data.value)
      {
        const html = this.desc.getEnumLabel(this._data.value);
        this._data.html = html;
      }
    }
    else if (data && typeof data.value == "undefined")
    {
      this._data.value = data || "";
      const enu = this.desc.getEnum(this._data.value);
      this._data.html = enu.html;
    }

    return this._data;
  }*/
  // @ts-ignore
  getFieldvalue(v: TFieldData): string | number | null {
    //console.log(typeof v === 'object' && v !== null && 'value' in v)
    if (typeof v === 'object' && v !== null && 'value' in v) {
      return v.value?.toString();
    }
    return null;
  }
  // @ts-ignore
  getFieldHtml(v: TFieldData): string | undefined {
    if (typeof v === 'object' && v !== null && 'html' in v) {
      return v.html;
    }
    return 'undefined';
  }

  getFieldLabel(v: TFieldData): string | undefined {
    if (typeof v === 'object' && v !== null && 'label' in v) {
      return v.label;
    }
    return 'undefined';
  }

  setData(data){
    this._data = data;
    return this._data;
  }

  /**
   * Returns the data of the field.
   * @returns {TFieldData} - The field data.
   */
  data() {
    return this._data;
  }

  formatStringToArray(inputString): number[] {
    const stringParts = inputString.split(',');
    const numbersArray = stringParts.map(part => parseInt(part, 10));
    return numbersArray;
  }

  updateValue(numbers) {
    console.log(this.value())
    let data = this.formatStringToArray(this.value());
    if (!Array.isArray(data)) {
     return ;
    }
    this.typedValues = data.map(number => {
      const correspondence = numbers.find(entry => entry.value === number);
      return correspondence ? correspondence.html : '';
    });

    
  }

  


}