import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
   selector: 'app-list-of-accesses',
   templateUrl: 'bootstrap/list-of-accesses.component.html',
   styleUrls: ['bootstrap/list-of-accesses.component.scss'],
})
export class ListOfAccessesComponent implements OnInit {
   @Input() isOpen: boolean = false;
   @Output() close = new EventEmitter<any>();

   title: string = 'Liste des Accès';

   constructor() {}
   closeModal() {
      this.close.emit();
   }

   ngOnInit(): void {}
}
