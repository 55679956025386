import { ContactsComponent } from '../containers';
import { PeopleDialogComponent } from './people-dialog/people-dialog.component';


export const components = [
    PeopleDialogComponent
];

export const entryComponents = [
    PeopleDialogComponent
];


export * from './people-dialog/people-dialog.component';
