import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/design-system/core/interfaces/user';

@Component({
   selector: 'app-new-space',
   templateUrl: 'bootstrap/new-space.component.html',
   styleUrls: ['bootstrap/new-space.component.scss'],
})
export class NewSpaceComponent implements OnInit {
   @Input() isOpen: boolean = false;
   @Output() close = new EventEmitter<any>();

   steps: {
      stepOrder: number;
      title: string;
   }[] = [
         {
            stepOrder: 1,
            title: 'Create New Space',
         },
         {
            stepOrder: 2,
            title: 'Share Space',
         },
      ];
   currentStep: number = 1;
   title: string = this.steps[this.currentStep - 1].title;

   // User lists

   selectedUsers: User[] = [];
   usersList: User[] = [
      {
         id: 1,
         fname: 'Garland',
         lname: 'Borde',
         image: '/assets/images/user-1.png',
      },
      {
         id: 2,
         fname: 'Dione ',
         lname: 'Samuel',
         image: '/assets/images/user-2.png',
      },
      {
         id: 3,
         fname: 'Jourdain ',
         lname: 'Dubois',
         image: '/assets/images/user-3.png',
      },
      {
         id: 4,
         fname: 'Bertrand',
         lname: 'Bret',
         image: '/assets/images/user-2.png',
      },
      {
         id: 5,
         fname: 'Amaline ',
         lname: 'Patenaude',
         image: '/assets/images/user-1.png',
      },
      {
         id: 6,
         fname: 'Patenaude',
         lname: 'Borde',
         image: '/assets/images/user-3.png',
      },
   ];

   constructor() { }

   closeModal() {
      this.close.emit();
      this.currentStep = 1;
   }
   nextStep() {
      this.currentStep++;
      this.title = this.steps[this.currentStep - 1].title;
   }
   prevStep() {
      this.currentStep--;
      this.title = this.steps[this.currentStep - 1].title;
   }
   onChange() {
   }
   ngOnInit(): void { }
}
