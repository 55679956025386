import { GcsItem } from "./gcs-item";

export class GcsGmail extends GcsItem{

    constructor(gcsItem){
        super(gcsItem);
    }

    setIconLink(gcsItem) {
        this.iconLink = '/assets/google_icons/gmail_icon.svg';
    }
}