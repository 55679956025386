<div class="left-section">
  <div *ngFor="let task of tasks">
    <div *ngIf="task.task_state !== 'pending'">
    <div class="user-profile">
      <div class="profile-image">
        <app-doc-avator-creator [name]="task.from_user?.html"></app-doc-avator-creator>

      <!-- <img [src]="'h'" alt="User Image">-->
      </div>
      <div class="user-details">
        <div class="user-name">{{ task?.from_user?.html }}</div>
        <div class="user-role">{{ (task?.state_infos?.title ?? task.actionLabel)+' ~ '+ transformTimestamp(task?.date_creation?.time) }}</div>
      </div>
    </div>
    <hr class="divider">
    </div>
  </div>
</div>