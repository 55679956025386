import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { from } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GDocService, GedService } from 'src/modules/app-common/services';
const themePath = environment.themePath;


@Component({
  selector: 'app-doc-dialogs',
  templateUrl: `./${themePath}/doc-dialogs.component.html`,
  styleUrls: [`./${themePath}/doc-dialogs.component.scss`]
})
export class DocDialogsComponent implements OnInit {
  isLoading = false

  constructor(private gedService: GedService, private router: Router,private route: ActivatedRoute,
    //  public dialogRef: MatDialogRef<DocDialogsComponent>,
    //  @Inject(MAT_DIALOG_DATA) public data,
    private gdocService: GDocService) { }

  ngOnInit(): void {

    if (this.data.function == 'delete')
      this.btnTxt = environment.stringsFile.deleteConfirmBtn //'Yes, delete it permanently'
    else
      if (this.data.function == 'detach')
        this.btnTxt = environment.stringsFile.detachConfirmBtn //'Yes, detach this document from PresenceDocs'
  }

  public dialogRef: MatDialogRef<DocDialogsComponent>

  //@Inject(MAT_DIALOG_DATA) public data
  title: string = environment.stringsFile.docInfos;
  @Input('data') data;
  @Input() isOpen: boolean = false;
  @Output() close = new EventEmitter<any>();

  closeModal() {
    this.close.emit();
  }

  onCancel(): void {
    this.close.emit();
    // this.dialogRef.close();
  }


  btnTxt = ''
  onConfirm() {
    this.isLoading = true

    if (this.data.function == 'delete')
      this.deleteDocument()
    else
      if (this.data.function == 'detach')
        this.detachDocument()
  }





  async deleteDocument() {
    let id = localStorage.docId;

    this.doc = await this.gdocService.getFile(id) || null;;
    let parentId = this.doc.parents[0] || null;
    let driveId = await this.gdocService.getMyDriveId();
    let spaceDoc = await this.gedService.getSpaceDoc(id);


    let comp = this.data.parentComponent;

    if (comp.inGed) {
      from(this.gedService.deleteDoc(this.doc))
        .pipe(
          delay(3000),
          tap(() => {
            this.isLoading = false;
            this.router.navigate(['../../'], { relativeTo: this.route });
          })
        )
        .subscribe()
     // this.gedService.deleteDoc(this.doc)
       // .then(res => {
          //   this.dialogRef.close();
         // this.isLoading = false;
          //this.router.navigate(['../../'], { relativeTo: this.route });
          /*if (spaceDoc) {
            console.log(spaceDoc["uid"]);
            let url = "/explorer/drive/" + spaceDoc["uid"];
            this.router.navigate([url]);
          }
          else {
           
            // this.dialogRef.close();
           // this.router.navigate(['/explorer/drive/' + parentId], { queryParams: { d: localStorage.host_domain } })
          //}


          // todo : get space UID from parent component and redirect to space
          // or get current URL and reditrect to it
          // ==> should exexute that from parent comp
          //this.router.navigate(['/workspace/main'],{queryParams:{ d: localStorage.host_domain}});

          /*
          if(parentId == driveId)
          {
            this.router.navigate(['/s/space/root']);
          }
          else
          {
            // this.dialogRef.close();
            this.router.navigate(['/s/space/'+parentId],{queryParams:{ d: localStorage.host_domain}})
          }
          */
      //  })
    }

    else {
      let response = await this.gdocService.deleteFile(this.doc);

      if (response != null) {
        this.isLoading = false;
        this.router.navigate(['../../'], { relativeTo: this.route });
        //this.router.navigate(['/s/space/' + parentId], { queryParams: { d: localStorage.host_domain } })
      }
    }


  }

  detachDocument() {
    let id = localStorage.docId

    this.gdocService.getFile(id)
      .then(res => {
        this.doc = res;
        this.gedService.detachFromGED(this.doc)
          .then(res => {
            if (res) {
              let url = this.router.url;
              // this.router.navigateByUrl(url)
              //  this.dialogRef.close();
              this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([url]);
                });

            }

          })

      })

  }

  doc;
}
