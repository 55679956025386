export type TFname = string;

export type TEnumData = {
    value: string | number | null;
    html: string;
    label?: string;
    [key: string]: string | number | null | undefined | boolean;//crer type à cette variable
    disabled: boolean ;
}
export type TFieldData = number | TEnumData | string | null;

export type RecordMetadata = {
    fields: Record<string , Record<TFname, TFieldData>>;
  };

export type RecordData = Record<TFname , TEnumData>;
