import { query } from "@angular/animations";
import { GedService } from "../../services";

type TEnumOption = {value:string,html:string};

export class DocType
{
  key;
  value;
  type;
  nullable;
  label;
  auto;
  fieldAutoList = [];
  autoValue: {};
  previewLink: string;
  control
  isEnum
  enumValues : Record<string,string>;
  // options : TEnumOption[];
  isMultiple
  required
 // ;
  urlAuto;
  case:string;
   

  public serviceGed: GedService

  constructor(
    key,
    data,
    metadata,
    gedService : GedService,
    setControl=false)
  {
   this.setServiceGed(gedService);
     this.setKey(key);
    this.setValue(data);
    this.setNullable(metadata);
    this.setLabel(metadata);
    this.setAuto(metadata);

    this.setUrlAto(this.getAuto());
    this.setType(metadata);
    this.setAutoValue();
    this.setIsMultiple(metadata);
    this.setRequired(metadata)
    this.setEnumValues(metadata);
    this.setIsEnum(metadata);

    this.setControl(metadata,setControl);
  }

  getServiceGed() {
    return this.serviceGed;
  }

  setServiceGed(gedService) {
    this.serviceGed = gedService
  }

  getKey() {
    return this.key;
  }
  getValue() {
    return this.value;
  }

  getType() {
    return this.type;
  }
  getRequired() {
    return this.required;
  }

  getNullable() {
    return this.nullable;
  }

  getLabel() {
    return this.label;
  }

  getAuto() {
     return this.auto;
  }

  isAuto() {
    return this.urlAuto || false;
  }

  getFieldAutoList() {
    return this.fieldAutoList;
  }
  getAutoValue() {
    return this.autoValue;
  }

  getControl() {
    return this.control;
  }

  getIsMultiple() {
    return this.isMultiple;
  }

  getEnumValues() {
    return this.enumValues;
  }

  getIsEnum() {
    return this.isEnum;
  }

  setKey(key) {
    this.key = key;
  }

  setValue(data)
  {
      this.value = data;
  }

  setType(metadata)
  {
    if (metadata?.["type"] == "integer")
      this.type = "number";

    if (metadata?.["type"] == "string" || !!this.getAuto())
      this.type = "string";

    if (metadata?.["type"] == "date")
      this.type = "date";
  }

  setNullable(metadata) {
    this.nullable = metadata?.nullable || false;
  }

  setRequired(metadata) {
    this.required = metadata?.required || false;
  }

  setLabel(metadata) {
    this.label = metadata?.label;
  }

  setAuto(metadata) {
    this.auto = metadata["x-dynamic-values"] || null;
  }

  setAutoValue() {
    this.autoValue = {};
  }

  setUrlAto(getAuto){
    this.urlAuto = getAuto?.['url'];
  }

  getUrlAuto(){
      return this.urlAuto
  }

  getPreviewLink(){
    return this.previewLink;
  }

  setPreviewLink(metadata)
  {
    if(this.getKey()=='template')
    {
      let id = metadata?.infos?.id || null;
      if(id!=null)
      {
        this.previewLink=`https://drive.google.com/thumbnail?sz=w360&id=${id}`;
      }
    }
  }

  setFieldAutoList(metadata , oid)
  {
    if(this.getUrlAuto() !=null && this.getControl()== "select")
    {
      let key = this.label;
      GedService.instance.getAutocompleteListSelect(this.getUrlAuto())
      .then(autolist=>
        {
          let tempList =[];

          tempList = autolist || [];
          for(let item of tempList)
          {
            this.fieldAutoList.push(item)
          }
        })
    }

    if(this.getAuto() !=null)
    {
      let key = this.getKey();

      if(key =='space' || key=='template')
      {
        this.serviceGed.getAutocompleteList(this.getValue(),  this.getUrlAuto() ,oid)
        .then(autolist=>
          {
            let tempList =[];

            if(autolist.length!=0)
            {
              this.setValue(autolist[0]);
              this.setPreviewLink(autolist[0])
            }

            tempList = autolist || [];
            for(let item of tempList)
            {
              this.fieldAutoList.push(item)
            }
        })
      }
    }
    else
    {
      this.serviceGed.getAutocompleteList(this.getValue(),this.getAuto() )
      .then(autolist=>
      {
        let tempList =[]
        tempList = autolist || [];

        for(let item of tempList){
          this.fieldAutoList.push(item)
        }
      })
    }
 // this.fieldAutoList = [];
  }

  setControl(metadata,setControl=false) {
    let control;

    if(setControl)
    {
      if(this.key == 'space')
        this.control = "select";
      else if(this.key == 'template')
        this.control = "select";
      else if(metadata.control)
      {
        this.control = metadata.control;
      }
      else if(this.type == "date")
          this.control = "date";
      else if(this.auto)
        this.control = "autocomplete";
      else if(this.isEnum && !this.isMultiple)
        this.control = "select";
      else if(this.isEnum && this.isMultiple)
        this.control = "checkbox";
      else
        this.control = "text";
    }
    else
      this.control = metadata.control;
  }

  setIsMultiple(metadata) {
    this.isMultiple =  (metadata.multiple)? true : false;
  }

  setIsEnum(metadata) {
    this.isEnum = (metadata?.enum) ? true : false;
  }

  setEnumValues(metadata) {
    this.enumValues = (metadata?.enum) ? metadata?.enum : null;
    // this.options = Object(this.enumValues).keys().map(value => { return {value,html:this.enumValues[value]}});
  }
}
