import { DocumentService } from "src/modules/documents/services";

export class DrivesData {
  private _iconLink: string;
  id: string;
  name: string;
  mimeType: string;
   properties: {
    type_document: string;
  };
  isFolder: boolean;
  isDrive : boolean ; 

   
  get iconLink(): string {
    if (this.properties?.type_document === "My Drive") {
      this._iconLink = "/assets/icons/my_drive_blue.svg";
    } else {
      this._iconLink =
        this._iconLink ||
        DocumentService.instance.getIconbyMimeType(this.mimeType);
    }
    return this._iconLink;
  }

  set iconLink(value: string) {
    this._iconLink = value;
  }

  getMimeType() {
    return this.mimeType;
  }

  setMimeType(mimeType: string) {

   // console.log("this.dataDrive :",  mimeType);

    this.mimeType = mimeType;
    if(this.id === "root"){
      this.mimeType = "application/vnd.google-apps.drive";
    }

    this.isDrive =  this.mimeType?.includes('vnd.google-apps.drive')  ? true : false;
    this.isFolder =  this.mimeType?.includes('google-apps.folder')  ? true : false;
  }

  hasTypeDocument(): boolean {
    return !this.properties?.type_document;
  }
}
