import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DocumentService } from 'src/modules/documents/services/document.service';
import { FolderDialogData } from '../../models/folder-dialog-data/folder-dialog-data.model';
import { GDocService } from '../../services';

const themePath = environment.themePath;

@Component({
  selector: 'app-add-folder',
  templateUrl: `./${themePath}/add-folder.component.html`,
  styleUrls: [`./${themePath}/add-folder.component.scss`]
})
export class AddFolderComponent implements OnInit {
  
  constructor(
    private gdocService: GDocService, private router: Router,
    private route: ActivatedRoute, private documentService: DocumentService) {}
    @Input() isOpen: boolean = false;
    @Output() close = new EventEmitter<any>();
    @ViewChild('f') public docFormulaire:NgForm;
    @Input("parentId")  parentId

    isLoading : boolean = false;
    titleDocument : string = environment.stringsFile.newFolder;


    ngOnInit(): void {
    }

 data: FolderDialogData ;
 folderName = new FormControl('', [Validators.required]);
     
    onCancel(): void {
    //this.dialogRef.close();
  }

  closeModal() 
  {
    this.close.emit();
 
    this.docFormulaire.form.reset();
   
       
  }


  onCreateFolder(folderName){
    //1. create folder
    this.isLoading == true;
        this.gdocService.createFolder(folderName.value,this.parentId)
      .then(data=>{
      
        let mimeType = 'application/vnd.google-apps.folder'
        let url = this.documentService.getRoutebyMimeType(mimeType)
        url+=data.id;
        this.isLoading == false;
     // this.closeModal
        //this is to reload page for the wanted linked document 
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>  
          this.router.navigateByUrl(  url)
        );       
      })  
  }

}
