import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Document } from 'src/modules/documents/models';
import { DocumentService } from 'src/modules/documents/services/document.service';
import { GDocService } from '../g-doc/g-doc.service';
import { APISce } from '../http/api.service';

import { GedPermission, SpaceInfos, SpaceId, GedPermRole, GedPermType, GedPermissionPostInfos } from "./ged.types";
export * from "./ged.types";

@Injectable({
  providedIn: "root",
})
export class GedService {
  //VARS
  doc;
  _currentDoc: Document;
  file: any;
  jwt = null;
  meta: Record<string, any>;
  user: gapi.auth2.GoogleUser;
  temp;
  inGed;
  spaces = [];
  revisions = [];
  links = [];
  dialog;
  metaFields;
  formFields = [];
  static instance: GedService;

  constructor(
    private api: APISce,
    private gdocService: GDocService,
    private docService: DocumentService,
    private snackBar: MatSnackBar,
  )
  {
    this._currentDoc = null;
    GedService.instance = this;

    // async
    this.loadDocTypes();
  }


  /* ####################################### GED FILES ################################### */

  setCurrentDoc(doc: Document, data?) {
    //sets currentDoc
    this._currentDoc = doc;
    //this.currentDoc.setData(data);
  }

  currentDoc(): Document {
    return this._currentDoc;
  }

  getContent(resp) {
    return resp.data.content;
  }

  //get doc by uid

  async getDoc(uid: string, path?: string)
  {
    try
    {
      let url = `/ged/documents/uid/${uid}?$withMeta=true&path=${path}`;
      let document = await this.api.get(url);
      console.log("document", document);
      this.doc = document;

      if (this.doc  != "d") {
        localStorage.setItem("gedfile", JSON.stringify(this.doc ));
      }
      else
      {
        return this.doc ;
      }

      return this.doc ;
    } catch (error) {
    //  console.error(error);

      if (error.status === 404) {
        // console.error("Document not found");
        return null;
      } else {
        throw error;
      }
    }
  }
  /**
     * send flag   for a document, by current user
     * @param uid document uid
     * @param flag 
     * @returns  { code : "OK"} 
     */
  async flagDocument(uid: string, flag: string = '') {
    // set payload
    let data =
    {
      flag
    }

    let url = `/ged/documents/uid/` + uid + '/flag';

    try {
      let response: { content } = await this.api.post(url, data);
      console.log('response type : ', response)
      // code: "OK"
      return response.content;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  /**
   * get document rating
   * @param uid
   * @returns
   */
  async getRatingDoc(uid: string): Promise<{ rating: number, nb_rates: number }>
  {
    let url = `/ged/rating/${uid}`
    const result: { content: { rating: number, nb_rates: number } } = await this.api.get(url);
    return result?.content;
  }

  /**
   * send rating for a document, by current user
   * @param uid document uid
   * @param rating
   * @param comment
   * @returns
   */
  async rateDocument(uid: string, rating : number, comment = '')
  {
    // set payload
    let data =
    {
      rating,
      comment
    }

    let url = `/ged/rating/` + uid;

    try {
      let response: { content } = await this.api.post(url, data);

      return response.content;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }


  /**
   * get Space related to a document
   * @param uid document uid
   * @returns
   */
  async getSpaceDoc(uid: string): Promise<any> {
    let url = `/ged/documents/uid/${uid}/space`;
    let document: { content: { data } } = await this.api.get(url);

    return document?.content?.data;
  }

  //get doc by uid

  /**
   *
   * @param uid
   * @param path
   * @returns
   */
  async getDocParent(uid: string, path?: string): Promise<{ data, metadata }>
  {
    try {
      const document: { content: { data, metadata } }
        = await this.api.get("/ged/documents/uid/" + uid + "?$withMeta=true&path=" + path);

      this.doc = document;
      if (this.doc != "d") {
        localStorage.setItem("gedfile", JSON.stringify(this.doc));
      }
      else {
        this.doc = null;
      }

      return this.doc?.content?.data;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  //not yet
  /*
  async getFile(fileInfos)
  {
    const path = fileInfos.name + ".gdoc";
    const uid = fileInfos.id;

    try
    {
      let resp = await this.api.get("/ged/documents/uid/" +uid +"?$withMeta=true&path=/" +path);

      let doc = this.setCurrentDoc(new Document(fileInfos, resp));

      return doc;
    }
    catch (error) {
      console.log(error);
      return this.setCurrentDoc(new Document(fileInfos));
    }

    //get a file in GED using its fileInfos
    //Select file where file.fileInfos == fileInfos
    return this.file;
  }
  */

  /**
   * extract file name from path
   * @param path
   * @returns
   */
  getFileName(path: string): string {
    let pathSplit = [path];
    pathSplit = path?.split("/");
    let i = 0;
    while (i < pathSplit?.length) {
      i++;
    }
    let name = null;
    if (pathSplit != undefined) {
      name = pathSplit[i - 1];
    }
    return name;
  }

  //not yet
  /*
  async updateFile()
  {
    const fileInfos = this.currentDoc().fileInfos;
    const path = fileInfos.name + ".gdoc";
    const uid = fileInfos.id;

    try {
      let config = await this.httpConfig(true);

      let resp = await this.http.get(BACKEND_URL + "/ged/documents/uid/" + uid + "?$withMeta=true&path=/" + path, config);

      let doc = this.setCurrentDoc(new Document(fileInfos, resp));
      return doc;
    }
    catch (error) {
      console.log(error);
      return this.setCurrentDoc(new Document(fileInfos));
    }
    //return this.file;
  }
  */

  /* ####################################### METADATA ################################### */

  async updateMetadata(doc, data, set = "meta"): Promise<any> {
    const params =
    {
      oid: doc.content.data.oid,
      role: doc.role,
      state: doc.content.state || "undefined",
    };

    let url = "/ged/documents/" + params.oid + "/role/" + params.role + "/state/" + params.state + "/meta";

    // update GED
    try {
      const resp: {} = await this.api.put(url + "?$withMeta=true", data);

      this.updateData(doc, data);

      /*-------------------------------------------- HERE UPDATE META IN G-DOC --------------------------------------------------*/
      // update GDOC
      //let props = doc(set,true,'name','toString');
      //this.gdocService.setMeta(props,doc.infos);
      // this.setMeta(fileGed,set);
      return resp;
    }
    catch (error) {
      console.error(error);
      return error;
    }
  }

  async updateSpaceMeta(
      driveInfo,
      formData: Record<string,any>,
      type)
  {
    let lastType = {
      html: "",
      value: formData.main_type,
    };

    // set ids from google drive
    let data =
    {
      oid: driveInfo.oid,
      uid: driveInfo.uid,
      main_type: type || lastType
    }

    // copy form data fields
    for (let fname in formData) {
      data[fname] = formData[fname] || "";
    }

    let contents = { data };

    let url = "/ged/spaces/" + driveInfo.oid + "?$withMeta=true";
    try {
      let response = await this.api.put(url, contents);
      return response;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  updateData(data, doc) {
    for (var key in Object.keys(doc)) {
      this.doc[key] = data[key];
    }
  }

  //new add : testing
  async addDocToGed(currentDoc, data)
  {
    //const fileInfos = doc.infos;

    //let ext = fileInfos.gExt || '';

    // !!! this line has not been tested yet !
    //if facing problems, comment the previous line and use the following code:
    /*

    let ext = '.gdoc';
    if(doc.mimeType.includes('document')) ext='.gdoc'
    if(doc.mimeType.includes('sheet')) ext='.gsheet'
    if(doc.mimeType.includes('presentation')) ext='.gpres'
    */

    const doc = currentDoc;
    let ext = this.docService.getDocReader(doc.mimeType, true);
    const path = doc.name + ext;

    const id = doc.id;

    // set payload
    let data2 =
    {
      name: path,
      uid: id,
      rev_uid: "",
      type_document: data?.curTypeDoc?.oid,
      iconLink: doc.iconLink,
      xdata: data,
    };

    let url = `/ged/documents?$withMeta`;
    try {
      let response = await this.api.post(url, data2);

      return response;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async addFolderToGed(typeFolder, data)
  {
    const path = data.name + '.folder';

    // set payload
    let data2 =
    {
      name: path,
      uid: data.driveId,
      type_folder: typeFolder,
      xdata: data
    }

    let url = `/ged/spaces?$withMeta=true`;

    try {
      let response = await this.api.post(url, data2);
      let data3 = response;
      return data3;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async createNewDocInGED(typeDocOid: string, data, templateId: string, folderId: string) {
    // set payload
    let data2 = {
      type_document: typeDocOid,
      folderId: folderId,
      xdata: data
    };

    let url = `/ged/documents/uid/${templateId}/copy?$withMeta`;

    try
    {
      let response: { content } = await this.api.post(url, data2);

      this.snackBar.open("Document created successfully", "", {
        duration: 500,
      });

      return response?.content;
    }
    catch (error)
    {
      console.log(error);
      return error;
    }
  }

  async getMeta(path: string, uid: string): Promise<Record<string, any>> {
    let url = `/ged/documents/uid/${uid}?$withMeta=true&path=${path}`;
    try {
      let document: { content: { metadata: { fields } } } = await this.api.get(url);
      if (!!document?.content) {
        this.meta = document?.content?.metadata.fields;
        return this.meta;
      }
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async setMeta(fileGed, metadata) {
    // get meta fields from GED
    let props = fileGed.getMetaProps("meta,base_meta,ged");
    // publish them to Google Doc as meta
    await this.gdocService.setMeta(props, fileGed.infos);
    // set meta data of a fileGed
    fileGed.metadata = metadata;
  }

  /**
   * Get form fields data/meta for a given document type
   *
   * @param typeOid document type OID
   * @param withTemplates for new files, select an existing model
   * @param withSpaces add spaces if creating globally (user need to select a space)
   * @param currentId provide current id so that the backend can prepopulate the form
   * @param forNewFile creating new file?, else if for existing file, we also get a "copy" field
   * @param typeOid document
   */
  async getMetaByType(
    typeOid: string,
    withTemplates = false,
    withSpaces = true,
    currentId = null,
    forNewFile = true,
    prefill = false

  ) {
    let url;
    
    if(typeOid){
      url = `/ged/documents/create_by_type/${typeOid}`;
    }else{
     url = `/ged/documents/create_default_type`;
      withSpaces = false;
      //prefill = false;

    }
    
    if (currentId)
      url += "?uid=" + currentId;

    const params =
    {
      $withMeta: true,
      withTemplates: withTemplates,
      withSpaces: withSpaces,
      prefill: prefill,
      forNewFile // if for existing file, we also get a "copy" field
    };

    try {
      let data: { content } = await this.api.get(url, params);

      return data?.content;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async getMetaDocByType(
    typeOid: string,
    parentUid: string,
    withTemplates = true,
    withSpaces = true,
    forNewFile = true
  ) {
    let url = `/ged/documents/create_by_type/${typeOid}`;
    const params =
    {
      $withMeta: true,
      parentUid: parentUid,
      withSpaces: withSpaces,
      withTemplates: withTemplates,
      forNewFile
    };

    try {
      let data: { content } = await this.api.get(url, params);
      return data.content;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  /* ####################################### OBJECT META ################################### */

  async getMetaByHtml(html, key) {
    let url = `/ged/${key}`;

    try {
      let objectList = await this.api.get(url);

      for (let key of Object.keys(objectList)) {
        if (objectList[key].html == html) {
          return objectList[key];
        }
      }
      return null;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  getObjectList(keyName, url) {
    return this.getAutocompleteList("", url).then((res) => {
      return res;
    });
  }

  /* ####################################### ROLES ################################### */

  //not yet
  //to be removed: current version in workflowService
  async getRoles(fileGed) {
    //: getRoles of users on a specific file in the GED

    const params =
    {
      oid: fileGed.data.oid,
    };

    let url = `/ged/documents/${params.oid}/workflow`;
    try {
      const roles = await this.api.get(url);
      return roles;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  /* ####################################### ACTIONS ################################### */

  //to be removed: current version in workflowService
  async getActionDialog(doc, actionName: string) {
    //const action = doc.action(actionId);
    this.formFields = [];

    const params =
    {
      oid: doc.content.data.oid,
      role: doc.content.role,
      state: doc.content.state,
      action: actionName,
    };

    let url = `/ged/documents/${params.oid}/role/${params.role}/state/${params.state}/actions/${params.action}/dialog`;

    try {
      let data = await this.api.get(url);

      this.dialog = data["data"];
      this.metaFields = this.dialog.metadata.fields;

      let i = 0;
      for (let key of Object.keys(this.metaFields)) {
        if (this.metaFields[key]['edit'] == false || this.metaFields[key]['show'] == false) {
        }
        else {
          let f = this.metaFields[key];

          let st = JSON.stringify(f);
          let leng = st.length;
          let t = st.slice(0, leng - 1);
          let temp = t + ',"value":""}';
          temp = JSON.parse(temp);

          if (temp["type"] == "file") {
          }
          if (temp["type"] == "text") {
          }
          if (temp["type"] == "text_choice") {
          }

          this.formFields.push(temp);
          i++;
        }
      }

      return this.formFields;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  //to be removed: current version in workflowService
  async getActions(path: string, uid: string) {
    let url = `/ged/documents/uid/${uid}?$withMeta=true&path=${path}`;

    try {
      let document = await this.api.get(url);

      let actions = document?.["content"].actions;
      return actions;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  //to be removed: current version in workflowService
  async execAction(action, doc) {
    //: execute action on the fileGed
    // --> changed status of the file
    // workflow step completed
    // different actions displayed to users

    const params =
    {
      oid: doc.content.data.oid,
      role: doc.role,
      state: doc.content.data.state,
      action: action.name,
    };

    let url = `/ged/documents/${params.oid}/role/${params.role}/state/${params.state}/actions/${params.action}?$withMeta=true`;

    try {
      let response = await this.api.post(url, null);

      return response;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  /* ####################################### AUTOCOMPLETE ################################### */

  async getObjectByValue(value, url) {
    try {
      let response = await this.getAutocompleteList(value, url);
      return response;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async getAutocompleteList(searchText, url, typeDoc?, otherFieldsKey?) {
    //receives searchText
    if (url == null)
      return;

    let keywords = searchText?.html
      ? searchText?.html.toLowerCase()
      : searchText;
      if(!keywords){
        keywords = "";
       // return ;
      }

    //adds the searchtext to the url in the backend to search with keywords = searchtext
    let url2 = url;

    if (typeDoc != null) {
      // to be rewritten here to support several field values
      let strType = Object.entries(otherFieldsKey).find(([key]) => {
        return key;
      });

      if (url2.includes("?")) {
        url2 += "&" + strType[0] + "=" + typeDoc;
      }
      else {
        url2 += "?" + strType[0] + "=" + typeDoc;
      }
    }
    else {
      if (!url2.includes("?")) {
        url2 += "?$view=autocomplete&text=" + keywords;
      }
      else {
        url2 += "&text=" + keywords;
      }
    }

    try {
      let response: { content: { data } } = await this.api.get(url2);

      return response.content?.data;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async getAutocompleteListSelect(url) {
    //receives searchText
    if (url == null)
      return;

    //adds the searchtext to the url in the backend to search with keywords = searchtext
    let url2 = url;

    return this.api
      .get(url2)
      .then((response: any) => {
        return response.content?.data;
      })
      .catch((error) => {
        //this.currentDoc=null;
        console.log(error);
        return error;
      });
  }

  async getEnumsValues(url) {
    let url2 = url;

    return this.api
      .get(url2)
      .then((response: any) => {
        // Map the response object to the data object.
        return response.content?.data;
      })
      .catch((error) => {
        //this.currentDoc=null;
        console.log(error);
        return error;
      });
  }

  // =================== DOC TYPES =============

  // cache of document types
  _docTypesBy = {};

  async getDocTypes(
    text  : string,  // autocomplete query string
    soid? : number, // space oid for selecting only relevant types for the space
    suid? : string  // or space uid
  )
  {
    let url = "/ged/doc-types";

    // space oid or suid
    if(soid || suid)
    {
      url+= "?$view=autocomplete_by_space";

      if(soid)
        url+= "&soid="+soid;

      if(suid)
        url+= "&suid="+suid;
    }

    if(false && this._docTypesBy[url])
      return this._docTypesBy[url];

    try
    {
      let docTypes = await this.getAutocompleteList(text,url);
      let filteredTypes = [];

      for (let docType of docTypes)
      {
        if (!text || docType.title.toLowerCase().includes(text.toLowerCase()))
        {
          filteredTypes.push(docType);
        }
      }

      // use cache
      this._docTypesBy[url] = filteredTypes;

      if(this._docTypesBy[url])
      {
        // deep copy of cache to avoid breaking the cache...
        return this._docTypesBy[url]
          .map(t =>
            {
              return {...t};
            }
          );
      }
    }
    catch (error)
    {
      console.log(error);
      throw error;
    }
  }

  docTypes = [];
  docTypesByOid: {};
  docTypesByMetaModel: {};

  // get list of types (add type)
  async loadDocTypes() : Promise<any[]>
  {
    if (this.docTypesByOid)
      return this.docTypes;

    this.docTypesByOid = {};
    this.docTypesByMetaModel = {};

    this.docTypes = await this.getDocTypes("") || [];

    this.docTypes.forEach(docType => {
      this.docTypesByOid[docType["oid"]] = docType;
      const metaModelProp = (docType["metadata_model"]||"").replace("_"," ");
      this.docTypesByMetaModel[metaModelProp] = docType;
    });

    return this.docTypes;
  }

  typeByMetaModel: Record<string,any>;
  async getTypeByModelName(model_id : string)
  {
    if(!this.docTypesByMetaModel)
    {
      this.docTypesByMetaModel = await this.loadDocTypes();
    }

    return this.docTypesByMetaModel && this.docTypesByMetaModel[model_id];
  }

  getDocTypesByOid() {
    return this.docTypesByOid || null;
  }

  getDocTypesByMetaModel() {
    return this.docTypesByMetaModel || null;
  }

  getDocTypeByOid(oid) {
    return this.docTypesByOid[oid] || null;
  }

  // OBSOLETE ?
  urlPopup(file, preview = false, withJWT = false) {
    let url = `/#!/doc?`;

    let params = ["uid=" + file.id, "name=" + file.title];

    if (withJWT) {
      params.push("jwt=" + "this.getJWT()");
    }

    if (preview) {
      params.push("preview=true");
    }

    url += params.join("&");
    return url;
  }

  /* ####################################### TASKS WORKFLOW ################################### */

  //to be removed: current version in taskService
  async getTasks() {
    let allTasks = [];
    let url = `/ged/documents/workflows/mytasks`;

    try {
      let tasks = await this.api.get(url);

      allTasks = tasks?.["content"];

      return allTasks;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async getWorkflow(uid: string) {
    let url = `/ged/documents/uid/${uid}/workflow`;

    try {
      let wf = await this.api.get(url);
      return wf;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  /* ####################################### LINKS ################################### */

  async getLinks(path: string, uid: string) {
    let url = `/ged/documents/uid/${uid}?$withMeta=true&path=${path}`;
    try {
      let document = await this.api.get(url);
      this.links = document?.["content"].links;
      return this.links;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  /* ####################################### FOLDERS/SPACES ################################### */

  // create new fom (create shared drive/folder) => get form
  async getNewSpaceForm(): Promise<{ data: any, metadata: any }> {
    let url = "/ged/spaces/folder_empty_form/Space";
    let params = {
      withMeta: true
    }

    try {
      let res: { content: { data, metadata } } = await this.api.get(url, params);
      return res.content;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  // attach exiting folder as Space => get form
  async getFolderMetaByType(typeId: string, folderName?: string, uid?: string) {
    let url = `/ged/spaces/folder_empty_form/${typeId}`;
    let params = {
      withMeta: true,
      name: folderName,
      uid
    }

    try {
      let res: { content: any } = await this.api.get(url, params);
      return res.content;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async inGedSpace(extension: string, folderName: string, folderId: string, query = "") {
    const uid = folderId;

    let url = `/ged/spaces/uid/${uid}?$withMeta=true`;
    if (query)
      url += "&query=" + query;

    try {
      let res: { content: any } = await this.api.get(url);

      return res.content;
    }
    catch (error) {
      console.log(error);
      return null;
    }
  }


  //deprecated
  isInGed(uid): Boolean {
    this.getTasks().then((myTasks) => {
      for (let i in myTasks) {
        if (myTasks[i].uid == uid) {
          localStorage.path = myTasks[i].path;
          this.temp = myTasks[i];
        }
      }

      if (this.temp != null && this.temp.uid == uid) {
        this.inGed = true;
        return true;
      }
      else {
        this.inGed = false;
        return false;
      }
    });
    return this.inGed;
  }

  // ============= SPACE =================
  async createSpace(spaceinfos: SpaceInfos): Promise<SpaceId> {
    let url = "/ged/spaces/create";

    try {
      let res: { content: SpaceId } = await this.api.post(url, spaceinfos);
      return res.content;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  // ============= PERMISSIONS =================
  async getPermissionList(spaceId: string): Promise<GedPermission[]> {
    const url = "/ged/spaces/permissions/" + spaceId;

    try {
      const res: { content: GedPermission[] } = await this.api.get(url);
      return res.content;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async addPermission(
    spaceId: string,
    email: string,
    role: GedPermRole = "content manager",
    type: GedPermType = "user"
  ): Promise<GedPermission> {
    let url = "/ged/spaces/permissions/" + spaceId;

    try {
      let permInfos: GedPermissionPostInfos = { email, role, type };
      let res: { content: GedPermission } = await this.api.post(url, permInfos);
      return res.content;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async removePermission(spaceId: string, permId: string) {
    let url = "/ged/spaces/permissions/" + spaceId + "/" + permId;

    try {
      await this.api.delete(url);
      return true;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  // ================== DOCUMENT ================
  getGedDocReader(path: string) {

    localStorage.path = path;
    return (this.docReaderFromPath.find((item) => path.includes(item.path))?.docReader || "document");

    //return this.docReaderFromPath.find(item=>path.includes(item.path))?.docReader || '.gdoc'
    // if (path.includes('.gdoc')) {
    //   return 'document';
    // }
    // if (path.includes('.gsheet')) {
    //   return 'spreadsheets';
    // }
    // if (path.includes('.gpres')) {
    //   return 'presentation';
    // }
  }

  docReaderFromPath = [
    { path: '.gdoc', docReader: 'document' },
    { path: '.gsheet', docReader: 'spreadsheets' },
    { path: '.gpres', docReader: 'presentation' }
  ]

  async actionExec(action, document) {
    let role = document?.role;
    let state = document?.state;
    let actionName = action.name;
    let oid = document?.oid;
    let data = {};
    let url = `/ged/documents/${oid}/role/${role}/state/${state}/actions/${actionName}?$withMeta=true`;

    try {
      let response = await this.api.post(url, data);

      return response;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async uploadFile(file, parentId: string, fields = "id,name,iconLink,properties,thumbnailLink") {
    let data = { file };

    let formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k]);
    });

    let url = `/ged/documents/upload?fields=${fields}&parentId=${parentId}`;

    try {
      let response = await this.api.post(url, formData);

      return response;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

  async uploadFileWithForm(
    file: File,
    data: Record<string, any>) {
    const jsonForm = JSON.stringify(data);

    // set payload
    let data2 = { file, jsonForm };

    // format with FormData for handling file upload
    let formData = new FormData();
    Object.keys(data2).forEach((k) => {
      formData.append(k, data2[k]);
    });

    let url = '/ged/documents/upload?$withMeta';
    try {
      let res: { content } = await this.api.post(url, formData);

      this.snackBar.open("Document created successfully", "", {
        duration: 500,
      });

      return res.content;
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }



  /* ####################################### DELETE/DETACH ################################### */


  async detachFromGED(fileGed) {
    const uid = fileGed.id;
    const name = fileGed.name;
    let url = '/ged/documents/uid/' + uid;

    try {
      const resp = await this.api.delete(url, null, null, true, true);

      this.snackBar.open("Document " + name + " detached from Presence Docs but still in your Drive",
        "",
        {
          duration: 5000,
        }
      );

      return resp;
    }
    catch (error) {
      this.snackBar.open("Document " + name + " detachment from Presence Docs failed, check your permission on that file", "", { duration: 5000, });
      console.log(error);
      return error;
    }
  }

  async deleteDoc(fileGed) {
    const uid = fileGed.id;
    const name = fileGed.name;
    let url = `/ged/documents/uid/${uid}?fullDelete=true`;

    try {
      const resp = await this.api.delete(url, null, null, true, true);

      if (resp["code"] != 403) {
        this.snackBar.open("Document " + name + " deleted", "", { duration: 5000, });
      }
      else {
        this.snackBar.open("Document " + name + "Failure, You dont have the permission to delete this file", "", { duration: 5000, });
      }
      return resp || null;
    }
    catch (error) {
      this.snackBar.open("Document " + name + "Failure, You dont have the permission to delete this file", "", { duration: 5000, });
      return error;
    }
  }

  async deleteFolder(uid, name = "") {
    let url = `/ged/folders/uid/${uid}?fullDelete=true`;
    try {
      let response = await this.api.delete(url);
      this.snackBar.open("Folder " + name + " deleted", "", { duration: 5000, });
      return response;
    }
    catch (error) {
      this.snackBar.open("Folder " + name + " deletion failed, check your permission on that folder", "", { duration: 5000, });
      console.log(error);
      return error;
    }
  }

  /* ####################################### Révisions document ################################### */

  async getDocRevisions(uid) : Promise<any[]>
  {
    const what = "revisions,tasks";
    let url = `/ged/documents/${uid}/revisions`;

    try {
      let res : {content:[]} = await this.api.get(url);

      return res.content;
    }
    catch (error) {
      throw error;
    }
  }

  async getDocHistory(uid) : Promise<any>
  {
    const what = "revisions,tasks";
    let url = `/ged/documents/uid/${uid}/history?what=${what}`;

    try {
      let res  = await this.api.get(url);

      return res.content?.workflow;
    }
    catch (error) {
      throw error;
    }
  }


  //getUsers
  async getAutocompleteListUser(searchText) {

    let url = '/gpeople';

    if (searchText) {
      url += "/search?$view=autocomplete&with_groups=true&text=" + searchText;
    }
    else {
      url += "/search?$view=autocomplete&with_groups=true&text=";
    }

    try {
      let response: any = await this.api.get(url);

      return response.content?.data;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  }

}
