import { availableLocales } from "../i18n";
import englishJson from "src/assets/i18n/en.json";

export const stringFile={
    stringsJson: returnNavObjectFile(),
    defaultStringFile: englishJson
}

export function returnNavObjectFile()
{
    let locale = availableLocales.find(item =>
        navigator.language.startsWith(item.localeName)
    );

    let navLanguage = (locale && locale.languageJson) || englishJson;

    return navLanguage;
}
