import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
  name: 'formManagerSelectFormatData'
})
export class FormManagerSelectFormatData implements PipeTransform {

  transform(
    data: Array<Record<'html' | 'label' | 'value', string>>
  ): Array<Record<'label' | 'value', string>>
  {
    return data?.map(({
      html,
      label,
      value
    }: Record<'html' | 'label' | 'value', string>) => ({
      label: label || html,
      value
    })) || [];
  }

}
