import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { APISce } from 'src/modules/app-common/services';


const GPEOPLE_API = environment.GPEOPLE_API

export const profilesCache = []


export const profilesArray = [];
export class ProfileResp {
  url: string;
  resp: any;
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(

    private api: APISce,
  ) {

    ContactService.instance = this;
  }

  public static instance: ContactService
  profileReqs = new Set()
  profileResp: Set<ProfileResp> = new Set();


  //works
  async getProfileByMail(mail: string, fields = "names,emailAddresses,addresses,photos,metadata") {
    if (mail == null || mail == undefined || !mail.includes("@")) {
      return null;
    }
    else {
      if (!fields)
        fields = ["emailAddresses", "addresses", "coverPhotos", "externalIds",
          "locations", "memberships", "metadata", "names", "organizations", "phoneNumbers",
          "photos", "relations", "ageRanges", "locales"
          , "biographies", "birthdays", "calendarUrls", "clientData",
          "genders"
        ]
          .join(',');

      let url = `${GPEOPLE_API}/${mail}/profile?fields=${fields}`;
      let existingReq = this.profileReqs.has(url);

      if (existingReq) {
        // from request cache
        let existingResponse = Array.from(this.profileResp).find(item => item.url == url)?.resp;
        return existingResponse;
      }
      else {
        // call the server

        try {
          let response: { content } = await this.api.get(url);
          let profile = response?.content || null;

          this.profileReqs.add(url);
          this.profileResp.add({ url: url, resp: profile })

          return profile;
        }
        catch (err) {
        }
      }
    }
  }

  async getImageByMail(mail: string, size = '600') {
    if (!!mail) {
      try {
        let url = `${GPEOPLE_API}/${mail}/photo?width=${size}`;
        let headers = await this.api.httpConfig(true, false)   //this.googleLoginService.httpConfig(); // ag
        let options = { headers, responseType: 'text' }


        // let image = await this.http.get(url, options).toPromise()
        //   return image;
        // })
        let image = await this.api.get(url, {}, options) || null;

        return image;
      }
      catch (exception) {

        return null;
      }
    }
    else { return null };


  }


}
