import { stringFile } from "src/assets/strings/strings";
import { SearchGdriveService } from "../services/search-gdrive.service";
import { GdriveItem } from "./gdrive-item";

export class GdriveFolder extends GdriveItem 
{
    appUrl; //folder
    color; //folder
    driveId; //folder
    icon; //folder
    mainType;//folder

    constructor(item){
        super(item);
        this.setAppUrl(item);
        this.setColor(item);
        this.setDriveId(item);
        this.setIcon(item);
        this.setMainType(item);
        this.getDisplayProperties();
    }

    _(s:string) : string {
        return stringFile.stringsJson[s] || s;
    }

    getDisplayProperties(urlPrefix='') {
        var self = this;
         
        if(this.displayProperties)
            return this.displayProperties;

        this.displayProperties = 
        [
            {
                id: 'name',
                value: this.getName(),
                iconImg: this.getIconLink(),
                link: '/s/space/' + this.getUid()
            },
            {
                id: 'owner',
                value: this.getOwner()?.displayName || this.getOwner()?.emailAddress,
            },
            {
                id: 'lastModified',
                value: this.getModifiedTime(),
             },
      
             {
                id: 'type',
                value: this.getTypeDocument() || this._('Folder'),
             },
             {
                id: 'state',
                value: null,
             },
             {
                id: 'Menu',
                value: '',
                menuItems: 
                [
                    {
                        label: stringFile.stringsJson['folderstr'],
                        callback: () =>
                        {
                              SearchGdriveService.instance.navigateTo(['/s/space/'+self.getUid()])
                        },
                     },
                     {
                        label: stringFile.stringsJson['openParent'],
                        callback: () =>
                        {
                            SearchGdriveService.instance.navigateTo(['/s/space/'+self.getParentId()])
                        }
                     },
                     {
                      label: stringFile.stringsJson['fichier'],
                      callback: () => {
                         window.open(self.getWebViewLink(),"_blank")
                      }
                    }                      
                ]
             }
        ]
        return this.displayProperties;
    }

    setAppUrl(item){
        this.appUrl = this.getProperties()?.app_url;
    }
    
    setColor(item){
        this.color = this.getProperties()?.color;
    }
    
    setDriveId(item){
        this.driveId = this.getProperties()?.driveId;
    }
    
    setIcon(item){
        this.icon = this.getProperties()?.icon;
    }
    
    setMainType(item){
        this.mainType = this.getProperties()?.main_type;
    }

    getAppUrl(){
        return this.appUrl;
    }

    getColor(){
        return this.color;
    }

    getDriveId(){
        return this.driveId;
    }

    getIcon(){
        return this.icon;
    }

    getMainType(){
        return this.mainType;
    }
}