import { Component, OnInit, AfterViewInit } from '@angular/core';

import { environment } from 'src/environments/environment';

declare var google: any;
//import { accounts } from 'google-one-tap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  tokenClient: any;

  constructor() {
  }

  log(s, s2 = '') {
    console.log("LoginComponent", s, s2);
  }

  ngOnInit(): void {
    // // init authorization flow
    // this.googleLoginService.initAuthorizeToken();  // ag

    // // request token without clicking on button
    // this.googleLoginService.requestAuthToken();  // ag
  }

  onButtonAuthorize() {
    // this.googleLoginService.requestAuthToken(); // ag
  }

  ngAfterViewInit() {
    // this.googleLoginService.signIn('g-signin-btn');
  }

  signOut() {
    //this.googleLoginService.signOut()
  }


  // ==== SIMPLE GSI SIGNIN ===========
  /*
  ngOnInit(): void
  {
    let withSigninOnly = false;

    // init GSI signin lib : useful for generating the google button
    if(withSigninOnly)
      this.initSimpleGSISignin();

    // init authorization flow
    this.googleLoginService.initAuthorizeToken();

    // request token without clicking on button
    this.googleLoginService.getAuthToken();

    // create Google signin button
    // NB. here we use the GSI button generator, and redirect to authorize.
    // later on, we could replace that by our own button and only use the authorize flow.
    if(withSigninOnly)
      this.generateGoogleSigninButton("g-auth-btn",
        (credentials) =>
        {
          this.log("Signin > Signin button cb",credentials);

          this.googleLoginService.getAuthToken();
        });
  }

    // use this function to initialise the lib for simple signin without access token
  // NB. not very useful because we dont get an access token for use by APIs
  // better use "Authorise" flow to get a token...
  initSimpleGSISignin()
  {
    this.log("Signin > google.accounts.id.initialize()");

    google.accounts.id.initialize({
      client_id: environment.CLIENT_ID,
      ux_mode: 'popup',
      auto_select: true,
      prompt_parent_id:'login_content',
      cancel_on_tap_outside: true,
      callback: ({ credential }) =>
      {
        this.log("Signin > google.accounts.id.initialize callback()",credential);
        let googleUser = this.decodeCredentials(credential);

      },
    });
  }

  decodeCredentials(jwtCred : string) : any
  {
    let res = atob(jwtCred.split('.')[1]);

    let infos = JSON.parse(res);
    console.log(infos);

    let user = new GSIUser(infos,null);

    return user;
  }

  generateGoogleSigninButton(buttonId = 'gsi-signin',click_listener=null)
  {
    this.log("Signin > generateGoogleSigninButton()");

    let button = document.getElementById(buttonId) as HTMLElement;

    let options : any = {
      type: "standard",
      theme: "outline",
      size: 'large',
      text: 'signin_with',
      logo_alignment:'left',
      shape:'circle',
      // width: 250
    }

    if(click_listener)
      options.click_listener = click_listener;

    google.accounts.id.renderButton(button, options);
  }
  */

}
