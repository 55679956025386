import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { TableColumn, TableRow } from '@interfaces/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-main',
  templateUrl: './search-main.component.html',
  styleUrls: ['./search-main.component.scss']
})
export class SearchMainComponent implements OnInit, OnChanges 
{
  @Input("searchResponse") searchResponse;
  @Input("resultGroup") resultGroup;
  @Input("keyword") keyword;
  searchResponseObservable;
  @ViewChild("myTemRef") myTemRef!: TemplateRef<any>;

  constructor(private router: Router) 
  { 

  }

  isLoading = true;
  documentData : any;

  ngOnInit(): void 
  {
    this.selectedGroup = 
      (this.searchResponse.results || [])
        .find(group => 
          {
            return group.id==this.resultGroup;
          });
          

         
  }

  selectedGroup
  
  ngOnChanges(changes: SimpleChanges): void {
   
    if(changes.searchResponse)
    {
      this.isLoading = true;    
      let results = this.searchResponse.results || [];
 
      this.isLoading = false;
      this.checkIfEmptyData(results)
      this.selectedGroup = results.find(group=>group.id==this.resultGroup)
      this.searchResponseObservable = of(results);
      (this.searchResponse?.results || []).forEach(object => 
       {

        object.results.forEach((r) => {
          r.displayProperties.forEach((r) => {
            if (r.id === "Flags") {
              r.html = this.myTemRef

            }
          })
        })

        object.columns.forEach((r) => {
          if (r.id === "rating") {
            r.class = "custom-rating";
          }
        })
      }) 
     
      this.documentData =results[0]?.data
       if(this.resultGroup==''){
         this.setDataLength()
      }

      this.setTable(results);
    }
  }

  setDataLength(){
   (this.searchResponse?.results || []).forEach(group=>group.data = group.data.filter((item,index)=>index<3))
  }

  tableObservable

  setTable(searchResponse)
  {
    // not needed
    let tableResponse =[]
      
    let arr = [];
    tableResponse.forEach(groupArray=>arr.push(...groupArray));

    this.tableObservable = of(tableResponse)   
  }

  setGroup(group)
  {

    this.router.navigate(['/search'],{queryParams: {q:this.keyword,group: group}});
 }

 allEmptyData = false;

 checkIfEmptyData(results){
   this.allEmptyData = (results.find(item=>item.results.length!=0)==undefined)? true : false;
 }
}
