import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CustomTableService } from "./service/crud-list-service.service";
import { FormRecord } from "./utils/form-record";
import { TableColumn, TableRow } from "@interfaces/table";

@Component({
  selector: "app-crud-list",
  templateUrl: "./crud-list.component.html",
  styleUrls: ["./crud-list.component.scss"],
})
export class CrudListComponent implements OnInit {
  // inputs
  @Input() label: string = "";
  @Input() addPageTitle: string = "";
  @Input() addPageRoute: string = "";
  @Input() editPageTitle: string = "";
  @Input() editPageRoute: string = "";
  @Input() data: any;
  @Input() dataFromUrl: any;
  @Input() loading: boolean = true;
  @Input() actions: any[] = [];

  // outputs
  @Output() onChangeEvent = new EventEmitter<string>();
  @Output() onLastChangeEvent = new EventEmitter<string>();
  @Output() onTableScrollEvent = new EventEmitter<string>();
  @Output() onLoadData = new EventEmitter();

  columns: TableColumn[] = [];
  dataSource: TableRow[][] = [];

  searchFormControl = new FormControl();

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private customTableService: CustomTableService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.searchFormControl.valueChanges.subscribe((res) =>
      this.emitChangeEvent(res)
    );
  }

  execFunc(func: Function, params: any) {
    func(params);
  }

  onScrollDown() {
    if (!this.loading) this.onTableScrollEvent.emit();
  }

  async deleteRecord(url: string, id: string) {
    const isConfirmed = window.confirm("Voulez-vous supprimer cet record?");
    if (isConfirmed) {
      this.loading = true;
      (await this.customTableService.deleteRecord(url, id))
        .subscribe((data) => {
          if (data.success === false) {
            this.snackbar.open(data.message, "", {
              duration: 5000,
            });
            this.loading = false;
            return;
          }
          this.snackbar.open("Le record a été supprimé avec succès", "rejeter", {
            duration: 5000,
          });
          if (this.dataFromUrl) {
            this.loadData();
          } else {
            this.onLoadData.emit();
          }
        });
    }
  }

  async loadData(serchVal = "") {
    (await this.customTableService
      .getData(this.dataFromUrl, serchVal))

      .subscribe((data) => {
        if (data) {
          this.loading = false;
          const { content } = data;

          const fr = new FormRecord(content, "form");

          const fields = Object.entries<any>(fr.fields()).filter(
            ([key, field]) => !field.desc().isHidden()
          );

          this.dataSource =
            [
              ...fr.getData().map((d) => {
                let rawData: any = d;

                const row = [];
                fields.forEach(([key]) => {
                  let value: any = d[key];

                  if (value && typeof value === "object" && "html" in value) {
                    value = value.html;
                  }

                  row.push({
                    id: key,
                    oid: rawData.oid,
                    value
                  });
                });

                row.push({
                  id: "menu",
                  value: "",
                  menuItems: [
                    {
                      label: "Open",
                      callback: (row: any) => {
                        this.openLink(rawData.oid);
                      },
                    },
                    {
                      label: "Delete",
                      callback: (row: any) => {
                        this.deleteRecord(this.dataFromUrl, rawData.oid);
                      },
                    },
                  ],
                });
                return row;
              }),
            ];

          this.columns = fields.map(([key, field]) => {
            let fdesc = field.desc();
            return {
              id: fdesc.name,
              type: "Text",
              label: fdesc.label,
              canSort: true,
            };
          });

          this.columns.push({
            id: "menu",
            type: "Menu",
            width: 50,
            class:'custom-menu',
          });

          // if (
          //   this.actions.length > 0 &&
          //   this.displayedColumns.indexOf("actions") === -1
          // ) {
          //   this.displayedColumns.push("actions");
          // }
        }
      });
  }

  ngOnChanges() {
    if (this.dataFromUrl) {
      this.loadData();
    }
    else {
      if (this.data && this.data.content) {
        const { content } = this.data;
        const fr = new FormRecord(content);

        this.dataSource =
          [
            ...fr.getData().map((d) => {
              const row = [];
              fr.getMetaKeys().forEach((key) => {
                row.push({
                  id: key,
                  value: d[key],
                });
              });
              return row;
            }),
          ];

        this.columns = fr.getMetaKeys().map((col) => ({
          id: col,
          type: "Text",
          label: col,
          canSort: true,
        }));
        // if (
        //   this.actions.length > 0 &&
        //   this.displayedColumns.indexOf("actions") === -1
        // ) {
        //   this.displayedColumns.push("actions");
        // }
        // this.onLastChangeEvent.emit(this.dataSource[items.length - 1].id);
      }
    }
  }

  emitChangeEvent(value) {
    if (this.dataFromUrl) {
      this.loadData(value);
    } else {
      this.onChangeEvent.emit(value);
    }
  }

  addPage() {
    this.router.navigateByUrl("crud-component" + this.addPageRoute, {
      state: {
        data: {
          title: this.addPageTitle,
          url: this.dataFromUrl,
          template: "add",
        },
      },
    });
  }

  openLink(docId: any): void {
    this.router.navigateByUrl(
      "crud-component" + this.editPageRoute + `/${docId}`,
      {
        state: {
          data: {
            title: this.editPageTitle,
            url: this.dataFromUrl,
            template: "edit",
            id: docId,
          },
        },
      }
    );
  }

  goback() {
    this.router.navigate([ this.data.routeSpaceUrl],{ relativeTo: this.route });
  }
}
