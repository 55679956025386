import { APISce } from 'src/modules/app-common/services';
import { IEnumDataProvider } from '../interfaces/enum-data-provider.interface';

export class EnumDataProvider implements IEnumDataProvider
{
  constructor(private api: APISce) {}

/**
 * Récupère la liste des énumérations pour un champ donné.
 * @param fname Le nom du champ pour lequel récupérer la liste d'énumérations.
 * @returns Une promesse résolue avec la liste d'énumérations ou une erreur en cas d'échec.
 */
  async getEnumList(fname: string)
 {
    try
    {
      let res = await this.api.get(`${fname}`);
      return res.content.data;
    }
    catch (error)
    {
      return error;
    }
  }

  /**
 * Récupère les données d'autocomplétion en fonction de l'URL et du texte donnés.
 * @param url L'URL pour récupérer les données d'autocomplétion.
 * @param text Le texte de filtrage pour les données d'autocomplétion.
 * @returns Une promesse résolue avec les données d'autocomplétion ou une erreur en cas d'échec.
 */
  async getAutocompleteData(url: string, text: string) {
      if(!url.includes("?"))
      url += "?";

    if(!url.startsWith("http"))
      url = url;
      try
      {
        let res  = await this.api.get(`${url}&text=${text}`);
        return res.content.data;
      }
      catch (error)
      {
        console.log(error);
        return error;
      }
  }

   /**
 * 
 * @param objectToAdd  un nouveau tag à ajouter.
 * @param fieldDesc ou on ajoute le tag.
 * @returns Une promesse résolue avec les données d'autocomplétion ou une erreur en cas d'échec.
 */
   async addTag(objectToAdd: string, fieldDesc) {
    
    try
    {
    let res = await  this.api.post(fieldDesc.urlAdd, objectToAdd);
    
    return res["content"]?.data;
    }
    catch (error)
    {
      console.log(error);
      return error;
    }
}
}
