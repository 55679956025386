import { FileItem } from "../../models/file/FileItem.model";

export { FileItem } from "../../models/file/FileItem.model";

import { FieldDesc } from "../../../form-manager/modeles/field-desc.model";

export class SpaceItemState
{
  value:string;

  data : {
    label:string;
    value:string;
    color?:string;
    role?:string;
    description?:string;
    empty?:string;
    icon?:string;
  };

  constructor(state,value) {
    this.data = state;
    this.value = value;
  }

  id() :string { return this.value; }
  label() :string { return this.data.label; }
  color() :string { return this.data.color; }
  role() :string { return this.data.role; }
  description() :string { return this.data.description; }
  emptyListString() :string { return this.data.empty || 'empty list'}
  icon() :string { return this.data.icon || null; }

}

// interface for spaces and folders
export interface GedFileContainer
{
  getName(): string;
  getIcon(): string;
  getDescription(): string;
  getOwner(): string;
  getDrive(): string;
  getUid() : string;

  canEdit() : boolean;
  getChildren() ;
  getFiles();
  getFieldDescs() : Record<string,FieldDesc>;
  getFieldDesc(fname) : FieldDesc;
  getEnumValues(fname) : {label:string, value:string}[];
  getFileUrl(file) : string;
  getFacets() : {};
}

export class Space implements GedFileContainer
{
  // space data received when displaying a space
  _data;
  _metadata;
  _space;

  // children field desc
  _fdescs : Record<string,FieldDesc>;
  _fdescArray : FieldDesc[];

  static _docTypesByModelName : Record<string,any>;

  name: string;
  app_url: string;
  color: string;
  description: string;
  driveId: string;
  icon: string;
  owner: string;
  sid: string;
  uid: string;
  initialLetter : string;
  states : SpaceItemState[];

  // space informations : permission and metadata tables
  spaceInfos = {
    canEdit:false,
    tables:{}
  };

  tables = [];

  docTypeName:string;

  constructor(space,spaceInfos=null)
  {
    let data = space.data || space;

    this._space = space;
    this._data = data;
    this._metadata = space.metadata || space;

    // init space properties
    if(data)
    {
      this.setName(data);
      this.setUrl(data);
      this.setColor(data);
      this.setDescription(data);
      this.setIcon(data);
      this.setOwner(data);
      this.setSid(data);
      this.setUid(data);
      this.setDrive(data);
    }

    // add extra infos on tables and permissions
    this.spaceInfos = space.metadata?.space || data.infos;
    if(this.spaceInfos)
    {
      this.initMetaEditTables(this.spaceInfos,this.sid,this.name);
    }
  }

  data() {
    return this._data;
  }

  prop(k) {
    return this._data[k] || this._space[k] || null;
  }

  getName() {
    return this.name;
  }

  getSortIndex() {
    return this.name;
  }

  getGroupName() {
    return this.prop('groupName') || null;
  }

  setGroupName(g) {
    return this._data['groupName'] = g;
  }

  getOid() {
    return this.prop('oid');
  }

  getUrl() {
    return "/s/space/"+this.getUid();
  }

  hasAppUrl() {
    return !!this.app_url;
  }

  getColor() {
    return this.color;
  }

  getIcon() {
    return this.icon;
  }

  getDescription() {
    return this.description;
  }

  setDocTypeName(docTypeName : string) {
    return this.docTypeName = docTypeName;
  }

  static setDocTypes(docTypesByModelName : Record<string,any>) {
    this._docTypesByModelName = docTypesByModelName;
  }

  getDocTypeName() {
    return this.docTypeName;
  }

  getDocTypeOid() {
    return this.prop('main_type');
  }

  getOwner() {
    return this.owner;
  }

  getDrive() {
    return this.driveId;
  }

  getSid() {
    return this.sid;
  }

  getUid() {
    return this.uid;
  }

  getInitialLetter(){
    return this.initialLetter
  }

  setName(space) {
    this.name = space?.name;
  }

  setUrl(space) {
    this.app_url = space?.app_url;
  }

  setDescription(space) {
    this.description = space?.description;
  }

  setIcon(space) {
    this.icon = space?.icon;
  }

  setColor(space) {
    this.color = space?.color;
  }

  setOwner(space) {
    this.owner = space?.owner;
  }

  setDrive(space) {
    this.driveId = space?.driveId;
  }

  setUid(space : {uid}) {
    this.uid = space?.uid;
  }

  setSid(space: {sid}) {
    this.sid = space?.sid;
  }

  setInitialLetter(space) {
    this.initialLetter = space?.name.charAt(0);
  }

  // ======= WORKFLOW ========
  hasStates() {
    return !!this._space?.["children"]?.metadata?.states;
  }

  getStates() : SpaceItemState[] {
    if(this.states)
      return this.states;

    let states = this._space?.["children"]?.metadata?.states;

    if(states)
    {
      this.states = [];
      for (let id in states)
      {
        this.states.push( new SpaceItemState(states[id],id) );
      }
    }

    return this.states;
  }

  // space extra informations
  canEdit() {
    return this.spaceInfos.canEdit || false;
  }

  // ========== CONTENT =========

  /**
   *
   *
   */
  getChildren() :
  {
    files: {}[],
    metadata: Record<string,
        {
          fields:Record<string,{}>
        }>
      }
  {
    return this._space?.children;
  }

  _files : FileItem[];

  /**
   * get list of files in the space
   */
  getFiles() : FileItem[]
  {
    if(!this._files)
    {
      let fieldDescs = this.getFieldDescs();

      this._files = (this._space?.children?.files || [])
        .map(file => new FileItem(file,fieldDescs));

      this._files.forEach(item => this.transformFields(item));
    }
    return this._files;
  }

  transformFields(item : FileItem)
  {
    const modelName = item.getModelName();

    const typeName = Space._docTypesByModelName[modelName]?.title;
    if(typeName)
      item.setTypeName(typeName);

    return item;
  }

  /**
   * get field metadata
   *
   * @returns
   */
  getFieldDescs() : Record<string,FieldDesc>
  {
    if(!this._fdescs)
    {
      this._fdescs = {};

      // init field descs
      const fdescs = this._space?.children?.metadata?.fields || {};
      if(fdescs)
      {
        for (const fname in fdescs)
        {
          const desc = this._space?.children?.metadata?.fields[fname];
          this._fdescs[fname] = new FieldDesc(desc,fname);
        }

        this._fdescArray = Object.values(this._fdescs);
      }
    }

    // return this._space?.children?.metadata?.fields || null;
    return this._fdescs;
  }

  getOwnerField()
  {
    return {
      name:"owner",
      label: "Owner",
      type:'string'
    }
  }

  getFieldDesc(fname: string) {
    return this._space?.children?.metadata?.fields && this._space?.children?.metadata?.fields[fname];
  }

  /**
   * get list of enum values if defined in metadata
   * @param fname
   * @returns
   */
  getEnumValues(fname: string) : {label:string, value:string}[]
  {
    let enums = this.getFieldDesc(fname)?.enumValues?.data;
    return enums;
  }

  getFileUrl(file : {id}) : string {
    return "/s/space/"+this.getUid()+"/file/"+file.id;
  }

  // ========= SEARCH FACETS
  getFacets() {
    return this._space.metadata?.space?.facets;
  }

  facetInfos; // cache

  /**
   * get facets fields
   *
   * @returns
   */
  getFacetsFields() : Record<string,FieldDesc>
  {
    return this.getFacetFieldDescs();

    // cached value
    if(this.facetInfos)
      return this.facetInfos;

    // get space facets from backend
    let facets = this._space.metadata?.space?.facets;

    if(facets?.facetInfos)
      // return
      this.facetInfos = facets.facetInfos;
    else
      // if no facet defined in space infos, get all fields as facets
      this.facetInfos = this.getFieldDescs();

    return this.facetInfos;
  }

  _facetsFieldDescs : Record<string,FieldDesc>;
  getFacetFieldDescs() : Record<string,FieldDesc>
  {
    if(!this._facetsFieldDescs)
    {
      this._facetsFieldDescs = {};

      let facetInfos = this._space.metadata?.space?.facets?.facetInfos;
      if(facetInfos)
      {
        for (const fname in facetInfos)
        {
          const desc = facetInfos[fname];
          this._facetsFieldDescs[fname] = new FieldDesc(desc,fname);
        }
      }
    }

    return this._facetsFieldDescs;
  }

  getFacetInfos(fname) {
    let infos = this.getFacetsFields();
    return infos[fname] || null;
  }

  getContentSortOrder() : {fname:string,reverse:boolean}
  {
    let orderBy = this._space.metadata?.space?.facets?.orderBy?.split(' ');
    if(orderBy)
    {
      let fname = orderBy && orderBy[0] || 'name';
      let reverse = ((orderBy[1] || "asc").toLowerCase() != "asc");

      return {fname,reverse};
    }

    return null;
  }

  // tables with content used by space meta data

  getMetaTables() {
    return this.tables;
  }

  initMetaEditTables(spaceInfos,sid,spaceName)
  {
    let tableInfos = spaceInfos.tables;

    let tables = [];
    for(let t in tableInfos)
    {
      let table = tableInfos[t];
      table.sid = sid;
      table.spaceName = spaceName;
      table.tid = t;

      let url = btoa(table.url);
      table.routeUrl = "/metatable/"+sid+"/"+t+"/"+url;
      table.routeSpaceUrl = "../.."; //this.route.pathFromRoot;
      let tdesc64 = btoa(JSON.stringify(table));
      table.routeSpacedUrl = "./meta-edit/"+tdesc64;
      tables.push(table);
    }

    this.tables = tables;
  }
}
