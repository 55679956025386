import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Video } from 'src/design-system/core/interfaces/video';

@Component({
   selector: 'app-tutorials',
   templateUrl: 'bootstrap/tutorials.component.html',
   styleUrls: ['bootstrap/tutorials.component.scss'],
})
export class TutorialsComponent implements OnInit {
   tutos: Video[] = [
      {
         id: 1,
         title: 'How to store documents',
         link: 'https://youtube.com',
      },
      {
         id: 2,
         title: 'How to store documents',
         link: 'https://youtube.com',
      },
      {
         id: 3,
         title: 'How to store documents',
         link: 'https://youtube.com',
      },
      {
         id: 4,
         title: 'How to store documents',
         link: 'https://youtube.com',
      },
      {
         id: 5,
         title: 'How to store documents',
         link: 'https://youtube.com',
      },
      {
         id: 6,
         title: 'How to store documents',
         link: 'https://youtube.com',
      },
      {
         id: 7,
         title: 'How to store documents',
         link: 'https://youtube.com',
      },
      {
         id: 8,
         title: 'How to store documents',
         link: 'https://youtube.com',
      },
   ];
   constructor(private _location: Location) {}
   goback() {
      this._location.back();
   }
   ngOnInit(): void {}
}
