import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { AuthService } from '../../../user/services';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
const BACKEND_URL = environment.BACKEND_URL;
@Injectable({
  providedIn: 'root'
})
export class ApiObsService {
  constructor(private http: HttpClient, private auth: AuthService) { }


  httpConfig(
    withGoogleToken = true,
    withJWT = true,
    withGoogleCookie = false
  ) {
    let headers = new HttpHeaders();

    if (withJWT) {
      // headers.set
      headers = headers.set("Authorization", "Bearer " + this.getJWT());

      if (withGoogleToken) {
        this.getCurrentToken()
        .pipe(
          tap((gtoken) => headers = headers.set(`GTOKEN`, `Bearer ${gtoken}`))
        )
        .subscribe(
          {
            error: () =>  console.error("missing token in API")
          }
        )
      }
    } else if (withGoogleToken) {
      this.getCurrentToken()
        .pipe(
          tap((gtoken) => headers = headers.set(`Authorization`, `Bearer ${gtoken}`))
        )
        .subscribe(
          {
            error: () =>  console.error("missing token in API")
          }
        )
    }

    if (withGoogleCookie) {
      headers = headers.set("Cookie", "G_ENABLED_IDPS=google");
    }

    let options = { headers };
    // console.log("headers from source")
    // console.log(options)
    return options;
  }

  getJWT() {
    return this.auth.jwt;

  }

  getCurrentToken() {
    return this.auth.getTokenWithObservable();
  }

  getToken() {
    return this.auth.token;

  }

  get<T>(
    url,
    params?,
    options?,
    withJwt = true,
    withGoogleToken = true
  ) {
    url = BACKEND_URL + url;

    if (!options) options = this.httpConfig(withGoogleToken, withJwt);
    // console.log('options : ', options)
    if (!!params) options.params = params;

    return this.http.get<T>(url, options)
  }

  post<T>(
    url,
    params?,
    body?,
    options?,
    withJwt = true,
    withGoogleToken = true
  ) {
    url = BACKEND_URL + url;

    if (!options) options = this.httpConfig(withGoogleToken, withJwt);
    // console.log('options : ', options)
    if (!!params) options.params = params;

    return this.http.post<T>(url,body,options)
  }

}
