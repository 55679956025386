import { GcsItem } from "./gcs-item";

export class GcsCalendar extends GcsItem{

    constructor(obj){
        super(obj);
    }

    setIconLink(gcsItem) {
        this.iconLink = '/assets/google_icons/calendar_icon.svg';
    }
}