import { ChangeDetectionStrategy, Component } from '@angular/core';

import { environment } from 'src/environments/environment';


const $theme = environment.themeUI;


@Component({
  selector: 'app-footer',
  templateUrl: $theme + '/footer.component.html',
  styleUrls: [$theme + '/footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

  get year(): number {
    return new Date().getFullYear();
  }

}
