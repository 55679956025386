import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wokrflow',
  templateUrl: './wokrflow.component.html',
  styleUrls: ['./wokrflow.component.scss']
})
export class WokrflowComponent implements OnInit {

  @Input('title') title : string = ''; 
  @Input() workflowRoles ; 
  @Input() isInfo ; 



  constructor() { }

  rightButton() {
    document.getElementById("containerP").scrollLeft += 20;
  }
  LeftButton() {
    document.getElementById("containerP").scrollLeft -= 20;
  }


  ngOnInit(): void {
  }

}
