import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Comment } from 'src/design-system/core/interfaces/comment';
import { ListItem } from 'src/design-system/core/interfaces/list-item';

@Component({
   selector: 'app-document',
   templateUrl: 'bootstrap/document.component.html',
   styleUrls: ['bootstrap/document.component.scss'],
})
export class DocumentComponent implements OnInit {
   currentTab: number = 0;
   step: number = 0;
   isSideBarOpened: boolean = false;
   moreDetailsModal: boolean = false;
   doUnlike: boolean = false;

   linkedDocs: { category: string; docs: ListItem[] }[] = [
      {
         category: 'Category 01',
         docs: [
            {
               id: 1,
               title: 'Document Name',
               image: '/assets/images/icon-word.svg',
               menuItems: [
                  {
                     label: 'Edit',
                     callback: function (id: number) {
                        console.log('Edited Item' + id);
                     },
                  },
                  {
                     label: 'Delete',
                     callback: function (id: number) {
                        console.log('Deleted Item' + id);
                     },
                  },
                  {
                     label: 'View',
                     callback: function (id: number) {
                        console.log('View Item' + id);
                     },
                  },
               ],
            },
            {
               id: 2,
               title: 'Document Name pdf',
               image: '/assets/images/icon-excel.svg',
               menuItems: [
                  {
                     label: 'View',
                     callback: function (id: number) {
                        console.log('View Item' + id);
                     },
                  },
               ],
            },
            {
               id: 3,
               title: 'Document Name pdf',
               image: '/assets/images/icon-pdf.svg',
               menuItems: [
                  {
                     label: 'Edit',
                     callback: function (id: number) {
                        console.log('Edited Item' + id);
                     },
                  },
                  {
                     label: 'View',
                     callback: function (id: number) {
                        console.log('View Item' + id);
                     },
                  },
               ],
            },
         ],
      },
      {
         category: 'Category 02',
         docs: [
            {
               id: 1,
               title: 'Document Name',
               image: '/assets/images/icon-excel.svg',
               menuItems: [
                  {
                     label: 'Edit',
                     callback: function (id: number) {
                        console.log('Edited Item' + id);
                     },
                  },
                  {
                     label: 'Delete',
                     callback: function (id: number) {
                        console.log('Deleted Item' + id);
                     },
                  },
                  {
                     label: 'View',
                     callback: function (id: number) {
                        console.log('View Item' + id);
                     },
                  },
               ],
            },
            {
               id: 3,
               title: 'Document Name pdf',
               image: '/assets/images/icon-pdf.svg',
               menuItems: [
                  {
                     label: 'Edit',
                     callback: function (id: number) {
                        console.log('Edited Item' + id);
                     },
                  },
                  {
                     label: 'View',
                     callback: function (id: number) {
                        console.log('View Item' + id);
                     },
                  },
               ],
            },
         ],
      },
   ];

   comments: Comment[] = [
      {
         id: 0,
         author: {
            id: 1,
            fname: 'Johne',
            lname: 'morgan',
            image: '/assets/images/user-1.png',
         },
         text: 'Ipsun text lorem dollar test',
         date: new Date('2022-10-23 23:24:00'),
      },
      {
         id: 1,
         author: {
            id: 1,
            fname: 'Johne',
            lname: 'morgan',
            image: '/assets/images/user-1.png',
         },
         text: 'Ipsun text lorem dollar test',
         date: new Date('2022-10-24 23:24:00'),
      },
      {
         id: 2,
         author: {
            id: 1,
            fname: 'Johne',
            lname: 'morgan',
            image: '/assets/images/user-2.png',
         },
         text: 'Text lorem text with test',
         date: new Date('2022-10-09 10:24:00'),
      },
      {
         id: 3,
         author: {
            id: 1,
            fname: 'Johne',
            lname: 'morgan',
            image: '/assets/images/user-3.png',
         },
         text: 'From test text dollar',
         date: new Date('2022-10-25 09:24:00'),
      },
      {
         id: 4,
         author: {
            id: 1,
            fname: 'Johne',
            lname: 'morgan',
            image: '/assets/images/user-3.png',
         },
         text: 'From test text dollar',
         date: new Date(),
      },
   ];
   constructor(private _location: Location) {}
   goback() {
      this._location.back();
   }
   onSend(event: any) {
      console.log(event);
   }
   ngOnInit(): void {
      let section = document.getElementById('lytcontent');
      if (section) {
         section.style.zIndex = '1030';
      }
   }
}
