import { OnlyGuestGuard } from './only-guest.guard';
import { OnlyLoggedGuard } from './only-logged.guard';


export const guards = [
    OnlyGuestGuard,
    OnlyLoggedGuard
];

export * from './only-guest.guard';
export * from './only-logged.guard';
