<div class="pl-20">
    <ng-container *ngIf="resultGroup==''">
        <div *ngFor="let group of searchResponseObservable | async">
             
            <div class="row list hide">
                <div class="col" *ngIf="group.data.length!=0">
                    <aura-table-row
                        [data]="group.data"
                        [columns]="group.columns"
                        [title]="group.label"
                        [disableHead]="true">
                    </aura-table-row>
                </div>
               
                
               
            </div>

            <div *ngIf="group.data.length!=0" class="row d-flex justify-content-center" style="cursor: pointer;" (click)="setGroup(group.id)">
                <p style="width: fit-content; font-weight: bold;">{{'showAllResults' | translate:{'group': group.label} }}</p>
            </div>
        </div>
       <!--  <div class="col" *ngIf="group.data.length == 0">
            <p>{{'No_results' | translate }}. . .</p>
        </div> -->
        <div *ngIf="allEmptyData && isLoading">
            <p>{{'Loading' | translate }}...</p>
        </div>
    </ng-container>
    <div   *ngIf="resultGroup=='' && documentData?.length == 0">
        <p>{{'No_results' | translate }}...</p>
    </div>

    <ng-container *ngIf="resultGroup!=''"> <!-- resultGroup :  string with name of group -->
        <div class="row list hide">
            <div class="col" *ngIf="selectedGroup!=undefined">
                <!-- selectedGroup :  group object with data etc -->
                <ng-container *ngIf="selectedGroup.data.length!=0">
                    <aura-table-row
                        [data]="selectedGroup.data"
                        [columns]="selectedGroup.columns"
                        [title]="selectedGroup.label"
                        [disableHead]="true">
                    </aura-table-row>
                </ng-container>

                <div *ngIf="isLoading">
                    <p>{{'Loading' | translate }}. . .</p>
                </div>

                <div *ngIf="selectedGroup.data.length==0 && !isLoading">
                    <p>{{'No_results' | translate }}. . .</p>
                </div>
            </div>

            <div class="col" *ngIf="selectedGroup==undefined">
                <div *ngIf="isLoading">
                    <p>{{'Loading' | translate }}. . .</p>
                </div>

                <div *ngIf="!isLoading">
                    <p>{{'No_results' | translate }}. . .</p>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #myTemRef let-flag="flag">
    <span class="tag {{ flag.classFlag }}" [matTooltip]="flag.tooltipFlag">{{
      flag.labelFlag
    }}</span
    ><span
      *ngIf="flag.countFlags"
      [matTooltip]="flag.tooltipFlag"
      class="tag tag-plus"
      >{{ flag.countFlags }}</span
    >
  </ng-template>




