export class FolderMeta {
    key : string;
    value;
    type;
    typemain : {value:'',html:''};
    nullable : boolean;
    required : boolean;
    label : string;
    auto;
    autoValue: {}
    desc:{}

    constructor(key,data,metadata)
    {
      this.desc = metadata;

      this.setKey(key)
      this.setLabel(metadata)
      this.setAuto(metadata)

      this.setType(metadata)
      this.setNullable(metadata)
      this.setRequired(metadata)
      this.setValue(data)
    }

    getKey() {
      return this.key;
    }
    getName() {
      return this.key;
    }
    getValue() {
      return this.typemain;
    }
    getTypemain() {
      return this.type;
    }
    getType() {
        return this.type;
      }
    getNullable() {
      return this.nullable;
    }
    getRequired() {
      return this.required;
    }
    getLabel() {
      return this.label;
    }
    getAuto() {
      return this.auto;
    }
    getAutovalue(){
        return this.autoValue
    }

    isHidden() {
      return this.desc["x-show"]==false || this.desc["show"]==false || this.key=="driveId";
    }

    setKey(key) {
      this.key = key;
    }
    
    setRequired(metadata){
      this.required = metadata?.required || false
    }

    setValue(data) {

      if(data = 'undefined'){
        this.value = "" ;
      }
      else
      this.value = data

    }

    setType(metadata) {
      let type = metadata?.["type"];

      if(type=="integer")
        type="number";

      if(!!this.auto)
        type="string";

      this.type = type;
    }

    setTypemain() {
      this.typemain =  {value:'',html:''};
    }

    setNullable(metadata){
        this.nullable = metadata?.nullable || false
    }

    setLabel(metadata){
        this.label = metadata?.label
    }

    setAuto(metadata){
      this.auto = metadata["x-dynamic-values"] || null
    }

    setAutoValue(){
      this.autoValue = {}
    }
}
