import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchRoutingModule } from './search-routing.module';
import { MaterialModule } from '../material/material.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { SpacesModule } from '../spaces/spaces.module';
import * as searchContainers from './containers'
import { DocumentsModule } from '../documents/documents.module';


@NgModule({
  declarations: [...searchContainers.containers],
  imports: [
    AppCommonModule,
    CommonModule,
    SearchRoutingModule,
    MaterialModule,
    DocumentsModule,
    SpacesModule
  ],
  exports: [SearchRoutingModule , ...searchContainers.containers]
})
export class SearchModule { }
