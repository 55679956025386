<app-sidenav title="{{'searchBar' | translate}}"
  [breadcrumb]="bc">
    <div class="background-container">
        <div>
            <!-- engine groups selector tabs-->
            <app-search-top [searchResponse]="searchResponse" [resultGroup]="resultGroup" [keyword]="keyword"></app-search-top>
        </div>

        <div class="main-div">
            <!-- results -->
            <app-search-main [searchResponse]="searchResponse" [resultGroup]="resultGroup" [keyword]="keyword">
                <div>
                </div>
                <p>number of results</p>
            </app-search-main>
        </div>
    </div>
</app-sidenav>


