import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ListItem } from "@interfaces/list-item";
import { TranslateService } from "@ngx-translate/core";
import { Crumb } from "src/modules/app-common/components/bread-crumb/bread-crumb.component";
import { BreadcrumbService } from "xng-breadcrumb";
import { Spacegroup } from "../../models/group-space/spacegroup.model";
import { Spacetype } from "../../models/type-space/spacetype.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-space-groups",
  templateUrl: "./space-groups.component.html",
  styleUrls: ["./space-groups.component.scss"],
})
export class SpaceGroupsComponent implements OnInit {
  ListeGroups: Spacegroup[] = [];
  displayGroups: ListItem[] = [];
  isOpen: boolean = false;
  listeSpaces: Spacetype[] = [];
  activeTab: string = "grid";
  bc: Crumb[] = [];

  canAddSpace = true;

  spaceNewModal: boolean = false;

  static instance: SpaceGroupsComponent;

  constructor(
    private titleService: Title,
    private router: Router,
    private translate: TranslateService,
    private breadcrumbService: BreadcrumbService
  )
  {
    SpaceGroupsComponent.instance = this;

    this.canAddSpace = environment.SPACE_ADMIN;
  }

  async ngOnInit(): Promise<void> {

    await this.translate.get("Spaces").toPromise();

    this.titleService.setTitle(this._("Spaces"));
    this.breadcrumbService.set("@Groups", " ");
    this.setBC();

  }

  _(s: string): string 
  {
    return this.translate.instant(s);
  }

  setBC() 
  {
    this.bc = [];

    let groups = this._("Spaces");
    let _home =  environment.stringsFile.homeSideNav;

    this.bc.push(
      new Crumb(_home, (event, self) => 
      {
       this.router.navigate(["workspace/main"])
      })
    );

    this.bc.push(new Crumb(groups, (event, self) => {}));
  }

  async asyncBC() 
  {
    return this.bc;
  }

  getData(data) 
  {
    this.ListeGroups = [];
    this.listeSpaces = [];

    let groups = data;
    if (groups["groups"]) {
      groups["groups"].forEach((group) => {
        this.ListeGroups.push(new Spacegroup(group));
      });
    }

    if (groups["spaces"]) {
    }
    groups["spaces"].forEach((space) => {
      this.listeSpaces.push(new Spacetype(space));
    });

    if (groups["models"]) {
      groups["models"].forEach((group) => {
        this.ListeGroups.push(new Spacegroup(group));
      });
    }


    this.alphabeticalOrder(this.listeSpaces);
    this.setDisplaySpaces(this.listeSpaces);
    this.setDisplayProperties(this.ListeGroups);
  }

  setDisplayProperties(listGroups)
  {
    this.displayGroups = [];
    listGroups.forEach((spaceGroup) => {
      this.displayGroups.push(spaceGroup["displayProperties"][0]);
    });
  }

  displaySpaces: ListItem[] = [];
  setDisplaySpaces(results)
  {
    this.displaySpaces = [];

    Object.values(results).forEach((item) => {
      this.displaySpaces.push(item["displayProperties"][0]);
    });
  }

  async alphabeticalOrder(array)
  {
    return array.sort((a, b) => (a['name'] < b['name'] ? -1 : 1));
  }

  toggleView(view: string)
  {
    this.activeTab = view;
  }

  open() {
    this.isOpen = true;
  }
  close() {
    this.isOpen = false;
  }

  openGroupe(name) {
    this.router.navigate(["/s/space/groups/" + name]);
  }
}
