import { DatePipe } from '@angular/common';
import { Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatStepper } from '@angular/material/stepper';
import { environment } from 'src/environments/environment';
import { GedService } from 'src/modules/app-common/services';
import { OneData } from '../../models/one-data/one-data.model';
const accent = '#85BFF2'
const primary = '#3f51b5'

const themePath = environment.themePath;

@Component({
  selector: 'app-doc-workflow-metadata',
  templateUrl: `./${themePath}/doc-workflow-metadata.component.html`,
  styleUrls: [`./${themePath}/doc-workflow-metadata.component.scss`]
})
export class DocWorkflowMetadataComponent implements OnInit, OnChanges
{
  @Input('path') path;
  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  @ViewChild("stepper") public stepper: MatStepper;
  currentTask: {};

  showContent = true;
  oneData: OneData;
  allData = [];
  meta: {};
  doc: {};

  ngOnInit(): void {
    this.getDoc();
  }

  currentDocId = localStorage.docId;

  isRoleUser = true;
  pending: any;
  workflow = null;
  showWorkflow = true;
  showState = true;
  gedDoc
  @Input() set _gedDoc(value) {
    this.gedDoc = value;
  }

  constructor(private gedService: GedService,
    private _formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe)
  {
    this.currentDocId = localStorage.docId;

    if (!!this.stepper)
    {
      this.stepper.selectedIndex = 1;
    }

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.getDoc();
  }
  ngOnChanges(changes: SimpleChanges): void
  {
    //  this.getDoc()
  }

  getDoc()
  {
    if (this.gedDoc != undefined)
    {
      this.doc = this.gedDoc.content.data;
      this.getBaseMeta(this.path, this.currentDocId);
    }
  }

  /*-------------------------------------------------THE ON EVENTS ------------------------------------------------------------*/

  isLoading = true;
  isLoadingState = true;

  splitWord(word, intru)
  {
    var i;
    if (word.includes(intru))
    {
      i = word.indexOf(intru);
    }

    let newWord = word.slice(i + 1)
    if (newWord[0] == " ")
    {
      newWord = newWord.slice(1);
    }

    return newWord;
  }

  getBaseMeta(path, currentDocId)
  {
    this.isLoadingState = false;
    let document = this.gedDoc;
    //GET METADATA JSON
    this.meta = document?.['content'].metadata.fields;

    //GET METADATA JSON
    for (var key of Object.keys(this.meta))
    {
      //SELECT METADATA WITH TAG = META
      if (!!this.meta[key]["x-tags"])
      {
        if (this.meta[key]["x-tags"] == "base_meta" || this.meta[key]["x-tags"].includes("base_meta"))
        {
          let label = this.meta[key].label;
          this.oneData = new OneData(key, label);

          if (this.doc[key] instanceof Object == true)
          {
            this.oneData.val = this.doc[key].html ;
          }
          else
          {
            this.oneData.val = this.doc[key] ;
          }
          if (this.meta[key].type == 'string')
          {
            this.oneData.type = 'text' ;
          }
          if (this.meta[key].type == 'integer')
          {
            this.oneData.type = 'number';
          }

          if (this.meta[key].type == 'date')
          {
            this.oneData.type = 'date' ;

            this.oneData.val = this.datepipe.transform(this.doc[key], 'dd/MM/yyyy') ;
          }

          if (!!this.meta[key]["x-ui"])
          {
            this.oneData.bgc = this.meta[key]["x-ui"]["color"];

            if (this.meta[key]["x-ui"]["class"] == 'md-accent')
            {
              this.oneData.bgc = accent;
            }

            if (this.meta[key]["x-ui"]["class"] == 'md-primary')
            {
              this.oneData.bgc = primary;
            }

            this.oneData.color = 'white';
          }

          this.allData.push(this.oneData);
        }
      }
    }

    if (this.allData.length == 0)
    {
      this.showState = false;
    }
    else
    {
      this.showState = true;
    }
  }

  showActions = true;

  doSomething(showActions)
  {
    this.showActions = showActions;
  }
}
