import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ListItem } from 'src/design-system/core/interfaces/list-item';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
   selector: 'app-spaces',
   templateUrl: 'bootstrap/spaces.component.html',
   styleUrls: ['bootstrap/spaces.component.scss'],
})
export class SpacesComponent implements OnInit {
   isOpen: boolean = false;

   activeTab: string = 'grid';
   departments: ListItem[] = [
      {
         id: 1,
         title: 'Marketing',
         redirectTo: '/contracts/1',
         text: 'Lorem ipsum',
         image: '/assets/images/icon-folder.svg',
         menuItems: [
            {
               label: 'Edit',
               callback: function (id: number) {
                  console.log('Edited Item' + id);
               },
            },
            {
               label: 'Delete',
               callback: function (id: number) {
                  console.log('Deleted Item' + id);
               },
            },
            {
               label: 'View',
               callback: function (id: number) {
                  console.log('View Item' + id);
               },
            },
         ],
      },
      {
         id: 2,
         title: 'Project',
         redirectTo: '/contracts/2',
         text: 'Lorem ipsum',
         image: '/assets/images/icon-folder.svg',
         menuItems: [
            {
               label: 'Edit',
               callback: function (id: number) {
                  console.log('Edited Item' + id);
               },
            },
            {
               label: 'Delete',
               callback: function (id: number) {
                  console.log('Deleted Item' + id);
               },
            },
            {
               label: 'View',
               callback: function (id: number) {
                  console.log('View Item' + id);
               },
            },
         ],
      },
      {
         id: 3,
         title: 'Clients',
         redirectTo: '/contracts/3',
         text: 'Lorem ipsum',
         image: '/assets/images/icon-folder.svg',
         menuItems: [
            {
               label: 'Edit',
               callback: function (id: number) {
                  console.log('Edited Item' + id);
               },
            },
            {
               label: 'Delete',
               callback: function (id: number) {
                  console.log('Deleted Item' + id);
               },
            },
            {
               label: 'View',
               callback: function (id: number) {
                  console.log('View Item' + id);
               },
            },
         ],
      },
      {
         id: 4,
         title: 'Sales',
         redirectTo: '/contracts/4',
         text: 'Lorem ipsum',
         image: '/assets/images/icon-folder.svg',
         menuItems: [
            {
               label: 'Edit',
               callback: function (id: number) {
                  console.log('Edited Item' + id);
               },
            },
            {
               label: 'Delete',
               callback: function (id: number) {
                  console.log('Deleted Item' + id);
               },
            },
            {
               label: 'View',
               callback: function (id: number) {
                  console.log('View Item' + id);
               },
            },
         ],
      },
      {
         id: 5,
         title: 'Proposal',
         redirectTo: '/contracts/5',
         text: 'Lorem ipsum',
         image: '/assets/images/icon-folder.svg',
         menuItems: [
            {
               label: 'Edit',
               callback: function (id: number) {
                  console.log('Edited Item' + id);
               },
            },
            {
               label: 'Delete',
               callback: function (id: number) {
                  console.log('Deleted Item' + id);
               },
            },
            {
               label: 'View',
               callback: function (id: number) {
                  console.log('View Item' + id);
               },
            },
         ],
      },
   ];
   constructor(
      private breadcrumbService: BreadcrumbService,
      private titleService: Title,
      private _location: Location
   ) {
      this.titleService.setTitle('Spaces');
   }
   goback() {
      this._location.back();
   }
   ngOnInit(): void {
      this.breadcrumbService.set('@Spaces', 'Spaces');
      this.breadcrumbService.set('@Groups', 'Groups');
   }
   toggleView(view: string) {
      this.activeTab = view;
   }

   open() {
      this.isOpen = true;
   }
   close() {
      this.isOpen = false;
   }
}
