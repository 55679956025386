import { GcsItem } from "./gcs-item";

export class GcsGroup extends GcsItem{

    constructor(gcsItem){
        super(gcsItem);
    }

    setIconLink(gcsItem) {
        this.iconLink = '/assets/google_icons/sites_icon.svg';
    }
}