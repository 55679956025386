import { FieldDesc } from "../field-desc/field-desc.model";

export class FormField
{
  private _desc: FieldDesc;
  private _data: any;


	constructor(data,desc) {
		this._desc = desc;
		this.set(data);
	}

	desc() {
		return this._desc;
	}

	data() {
		return this._data;
	}

	set(v) {
		this._data = v;
		return this._data;
	}

	update(v) {
		this._data = v;
		return this._data;
	}

	// to be used by record.fields(tags,true,'name','field')
	field() {
		return this;
	}

	value(native:false) {
		if(this._data===null)
			return null;

		return this._data.value || this._data;
	}

	toString() {
		if(this._data===null || typeof this._data == "undefined")
			return '';
		return this._data.html || this._data.value || this._data;
	}

	forEachTag(cb)
  {
		const tags = this._desc.tags;
		for (let i=0; i<tags.length; i++)
		{
			const tag = tags[i];
			cb(tag,this);
		}
	}
}
