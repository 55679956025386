
import { SpaceService } from "src/modules/spaces/services";
import { DocLinksComponent } from "../../components";
import { DocumentService } from "../../services/document.service";
import { LinkData } from "./link-data.model";

export class Link
{
  title: String;
  data: LinkData;
  add = [];
  name: String;
  obj: any[];
  displayProperties;
  relativeUrl;

  constructor(doc)
  {
    this.setTitle(doc);
    this.setData(doc)
    this.setAdd(doc)
    this.setName(doc)
    this.getDisplayProperties();
    this.getRelativeUrl()
  }

  getDisplayProperties()
  {
    var self = this;
    this.displayProperties =
    [
      {
        category: this.getName(),
        docs: this.getDocs(this.getData()),
      }
    ];

    return this.displayProperties;
  }

  getTitle()
  {
    return this.title;
  }

  getData()
  {
    return this.data;
  }

  getAdd()
  {
    return this.add;
  }

  getName() {
    return this.name;
  }

  getRelativeUrl()
  {
    this.relativeUrl  = DocLinksComponent.instance.getUrlRelative()
     return this.relativeUrl;
  }

  setTitle(doc)
  {
     this.title = doc.title;
  }

  setData(doc)
  {
    this.data = doc.data;
  }

  setName(doc)
  {
    this.name = doc.name;
  }

  setAdd(doc)
  {
    this.add = doc?.add;
  }

  listeDocs = [];
  getDocs(data)
  {
    (data || [])?.forEach(doc =>
      {
        const title = doc?.["name"] || doc?.["title"] || doc?.["reference"];

        this.listeDocs.push(
        {
          id: doc?.["uid"],
          title,
          image:  DocumentService.instance.getIconbyMimeType(doc?.["icon_link"]),
          redirectTo: this.getRelativeUrl() +"/file/" + doc.uid,
          menuItems:
          [
            {
              label: 'Ouvrir',
              callback: function () {

                DocLinksComponent.instance.toLink(doc)

              },
            },

          ],
        });

    })

    return this.listeDocs;
  }

}
