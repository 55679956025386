<div id="login" ng-controller="LoginCtrl" class="container">
  <div class="row justify-content-center">
    <mat-card class="login-card">
      <mat-card-header>
        <mat-card-title>
          <img src="/assets/images/logo-wt.svg#light" class="logo">
        </mat-card-title>
      </mat-card-header>


      <mat-card-content>
        <lib-google-link classes="g-auth-btn"></lib-google-link>
      </mat-card-content>
    </mat-card>
  </div>
</div>