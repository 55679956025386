import { Injectable } from '@angular/core';
import { search_config } from '../../../environments/config/search_config';
import * as engines from "src/modules/engines/models/index";
import * as engineServices from "src/modules/engines/services/index";

@Injectable({
  providedIn: 'root'
})
export class SearchEngineInitializerService {
  constructor() {
  }

  currentUser;
  searchEngineObjects = []
  clientContexts = [];

  async getEngines(
    categoryLabel = '',
    withContext = false
  ) {
    let engineList = search_config.engineList.filter(e => e.active == true);
    // console.log("searchEngineObjects",this.searchEngineObjects)

    // init search engines
    if (withContext == false) {
      // this.searchEngineObjects = engineList.map(engine=>engine.e)
      //need to specify each engine
      this.searchEngineObjects = engineList
        .map(e => {
          let handlerName = e?.handlerName;
          let serviceName = e?.serviceName;
          let instance = engineServices[serviceName].instance;

          return new engines[handlerName](instance);
        });
    }

    if (withContext == true) {
      // if(withContext) await this.setContexts();
      this.searchEngineObjects = engineList
        .map(e => {
          let handlerName = e?.handlerName;
          let serviceName = e?.serviceName;
          let context = this.clientContexts.find(item => item.engine == e.e).context;
          let instance = engineServices[serviceName].instance;

          return new engines[handlerName](instance, context);
        });
    }

    // group them by category
    if (categoryLabel) {
      let category = search_config.categories.find(cat => cat.id == categoryLabel);

      // list of active search engines
      let engineNames = category.engines;

      // filters requested engine among supported handlers
      this.searchEngineObjects = this.searchEngineObjects
        .filter(e => engineNames.includes(e.getEngine()));

      // console.log("engines", this.searchEngineObjects)
    }

    return this.searchEngineObjects;
  }
}
