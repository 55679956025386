import { Action } from "../action/action.model";
import { Document } from "../document/document.model";

export class WorkflowFileAction {
    private _action: Action;
    private _fileGed : any;


	constructor(fileGed,id,action) {
		this._action = {id, ...action};
		this._fileGed = fileGed;
	}

	static map = {
		primary : 'md-default bg_primary',
		default : 'md-default',
		warning : 'md-default',
		alert : 'md-default',
	}

	name() { return this._action.name; }
	id() { return this._action.id; }
	role() { return this._action.role; }
	dialog() { return this._action.dialog; }
	button_class() { return WorkflowFileAction.map[this._action.button_class] || 'default'; }

	hasDialog() {
		return this._action.dialog;
	}

}
