import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TasksListComponent } from './containers/tasks-list/tasks-list.component';
import { DocumentPageComponent } from '../documents/containers/document-page/document-page.component';
import { TaskTableComponent } from './containers/tasks-list/task-table/task-table.component';
import { TaskGridComponent } from './containers/tasks-list/task-grid/task-grid.component';

const routes: Routes =
  [
    {
      path: 'all',
      component: TasksListComponent,
      data: {
        title: environment.stringsFile.taskPage,
      }
    },
    {
      path: "all/view/table",
      component: TaskTableComponent,
      data:{ title: environment.stringsFile.tasksTitle}
    },
    /*{
      path: "all/view/grid",
      component: TaskGridComponent
    },*/

    {
      path: "all/view/:type/file/:docId",
      component: DocumentPageComponent
    },
    {
      path: "all/view/:type/:flagged/file/:docId",
      component: DocumentPageComponent
    },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksRoutingModule { }
