export const gcs_config = {
    datasources_item_config: [
        {
            ds: 'GOOGLE_DRIVE',
            handlerName: 'GcsGdrive'
        },
        {
            ds: 'GOOGLE_GROUPS',
            handlerName: 'GcsGroup'
        },
        {
            ds: 'GOOGLE_GMAIL',
            handlerName: 'GcsGmail'
        },
        {
            ds: 'GOOGLE_CALENDAR',
            handlerName: 'GcsCalendar'
        },
        {
            ds: 'GOOGLE_SITES',
            handlerName: 'GcsSite'
        },
        {
            ds: 'datasources/5d42c86b1e39a83932077adc8d6317ac',
            handlerName: 'GcsTangram'
        },
        {
            ds: 'datasources/5d42c86b1e39a839e9fa3a5d02609824',
            handlerName: 'GcsWorkplace'
        },
        {
            ds: 'datasources/5d42c86b1e39a839c5345d548d607de9',
            handlerName: 'GcsPerson'
        },
        {
            ds: 'datasources/5d42c86b1e39a839fc5391535b96b3b2',
            handlerName: 'GcsTangram'
        }
    ]
}

