import { FieldDesc } from "./field-desc.model";

/**
 * FieldDescEnum is a subclass of FieldDesc that represents an enum field descriptor.
 */
export class FieldDescEnum extends FieldDesc
{
      enumValues: {
      data: any[];
      enum?: any;
      url?: string;
      "field-title"?: string;
      fieldValue?: string;
      query?: any;

    };

    selectValues: {}; // static list of enums (to be removed?)
    fieldTitle: string; // name of label in options (default: html)
    typeEnum?: string;
    typedValues : string = '';
  /**
   * Constructor for enum field descriptor
   * @param desc metadata descriptor for field
   * @param fname field name / id
   *
   */
    constructor(desc: any, fname: string) {
      super(desc, fname);

      if (desc["x-values"]) {
        this.selectValues = desc["x-values"];
        this.enumValues = { data: desc["x-values"] };
      }

      if (desc["x-dynamic-values"]) {
        this.enumValues = desc["x-dynamic-values"];
        this.fieldTitle = this.enumValues["field-title"] || "html";
      } else if (desc["enum"]) {
        const listItems = Object.keys(desc["enum"]).map((key) => ({
          html: desc["enum"][key as keyof typeof desc["enum"]],
          value: key,
        }));

        this.enumValues = { data: listItems };
        this.fieldTitle = "html";
      } else {
        this.fieldTitle = "html";
      }


    }


    /**
     * Checks if the field is an enum field.
     * @returns true if it is an enum field, false otherwise.
     *
     */
    // @ts-ignore
    isEnum(): boolean {
      return true;
    }

    /**
     * Returns the list of enum values.
     * @returns an array of enum values.
     */
    // @ts-ignore
  enums(isAuraInput = false, isSelect = false) {
    if (isAuraInput) {
      let data = [];
      if (isSelect) {
        data = this.enumValues?.data?.map(({ html, value }) => ({
          label: html, value: typeof value === 'number' ? value.toString() : value, // Conversion de number en string si nécessaire          
        }));

      } else {
        data = this.enumValues?.data?.map(({ html, value }) => ({
          label: html,  value: value
        }));
      }

      return data || [];

    }
    return this.enumValues.data|| [];
  }

    /**
     * Retrieves the enum object for the given value.
     * @param v - the value to match.
     * @returns the enum object if found, null otherwise.
     */
    // @ts-ignore
    getEnum(v: any) {
      let len = this.enumValues?.data?.length;
      for (let i = 0; i < len; i++) {
        if (this.enumValues.data[i].value == v) {
          return this.enumValues.data[i];
        }
      }

      return null;
    }


    /**
     * Retrieves the label of the enum value.
     * @param v - the value to match.
     * @returns the label of the enum value if found, null otherwise.
     */
    // @ts-ignore
    getEnumLabel(v: any) {
      let e = this.getEnum(v);

      if (e !== null && e.hasOwnProperty(this.fieldTitle)) {
        return e[this.fieldTitle];
      }

      return null;
    }


    /**
     * Updates the list of enum values, if enums are a set of strings, transform them to {value,html}.
     * @param data - an array of new enum values.
     */
    // @ts-ignore
    updateEnumList(data: any[]) {
      if (data.length) {
        let first = data[0];
        if (typeof first === "string") {
          data = data.map((value) => ({ value, html: value }));
        }
      }

      if (this.selectValues) {
        // obsolete?
        this.selectValues = { ...this.selectValues, data };
      }

      this.enumValues = { ...this.enumValues, data };
    }

    setValues(field){

      const filteredObjects = this.enums().filter(obj => {
        const objValue = obj.value.toString(); // Assurez-vous que la valeur de l'objet est une chaîne de caractères
        return field.value().split(',').some(value => value === objValue);
      });
      const htmls = filteredObjects.map(obj => obj.html);
      if(htmls)
      this.typedValues = htmls.join(', ')
      /*const filteredObjects = this.enums().filter(obj => obj.value === num);
    const htmls = filteredObjects.map(obj => obj.html);
    return htmls.join(', ');*/

    }

    /**
     * Sets the field data for the enum values.
     * @param data - an array of new enum values.
     */
    setFieldData(data: any) {
      this.updateEnumList(data);
    }

    /**
     * Checks if the enum field must be preloaded.
     * @returns true if the field control is "select" and there is a URL specified in enumValues, false otherwise.
     */
    // @ts-ignore
    mustPreload(): boolean {
      return (
        this._control == "select" &&
        this.enumValues &&
        this.enumValues.url != null
      );
    }

    /**
      * Retrieves the query parameters for the enum field.
      * @returns the query parameters specified in enumValues or null if not available.
      */
     // @ts-ignore
    queryParams(): any {
      return this.enumValues.query || null;
    }

    /**
      * Retrieves the URL for the enum field.
      * @returns the URL specified in enumValues or null if not available.
      */
     // @ts-ignore
    getUrl()  {
      return this.enumValues.url || 'h';
    }
  }
