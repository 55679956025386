<div class="row mb-40 pt-20 pl-20">
    <div class="col-4 col-md-2" style="width: fit-content;">
        <ng-container *ngIf="resultGroup==''">
            <button auraButton  width="120" label="{{'All' | translate}}" [hasShadow]="true"
                (click)="unselectGroup()" type="primary"></button>
        </ng-container>
        <ng-container *ngIf="resultGroup!=''">
            <button auraButton  width="120" label="{{'All' | translate}}" [hasShadow]="true" (click)="unselectGroup()" type="light"></button>
        </ng-container>
    </div>

    <div *ngFor="let group of categories" class="col-4 col-md-2" style="width: fit-content;">
        <ng-container *ngIf="resultGroup==group.id">
            <button auraButton  width="120" [label]="group.label" [hasShadow]="true" (click)="setGroup(group.id)" type="primary"></button>
        </ng-container>
        <ng-container *ngIf="resultGroup!=group.id">
            <button auraButton  width="120" [label]="group.label"
                [hasShadow]="true" (click)="setGroup(group.id)" type="light"></button>
        </ng-container>
    </div>

    <div class="col-1 col-md-1" *ngIf="resultGroup!=''">
    </div>

    <div class="col-4 col-md-2" *ngIf="resultGroup!='' && (resultGroup=='drive' || resultGroup=='spaces')" style="width: fit-content;">
        <button auraButton  width="120"
            icon="bi bi-sliders"
            [hasShadow]="true" label="{{'All filters' | translate}}"
            (click)="openFacetsPanel()"
            type="light"
            ></button>

    </div>
</div>

<!-- RIGHT SIDE PANEL : FACETS -->

<div class="right-aside" [ngClass]="isToggled? 'on' : ''">
    <div class="wrap">
        <ul class="list-group">
            <li class="list-group-item list-group-item-no-border">
                <div class="row facets-title-div">
                    <p class="facets-title">{{'filtersTitle' | translate}}</p>
                    <div class="ps-0 d-flex justify-content-end ms-auto me-0" style="width: fit-content;">
                        <button type="button" class="btn-close ng-star-inserted" (click)="closePanel()"></button>
                    </div>
                </div>

                <app-search-facets
                    [facets]="selectedGroup?.facets"
                    [keyword]="keyword"
                    [group]="selectedGroup?.id || resultGroup"
                    (emitState)="searchFacetsEmit($event)"></app-search-facets>

            </li>
        </ul>

    </div>
</div>
<div class="overlay" (click)="toggleRightSidebar()"></div>
