import { RecentContact } from "./recent-contact";
import { RecentDocument } from "./recent-document.model";
// import { Event } from "../models/event/event.model";
import { GcalEvent } from "./event/gcal-event.model";
import { RecentFile } from "./recent-file.model";


export class Workspace
{
    recentContacts = [];
    recentFiles = [];
    recentDocs = [];
    filesWithActions = [];
    events = [];
    withEvents:boolean;
    withRecentContacts:boolean;

    constructor(workspaceResponse,withEvents=true,withRecentContacts=false)
    {
      this.withEvents = withEvents;
      this.withRecentContacts = withRecentContacts;

      this.setRecentFiles(workspaceResponse);
      this.setRecentDocs(workspaceResponse);
      this.setFilesWithActions(workspaceResponse);

      if(this.withRecentContacts)
        this.setRecentContacts(workspaceResponse);

      if(this.withEvents)
        this.setEvents(workspaceResponse);
    }

    recentContactsEnabled() {
      return this.withRecentContacts;
    }

    eventsEnabled() {
      return this.withEvents;
    }

    setRecentContacts(workspaceResponse)
    {
        if(workspaceResponse)
        {
            let recentContactsResponse = workspaceResponse['people'] || []

            this.recentContacts = recentContactsResponse.map(recentContact=> new RecentContact(recentContact))
        }
    }

    setRecentFiles(workspaceResponse)
    {
        if(workspaceResponse)
        {
            let recentFilesResponse = workspaceResponse['recentFiles'] || []
            this.recentFiles = recentFilesResponse
              .filter(file => !file.properties)
              .map(file => new RecentFile(file))
        }
    }

    setRecentDocs(workspaceResponse)
    {
        if(workspaceResponse)
        {
          // get files with Meta (NB. to get old files without appProps)
          let recentFilesResponse = workspaceResponse['recentFiles'] || []
          let recentFilesWithMeta = recentFilesResponse
            .filter(file => file.properties)
            .map(file => new RecentFile(file))

          // get PDocs files (with has_meta appProperty)
          let recentDocsResponse = workspaceResponse['recentDocs'] || []
          this.recentDocs = [... recentDocsResponse.map(file => new RecentDocument(file)),
            ...recentFilesWithMeta];
        }
    }

    setFilesWithActions(workspaceResponse)
    {
        if(workspaceResponse)
        {
            let filesWithActionsResponse = workspaceResponse['filesWithActions'] || []
            this.filesWithActions = filesWithActionsResponse.map(fileAction => new RecentFile(fileAction))
        }
    }

    setEvents(workspaceResponse)
    {
        if(workspaceResponse)
        {
            let eventsResponse = workspaceResponse['events'] || []
            this.events = eventsResponse.map(event => new GcalEvent(event))
        }
    }

    getRecentContacts(){
        return this.recentContacts;
    }
    getRecentFiles(){
        return this.recentFiles;
    }

    getRecentDocs(){
      return this.recentDocs;
    }
    getFilesWithActions(){
        return this.filesWithActions;
    }
    getEvents(){
        return this.events;
    }
}
