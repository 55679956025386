import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APISce } from 'src/modules/app-common/services';
import { GcalEvent } from '../models/event/gcal-event.model';

const GCALENDAR_API = environment.GCALENDAR_API;
@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private api: APISce) { }

  acceptStatus = environment.stringsFile.acceptStatus;

  declineStatus = environment.stringsFile.declineStatus;
  maybeStatus = environment.stringsFile.maybeStatus;
  allStatus = environment.stringsFile.allStatus;

  async setMeetingResponse(event: GcalEvent, response: string, calendarId = "primary") {

    let eventAnswer = this.getResponseStatus(response);
    let eventId = event.getId();


    let url = `${GCALENDAR_API}/${calendarId}/events/${eventId}/${eventAnswer}`;
    let options = this.api.httpConfig() // {} //this.googleLoginService.httpConfig(); // to be seen after

    let updatedEventResponse = await this.api.put(url, null, options)

    let updatedEvent = updatedEventResponse['content'] || null;
    event = new GcalEvent(updatedEvent);

    return event;

  }


  async setMeetingResponseListAdvanced(event: GcalEvent, response: string, calendarId = "primary") {

    let eventAnswer = response;
    let eventId = event.getId();


    let url = `${GCALENDAR_API}/${calendarId}/events/${eventId}/${eventAnswer}`;
    let options = this.api.httpConfig() //{} //this.googleLoginService.httpConfig();

    let updatedEventResponse = await this.api.put(url, null, options)

    let updatedEvent = updatedEventResponse['content'] || null;
    event = new GcalEvent(updatedEvent);
    return event;

  }

  meetingOptions = [this.acceptStatus, this.declineStatus, this.maybeStatus, this.allStatus]
  getResponseStatus(response) {
    if (response == this.allStatus) return "needsAction";
    if (response == this.acceptStatus) return "accepted";
    if (response == this.declineStatus) return "declined";
    if (response == this.maybeStatus) return "tentative";

  }
}
