import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { InfosStepTask, Workflow, WorkflowAction } from 'src/modules/workflow/models';
import { WorkflowService } from 'src/modules/workflow/services';
//import  AvatarInitials  from 'avatar-initials';

// Constantes pour les couleurs et les chemins
const primary = "#245E99";
const white = "#FFFFFF";
const black = "#212121";
const themePath = environment.themePath;

@Component({
  selector: 'app-doc-list-approval-activity',
  templateUrl: './doc-list-approval-activity.component.html',
  styleUrls: ['./doc-list-approval-activity.component.scss']
})
export class DocListApprovalActivityComponent implements OnInit {

  @Input("path") path;
  @Input() set _gedDoc(value) {
    this.gedDoc = value;
  }

  @Output() emitState: EventEmitter<any> = new EventEmitter();

  // Propriétés du composant
  currentDocId: string;
  doc;
  actions: WorkflowAction[] = [];
  isLoading = true;
  showActions = true;
  description: string;
  titleOfActions: string;
  showFirstModel: boolean;
  showSecondModel: boolean;
  noModelExist:boolean = true;
  dataState: Workflow;
  userAvatar: string;
  icon: string;
  gedDoc;
  backgroundColor = '#66BD7A';
  textColor = '#006F43';
  actionExist : boolean = true;
  
  




  constructor(private workflowService: WorkflowService, private route: ActivatedRoute) { }

  /**
   * Initialise le composant
   */
  ngOnInit(): void {
    // Récupère et définit l'ID du document à partir des paramètres de la route
    this.route.params.subscribe(params => {
      let id = params["docId"];
      this.currentDocId = id;
      this.getDoc();
    });

  }

  /**
   * Fonction déclenchée lors du clic sur un bouton
   */
  onButtonClick() {
    // Ajoutez la logique à exécuter lors du clic sur le bouton
    console.log('Bouton cliqué !');
  }

  /**
   * Fonction déclenchée lors de changements dans les propriétés d'entrée
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gedDoc) {
      this.gedDoc = changes.gedDoc.currentValue;
      this.getDoc();
    }
  }

  /**
   * Récupère les détails du document en fonction des données fournies
   */
  getDoc() {
    if (this.gedDoc != undefined && this.currentDocId != undefined) {
      this.doc = this.gedDoc.content.data;
      this.getActions(this.path, this.currentDocId);
      this.getWorkflow(this.currentDocId);
    }
  }

  /**
   * Récupère de manière asynchrone le flux de travail en fonction de l'ID du document
   * @param currentDocId - ID actuel du document
   */
  async getWorkflow(currentDocId: string) {
    this.workflowService
      .getsteps(currentDocId)
      .then((data) => {
        this.dataState = data.content;
        this.noModelExist = false; 
        if (this.dataState?.tasks?.length == 0 || !this.actionExist) {
          this.showFirstModel = true; 
          this.showSecondModel = false;
        } else {
          this.showFirstModel = false;
          this.showSecondModel = true;
        }
      });
  }

  /**
   * Récupère les actions liées au document et met à jour les propriétés du composant en conséquence
   * @param path - Chemin d'accès
   * @param currentDocId - ID actuel du document
   */
  getActions(path:string, currentDocId: string) {
    this.workflowService
      .getActions(path, currentDocId, this)
      .then((data) => {
        this.actionExist = data.actions?.length !== 0;
        this.isLoading = false; // Supprime le spinner de chargement lors de la récupération des données
       
      });
  }

  /**
   * Met à jour la propriété showActions en fonction des actions de l'événement
   * @param event - Événement déclenché
   */
  showActionsEvent(event) {
    this.isLoading = event.isLoading;
    this.showActions = event.actions;
  }

  /**
   * Met à jour les propriétés du composant en fonction des informations de l'étape
   * @param infosStep - Informations sur l'étape
   */
  getInfosStep(infosStep: InfosStepTask) {
    if (infosStep.description) {
      this.description = infosStep.description;
    }
    if (infosStep.titleOfActions) {
      this.titleOfActions = infosStep.titleOfActions;
    }
    if (infosStep.icon) {
      this.icon = infosStep.icon;
    }
  }

  getDateNotPending(date){
    return ' ~ '+this.transformTimestamp(date)
  }

  getOrdinal(n: number): string {
    if (n >= 11 && n <= 13) {
      return 'th';
    }
    const remainder = n % 10;
    if (remainder === 1) {
      return 'st';
    } else if (remainder === 2) {
      return 'nd';
    } else if (remainder === 3) {
      return 'rd';
    } else {
      return 'th';
    }
  }

  transformTimestamp(timestamp: number): string {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const ordinal = this.getOrdinal(day);
    return `${month} ${day}${ordinal} ${year}`;
  }
}
