import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchGcontactService {

  constructor() {
    SearchGcontactService.instance = this;
   }
   static instance: SearchGcontactService
  }
