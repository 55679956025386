import { Action } from "../action/action.model";
import { FormRecord } from "../form-record/form-record.model";
import { Role } from "../role/role.model";
import { WorkflowFileAction } from "../workflow-action/workflow-action.model";

export class GedFile extends FormRecord{

    public infos: any;
    private _comment: any;
    private _tasks: any;
    private _actions: WorkflowFileAction[];
    private _role: Role;
    private _canEdit: any;



	constructor(fileInfo=null,data=null) {
		super(data);
		this.infos = fileInfo;
		this._comment = this._tasks = this._actions = null;

		if(data)
		{
			if(data.role)
			{
				this._role = data.role;
			}

			if(data.actions)
			{
				this.setActions(data.actions);
			}

			if(typeof data.canEdit != "undefined" && data.canEdit==false)
				this._canEdit = false;
			else
				this._canEdit = true;
		}
		else
			this._canEdit = true;
	}

	isInGed() {
		return !!this.data();
	}

	setFileInfo(fileInfo) {
		this.infos = fileInfo;
	}

	setPreviewInfo(infos) {
		this.infos.previewUrl = infos.previewUrl;
		this.infos.previewEditUrl = infos.previewEditUrl;
		this.infos.gExt = infos.gExt;
	}

	setActions(actions) {
		this._actions = [];
		for (let n in actions)
		{
			const action = new WorkflowFileAction(this,n,actions[n]);
			this._actions.push(action);
		}
	}

	role() {
		return this._role;
	}

	actions() {
		return this._actions;
	}

	canEdit() {
		return this._canEdit;
	}

	action(n)
	{
		for (let i=0; i < this._actions.length; i++)
		{
			const action = this._actions[i]
			if(action.name() == n)
				return action;
		}

		return null;
	}

	getMetaProps(tags=null,key='label',data=null)
	{
		let props = this.fields(tags,true,key,'toString');
		return props;
	}

}
