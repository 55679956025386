import { SearchResultItem } from "./search-engine-result";
import { SearchGcalEngine } from "src/modules/gcal/models/search-gcal-engine";
import { SearchGcontactEngine } from "src/modules/gcontact/models/search-gcontact-engine";
import { SearchGcontact2Engine } from "src/modules/gcontact2/models/search-gcontact2-engine";
import { SearchGcsEngine } from "src/modules/gcs/models/search-gcs-engine";
import { SearchGdriveEngine } from "src/modules/gdrive/models/search-gdrive-engine";
import { SearchGedEngine } from "src/modules/gdrive/models/search-ged-engine";

import { SearchGpeopleEngine } from "src/modules/gpeople/models/search-gpeople-engine";
import { SearchGcontributorEngine } from "src/modules/gcontributor/models/search-gcontributor-engine";

//ALL ENGINES CLASSES HERE
export const engines =
[
    SearchGdriveEngine,
    SearchGedEngine,
    SearchGcontactEngine,
    SearchGcontact2Engine,
    SearchGcalEngine,
    SearchGpeopleEngine,
    SearchGcsEngine,
    SearchGcontributorEngine,
]

export * from  "./search-engine-result";
export * from "src/modules/gdrive/models/search-gdrive-engine";
export * from "src/modules/gdrive/models/search-ged-engine";
export * from "src/modules/gcal/models/search-gcal-engine";
export * from "src/modules/gcontact/models/search-gcontact-engine";
export * from "src/modules/gcontact2/models/search-gcontact2-engine";
export * from "src/modules/gdrive/models/search-gdrive-engine";
export * from "src/modules/gpeople/models/search-gpeople-engine";
export * from "src/modules/gcs/models/search-gcs-engine";
export * from "src/modules/gcontributor/models/search-gcontributor-engine";

