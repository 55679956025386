import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DocumentService } from 'src/modules/documents/services/document.service';
import { SpaceService } from 'src/modules/spaces/services';
import { DocType } from '../../models/doc-type/doc-type.model';
import { DoctypeDialogData } from '../../models/doctype-dialog-data/doctype-dialog-data.model';
import { GedService } from '../../services';

const themePath = environment.themePath;

@Component({
  selector: "app-add-doctype",
  templateUrl: `./${themePath}/add-doctype.component.html`,
  styleUrls: [`./${themePath}/add-doctype.component.scss`],
})
export class AddDoctypeComponent implements OnInit {


  constructor(
    // public dialogRef: MatDialogRef<AddDoctypeComponent>, @Inject(MAT_DIALOG_DATA) public data: DoctypeDialogData,
    private router: Router, private spaceService: SpaceService, private gedService: GedService, private documentService: DocumentService,
    private cdr: ChangeDetectorRef) {
  }

  public dialogRef: MatDialogRef<AddDoctypeComponent>;
  @Inject(MAT_DIALOG_DATA) public data: DoctypeDialogData
  @Input() isOpen: boolean = false;
  @Output() close = new EventEmitter<any>();

  titleDocument: string = environment.stringsFile.newDoc;
  spaceDesactivated: boolean =  !environment.ESPACE_ACTIVATED;

  @ViewChild('f') public docFormulaire: NgForm;

  closeModal() {
    this.close.emit();
    this.currentStep = 1;

    this.docFormulaire?.form?.reset();
    this.doFilterSelect()
    this.showForm = false;
    this.drawer.close();

  }

  onCancel(): void {
    //this.dialogRef.close();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }


  ngOnInit(): void {
    this.doFilterSelect()
    this.Step_1_Form();
    this.Step_2_Form();
  }

  isLoading = false;


  previewLink: string;
  temp = null;
  docType = {
    oid: "",
    title: "",
  };
  currentId;
  fieldAutoList = [];
  words;
  inGed = false;
  typeDoc;
  showAuto;
  autoUrl;
  showForm = false;
  showdrawer = false;
  testInput;
  done = false;

  currentDoc;
  name;
  code;
  dataToSave = [];
  outerElement = {
    key: "",
    value: null,
  };
  jsontosend = {};
  templateId = "";
  spaceId = "";

  docTypes;
  typeList = [];

  listeSpaces = [];
  listeTemplates = [];
  listeTemplates2 = [];
  defaultTemplate;
  defaultSpace;
  jsonData = {};
  checkedOptions = {}
  @ViewChild("drawer") public drawer: MatDrawer;

  formFields = [];
  temporaryList = [];

  /*   doFilter(value) {
      if (value !== "") {
        this.typeList = [];
      } else {
        this.gedService.getDocTypes("").then((res) => {
          this.autoComplete = res;
  
          this.typeList = [];
  
          for (let option of Object.values(this.autoComplete)) {
            this.typeList.push(option["title"]);
          }
          this.filterType(this.typeList, value);
        });
      }
    } */

  async doFilterSelect() {
    this.docTypes = await this.gedService.getDocTypes("");
  }

  filterType(options, value) {
    return options.filter((values) =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      values.toLowerCase().includes(value)
    );
  }

  doFilterField(field) {
    if (field.value == "") {
      field.fieldAutoList = [];
    }
    else {
      this.gedService
        .getAutocompleteList(field.value, field.auto.url)
        .then((res) => {
          field.fieldAutoList = [];

          if (res && res.length) {
            let autoComplete = [];
            autoComplete = res;

            for (let option of Object.values(autoComplete)) {
              field.fieldAutoList.push(option);
            }

            field.fieldAutoList = this.filter(field.fieldAutoList, field.value);
          }
        });
    }
  }

  filter(options, value) {
    return options.filter((values) =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      values.html.toLowerCase().includes(value)
    );
  }

  async getSpacebyType(type) {
    let space = this.spaceService.getSpacebySid(type);

    return space;
  }
  isDocSpace: boolean = false;
  // Document type select
  onSelectedType(event) {
    this.drawer.open();
    this.formFields = [];
    let type = event.value;

    this.gedService.getDocTypes("")
      .then((types) => {
        this.docTypes = types;
        let oid;

        for (let typeDoc of this.docTypes) {
          if (typeDoc.title == type) {
            oid = typeDoc.oid;
            this.docType = typeDoc;

            // get record form for this document type
            this.gedService.getMetaByType(oid, true, true, null, true)
              .then((res) => {
                this.showForm = true;
                this.showdrawer = true;
                let data = res["data"];
                let metadata = res["metadata"]["fields"];

                for (let key of Object.keys(metadata)) {
                  if (metadata[key]["x-show"] != false && metadata[key]["show"] != false) {
                    let element: DocType;
                    element = new DocType(key, data[key], metadata[key], this.gedService);

                    //temporary
                    //begin
                    if (element.getAuto() != null && element.getControl() == "select") {
                      this.gedService.getAutocompleteListSelect(element.getUrlAuto())
                        .then((autolist) => {
                          let tempList = [];
                          tempList = autolist || [];

                          for (let item of tempList) {
                            element.fieldAutoList.push(item);
                          }
                        });
                    }
                    else if (element.getControl() == "checkbox") {
                      if (element.getUrlAuto()) {
                        this.gedService.getAutocompleteListSelect(element.getUrlAuto())
                          .then((autolist) => {
                            let tempList = [];
                            tempList = autolist || [];

                            for (let item of tempList) {
                              element.fieldAutoList.push(item);
                            }
                          });
                      }

                    }
                    else if (element.getIsEnum() && !(element.getIsMultiple())) {
                      element.setValue({ value: element.getValue().value.toLowerCase(), html: element.getValue().html.toLowerCase() });

                    }
                    else if (element.getAuto() != null) {
                      if (
                        element.getKey() == "space" ||
                        element.getKey() == "template"
                      ) {
                        // dynamic enum values can be dependent on another fields values
                        // the names of that other fields are provided in the query
                        let otherFieldsKey = metadata[key]["x-dynamic-values"]["query"];

                        if (otherFieldsKey) {
                          this.gedService.getAutocompleteList(element.getValue(), element.getUrlAuto(), oid, otherFieldsKey)
                            .then((autolist) => {

                              let tempList = [];

                              if (autolist?.length != 0) {
                                //  element.setValue(autolist[0]);
                                if (autolist) {
                                  element.value = { ...autolist[0] };
                                  element.setPreviewLink(autolist[0]);
                                }
                              }

                              tempList = autolist || [];

                              for (let item of tempList) {
                                if (element.getKey() == "space") {
                                  this.listeSpaces = element.fieldAutoList;

                                  let space = this.getSpacebyType(item.html);
                                }
                                element.fieldAutoList.push(item);
                              }

                              if (element.getKey() == "template") {

                                this.listeTemplates = element.fieldAutoList;
                                this.listeTemplates2.push(element.getPreviewLink());
                                this.previewLink = element.getPreviewLink();
                              }
                            });
                        }

                      }
                    }
                    else {
                      this.gedService.getAutocompleteList(element.getValue(), element.getAuto())
                        .then((autolist) => {
                          let tempList = [];
                          tempList = autolist || [];
                          for (let item of tempList) {
                            element.fieldAutoList.push(item);
                          }
                        });
                    }

                    //end.

                    if (this.data?.eventItem) {
                      element = this.fillEventElement(element)
                    }
                    this.formFields.push(element);

                    this.temporaryList.push(element);
                    if (element.key == "space") {
                      this.isDocSpace = true
                    }

                  }

                }



              });
          }
        }
      });

  }

  fillEventElement(element) {
    let eventItem = this.data.eventItem;
    /*  if(element.key=='description') element.value = eventItem?.getDesc()
    if(element.key=='name') element.value = eventItem.getTitle() */
    return element;

  }

  onSelectedField(event: MatAutocompleteSelectedEvent, element) {
    for (let option of element.fieldAutoList) {
      if (option.html == event.option.value) {
        let temp =
        {
          name: element.key,
          value: option
        };

        this.jsonData[element.key] = option;
      }
    }
  }

  onSelectedValue(field, value) {
    if (field.key == "template") {
      for (let item of field.fieldAutoList) {
        if (value.html == item.html) {
          //field.value=item;
          // field.value=item;
          let id = item?.infos.id || null;
          this.previewLink = `https://drive.google.com/thumbnail?sz=w300&id=${id}`;
        }
      }
    }
  }

  async getIdSpace(field) {
    let spaces = this.listeSpaces;
    for (let i = 0; i < spaces.length; i++) {
      let space = spaces[i];

      if (space["html"] == field) {
        return space;
      }
    }
    return null;
  }

  async getIdTemplate(field) {
    let themes = this.listeTemplates;

    for (let i = 0; i < themes.length; i++) {
      let t = themes[i];

      if (t["html"] == field) return t;
    }
    return null;
  }


  onChangeEnumsChecked(field, name, isChecked: boolean) {

    if (!this.checkedOptions[name.key])
      this.checkedOptions[name.key] = []
    let tags = []

    if (isChecked) {
      this.checkedOptions[name.key].push(field)
    }
    else {
      let index = this.checkedOptions[name.key].indexOf(x => x.value == field)

      this.checkedOptions[name.key].splice(index, 1);
    }

  }

  async onCreateDoc(values) {
    // let spaceId = await this.getIdSpace(values["space"]);
    let templeteId = await this.getIdTemplate(values["template"]);
    this.done = true;
    this.isLoading = true;
    let data = {};
    for (let item of this.formFields) {

      let key = item.key;
      if (item.auto == null && !item.isEnum && item.control != "checkbox") {
        data[key] = values[key];
      }
      else if (item.isEnum && !(item.isMultiple)) {
        data[key] =
        {
          value: item.value.html,
          html: item.enumValues[item.value.html],
          //.toLowerCase()
        };


      }
      else if (item.isEnum && (item.isMultiple) && item.control == "checkbox") {
        let keys = [];
        let datavalues = [];
        if (this.checkedOptions[key]) {
          keys = this.checkedOptions[key].map((obj) => {
            return obj['key'];
          });

          datavalues = this.checkedOptions[key].map((obj) => {
            return obj['value'];
          });
        }

        let strArray = keys.join('|');
        data[key] =
        {
          value: '|' + strArray.toString() + '|',
          html: datavalues.join(','),
          values: this.checkedOptions[key]
        };
      }
      else if (!(item.isEnum) && (item.isMultiple) && item.control == "checkbox") {
        let keys = [];
        let datavalues = [];

        if (this.checkedOptions[key]) {
          keys = this.checkedOptions[key].map((obj) => {
            return obj['value'];
          });

          datavalues = this.checkedOptions[key].map((obj) => {
            return obj['html'];
          });
        }

        let strArray = keys.join('|');

        data[key] =
        {
          value: '|' + strArray.toString() + '|',
          html: datavalues.join(','),
          values: this.checkedOptions[key]
        };
      }
      else if (item.key == "template") {
        data[key] =
        {
          value: templeteId["value"],
          html: item.value.html,
        };
      }
      else {
        let ResData = await this.gedService.getAutocompleteList(values[key], item["auto"]["url"]);

        // data[key] = ResData?.find(item=>item?.html==values[key])
        data[key] = ResData?.find(item => item?.html == values[key])
        if (ResData?.length == 0) {
          this.gedService.getAutocompleteList('', item["auto"]["url"])
            .then(response => {
              data[key] = response.find(item => item?.html == values[key])
            })
        }
      }
    }

    for (let item of this.formFields) {
      if (item.key == "template") {
        this.templateId = templeteId["uid"] || null;
      }
    }

    this.jsonData = data

    if (this.templateId != null) {
      this.gedService.createNewDocInGED(this.docType.oid, this.jsonData, this.templateId, "root")
        .then((resp) => {
          //console.log('resutl is ,',resp)
          this.isLoading = false;
          if (resp != undefined) {
            if (resp?.id) {
              let url = '/workspace/main/file/' + resp.id;
              this.router.navigate([url]);
            }
          }
        });
    }
  }

  cancelForm() {
    for (let item of this.formFields) {
      item.value = "";
    }
    this.showForm = false;
  }

  doctypes: { label: string; value: string }[] =
    [
      {
        label: 'Pdf',
        value: 'pdf',
      },
      {
        label: 'Excel',
        value: 'excel',
      },
      {
        label: 'Word',
        value: 'word',
      },
    ];

  departs: { label: string; value: string }[] = [
    {
      label: 'Department 1',
      value: 'dep-1',
    },
    {
      label: 'Department 2',
      value: 'dep-2',
    },
    {
      label: 'Department 3',
      value: 'dep-3',
    },
  ];
  spaces: { label: string; value: string }[] = [
    {
      label: 'Space 1',
      value: 'spc-1',
    },
    {
      label: 'Space 2',
      value: 'spc-2',
    },
    {
      label: 'Space 3',
      value: 'spc-3',
    },
  ];

  Step_1: FormGroup;
  Step_2: FormGroup;

  steps: {
    stepOrder: number;
    title: string;
  }[] = [
      {
        stepOrder: 1,
        title: 'Type of Document',
      },
      {
        stepOrder: 2,
        title: 'Create New document',
      },
      {
        stepOrder: 3,
        title: 'Select department',
      },
      {
        stepOrder: 4,
        title: 'Choose Space',
      },
      {
        stepOrder: 5,
        title: 'Select Template',
      },
      {
        stepOrder: 6,
        title: 'New Document',
      },
    ];
  currentStep: number = 1;
  title: string = this.steps[this.currentStep - 1].title;


  nextStep() {
    this.currentStep++;
    this.title = this.steps[this.currentStep - 1].title;
  }

  prevStep() {
    this.currentStep--;
    this.title = this.steps[this.currentStep - 1].title;
  }

  // Steps Forms
  Step_1_Form() {
    this.Step_1 = new FormGroup({
      doctype: new FormControl('', [Validators.required]),
    });
  }

  Step_2_Form() {
    this.Step_2 = new FormGroup({
      doctype: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
      ]),
    });
  }
}
