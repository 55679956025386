import { ActionDialog } from './action-dialog/action-dialog.model';
import { Action } from './action/action.model';
import { Document } from './document/document.model';
import { FieldDesc } from './field-desc/field-desc.model';
import { FormFieldDate } from './form-field-date/form-field-date.model';
import { FormFieldEnum } from './form-field-enum/form-field-enum.model';
import { FormField } from './form-field/form-field.model';
import { FormRecord } from './form-record/form-record.model';
import { GedFile } from './ged-file/ged-file.model';
import { WorkflowFileAction } from './workflow-action/workflow-action.model';


export const models = [
    Action,
    ActionDialog,
    Document,
    WorkflowFileAction,
    FormField,
    FormRecord,
    FieldDesc,
    FormFieldDate,
    FormFieldEnum,
    GedFile,
];

export * from './action/action.model';
export * from './action-dialog/action-dialog.model';
export * from './ged-file/ged-file.model';
export * from './document/document.model';
export * from './workflow-action/workflow-action.model';
export * from './field-desc/field-desc.model';
export * from './form-field/form-field.model';
export * from './form-field-date/form-field-date.model';
export * from './form-field-enum/form-field-enum.model';
export * from './flagging/flagging.models';

