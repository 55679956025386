import { TableColumn } from "src/design-system/core/interfaces/table";
import { SearchEngine } from "src/modules/engines/models/search-engine";
import { SearchGpeopleService } from "../services/search-gpeople.service";
import { GpeoplePerson } from "./gpeople-person";


export class SearchGpeopleEngine extends SearchEngine {


    searchGpeopleService: SearchGpeopleService
    facets = [];

    constructor(searchGpeopleService?,clientContexts?){
        super();
        this.searchGpeopleService = searchGpeopleService
    }

    setEngine(){
        this.engine = "gpeople";
    }
    
    setResults(engineResponse, prefixUrl, keyWord: string = ""){
        let jsonResults = engineResponse?.people || []
        this.results = jsonResults.map(item=>new GpeoplePerson(item))
        this.setDisplayProperties(this.results);
        this.getColumns();
        return this.results;

    }
    displayProperties;

    setDisplayProperties(results){
        this.displayProperties = results.map(item=>item.getDisplayProperties())
    }

    columns: TableColumn[]
    getColumns(){
        this.columns = [
            {
              id: 'name',
              type: 'TextWithIcon'
            },
            {
              id: 'mail',
              type: 'Text',
              label: 'Mail'
            },
            {
               id: 'Menu',
               type: 'Menu',
               label: 'Actions',
            }
         ];

        return this.columns;
    } 


    getDisplayProperties(){
        return this.displayProperties;
    }
    

    getResults(){
        return this.results;
    }

    setEngineOptions(){
        this.engineOptions = {engine: this.getEngine()}
    }

    setFacets(engineResponse){
        let jsonFacets = engineResponse;



        return this.facets;
    }

    getFacets(){
        return this.facets;
    }

}