import { Component, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GDocService, GedService } from 'src/modules/app-common/services';
import { MetaType } from 'src/modules/documents/models/meta-type/meta-type.model';
import { SpaceHomeComponent } from '../../containers';
import { FolderMeta } from '../../models/folder/folder-meta.model';

@Component({
  selector: 'app-folder-add',
  templateUrl: './folder-add.component.html',
  styleUrls: ['./folder-add.component.scss']
})
export class FolderAddComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private gedService: GedService,
    private router: Router,
    private gdocService: GDocService,
    private drawer: SpaceHomeComponent) {
  }

  temp = null;
  folderType = '';
  spaceId;
  words;
  inGed = false;
  typeDoc;
  showAuto;
  autoUrl;
  typemains;

  newDocument = {
  }
  spaceName;
  showForm = false;
  formFields = []
  fieldAutoList = [];
  temporaryList = []

  material_icons: string[] = environment.material_icons;
  colors_ged: string[] = environment.colors_ged;

  autoComplete = [];

  ngOnInit(): void {

    this.autoComplete = this.titleList;

    this.route.params.forEach(params => {
      let id = params["spaceId"]
      this.spaceId = id;
    })
  }

  doFilter(value) {
    if (this.folderType == '') {
      this.autoComplete = this.titleList
    }
    else {
      this.autoComplete = this.filter(this.titleList, this.folderType)
      this.filter(this.titleList, this.folderType)
    }
  }

  titleList = ['No value', 'Space']

  filter(options, value) {
    return options.filter(values =>
      values.toLowerCase().includes(value.toLowerCase()))
  }



  async onSelectedType(event: MatAutocompleteSelectedEvent) {
    this.formFields = [];
    this.temporaryList = [];

    this.spaceName = await this.gdocService.getSpaceName(this.spaceId);

    let response = await this.gedService.getFolderMetaByType(event.option.value, this.spaceName, this.spaceId);
    let data = response["data"]
    let metadata = response["metadata"]["fields"]

    for (let key of Object.keys(metadata)) {
      let element: FolderMeta;
      element = new FolderMeta(key, data[key], metadata[key]);



      if (element.type == "integer") element.type = "number"
      if (element.type == "string" || !!element.auto) element.type = "string"

      /* if(element.auto!=null){

        this.gedService.getAutocompleteList(element.value,element.auto.url)
        .then(autolist=>{
          let tempList = autolist
          this.typemains = autolist;

          for(let item of tempList){

            this.fieldAutoList.push(item)


          }

        })
      } */

      if (element.key == "driveId") element.value = this.spaceId;
      this.temporaryList.push(element);

      if (metadata[key]["x-show"] == false || metadata[key]["show"] == false || element.key == "driveId") {
      }
      else {
        this.formFields.push(element);
      }
    }

    this.showForm = true;
  }

  filterr(options, value, field) {
    let currentValue = options.find(item => item.html.toLowerCase() == value.toLowerCase());
    return currentValue;
  }

  doFilterField(field) {
    if (field.value == "") {
      this.fieldAutoList = [];
    }
    else {
      this.gedService.getAutocompleteList(field.value, field.auto.url)
        .then(res => {
          let autoComplete = [];
          autoComplete = res;

          this.fieldAutoList = [];
          for (let option of Object.values(autoComplete)) {
            this.fieldAutoList.push(option)
          }

          let currentValue = this.filterr(this.fieldAutoList, field.value, field)
          let indexOf = this.formFields.findIndex(field => field?.value == currentValue?.html);
          if (indexOf >= 0) this.formFields[indexOf].autoValue = currentValue;

        })
    }
  }

  onAddtoGed(values) {
    let folderType = values.folderType.toLowerCase();

    //delete data['folderType']
    let data2 = {}
    for (let item of this.formFields) {
      if (item.auto == null) {
        data2[item.key] = item.value;
      }
      else {
        data2[item.key] = item.autoValue;
      }
    }

    data2["uid"] = this.spaceId;

    this.gedService.addFolderToGed(folderType, data2)
      .then(res => {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate([currentUrl]);
          })
      })
  }

  closeDrawer() {
    this.drawer.closeDrawer()
  }

  cancelForm() {
    this.folderType = ''

    for (let item of this.formFields) {
      item.value = '';
    }
    this.showForm = false;
  }
}
