import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout.component";
import { DashboardComponent } from "@pages/dashboard/dashboard.component";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { LeftAsideComponent } from "./left-aside/left-aside.component";
import { RightAsideComponent } from "./right-aside/right-aside.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TasksComponent } from "@pages/tasks/tasks.component";
import { DepartmentsComponent } from "@pages/departments/departments.component";
import { SpacesComponent } from "@pages/spaces/spaces.component";
import { NotfoundComponent } from "@pages/notfound/notfound.component";
import { BreadcrumbModule } from "xng-breadcrumb";
import { ContractsComponent } from "@pages/contracts/contracts.component";
import { AnalyticsComponent } from "@pages/analytics/analytics.component";
import { UsersComponent } from "@pages/users/users.component";
import { SupportComponent } from "@pages/support/support.component";
import { NewDocumentComponent } from "../popups/new-document/new-document.component";
import { NewDepartmentComponent } from "../popups/new-department/new-department.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NewSpaceComponent } from "../popups/new-space/new-space.component";
import { TutorialsComponent } from "@pages/tutorials/tutorials.component";
import { TicketsComponent } from "@pages/tickets/tickets.component";
import { DocumentComponent } from "@pages/document/document.component";
import { DocumentDetailsComponent } from "../popups/document-details/document-details.component";
import { TellUsWhyComponent } from "../popups/tell-us-why/tell-us-why.component";
import { ListOfAccessesComponent } from "../popups/list-of-accesses/list-of-accesses.component";
import { AuraModule } from '@presencesoft/aura';

@NgModule({
    declarations: [
      LayoutComponent,
      DashboardComponent,
      TasksComponent,
      DepartmentsComponent,
      SpacesComponent,
      ContractsComponent,
      AnalyticsComponent,
      UsersComponent,
      HeaderComponent,
      FooterComponent,
      LeftAsideComponent,
      RightAsideComponent,
      NotfoundComponent,
       SupportComponent,
      NewDocumentComponent,
      NewDepartmentComponent,      
      NewSpaceComponent,
      TutorialsComponent,   
      TicketsComponent,   
      DocumentComponent,
      DocumentDetailsComponent,
      TellUsWhyComponent,
      ListOfAccessesComponent,
      NewDocumentComponent
   ],
   imports: [
      CommonModule,
      RouterModule,
      NgbModule,
      BreadcrumbModule,
      ReactiveFormsModule,
      FormsModule,
      AuraModule


   ],
   exports: [
      HeaderComponent,
      FooterComponent,
      LeftAsideComponent,
      RightAsideComponent,
      DocumentComponent,
     TellUsWhyComponent,
     DocumentDetailsComponent,
     ListOfAccessesComponent,
     NewDocumentComponent,
     AuraModule

   ],  

})
export class LayoutModule { }
