import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";

import { Observable, throwError } from "rxjs";

import { environment } from "src/environments/environment";
import { AuthService } from "src/modules/user/services";
import { VertexSearchResult } from "../../../spaces/models/vertex-search-result/vertex-search-result.model";
const BACKEND_URL = environment.BACKEND_URL;
const VERTEX_BE_URL = environment.VERTEX_BE_URL;

/**
 * The API Servie calls backend End points.
 * It manages the base url and tokens, and check if the request was ok.
 * If it receives a 401 or 403, it requests a new token and retry the request
 */

@Injectable({
  providedIn: "root",
})
export class APISce {
  constructor(private http: HttpClient, private auth: AuthService) {}

  public async put(
    url,
    data,
    params?,
    options?,
    withJwt = true,
    withGoogleToken = true
  ): Promise<any> {
    url = BACKEND_URL + url;

    if (!options) options = await this.httpConfig(withGoogleToken, withJwt);

    if (!!params) options.params = params;

    return this.http
      .put(url, data, options)
      .pipe(
        catchError((err) => {
          return this.errorHandler(err);
        })
      )
      .toPromise();
  }

  public async get(
    url,
    params?,
    options?,
    withJwt = true,
    withGoogleToken = true,
    useVertexBE = false
  ): Promise<any> {
    url = (useVertexBE ? VERTEX_BE_URL : BACKEND_URL) + url;

    if (!options) options = await this.httpConfig(withGoogleToken, withJwt);

    if (!!params) options.params = params;

    try {
      // console.log('%c GET:'+url, 'background: #eeeeee; color: #333', options);
      let res = await this.http
        .get(url, options)
        /*.pipe(catchError(err => {
          return this.errorHandler(err);
        })) */
        .toPromise();

      return res;
    } catch (error) {
      if (error.status != 404) throw error;

      console.error("error in api get", error, url);

      return null;
    }
  }

  public async post(
    url,
    data,
    params?,
    options?,
    withJwt = true,
    withGoogleToken = true,
    useVertexBE = false
  ): Promise<any> {
    url = (useVertexBE ? VERTEX_BE_URL : BACKEND_URL) + url;

    if (!options) options = await this.httpConfig(withGoogleToken, withJwt);

    if (!!params) options.params = params;

    return this.http
      .post(url, data, options)
      .pipe(
        catchError((err) => {
          return this.errorHandler(err);
        })
      )
      .toPromise();
  }

  //put
  public async update(url, data, params?, options?) {
    url = BACKEND_URL + url;

    if (!options) options = await this.httpConfig(true);

    if (!!params) options.params = params;

    return this.http
      .put(url, data, options)
      .pipe(
        catchError((err) => {
          return this.errorHandler(err);
        })
      )
      .toPromise();
  }

  public async delete(
    url,
    params?,
    options?,
    withGoogleToken = true,
    withJwt = true
  ) {
    url = BACKEND_URL + url;

    if (!options) options = await this.httpConfig(withGoogleToken, withJwt);

    if (!!params) options.params = params;

    return (
      this.http
        .delete(url, options)
        /*.pipe(catchError(err=>{
        return this.errorHandler(err);
      }))*/
        .toPromise()
    );
  }

  // ======================= CONFIG =============

  async httpConfig(
    withGoogleToken = true,
    withJWT = true,
    withGoogleCookie = false
  ) {
    let headers = new HttpHeaders();

    if (withJWT) {
      // headers.set
      headers = headers.set("Authorization", "Bearer " + this.getJWT());

      if (withGoogleToken) {
        let gtoken = await this.getCurrentToken();
        if (gtoken) {
          headers = headers.set("GTOKEN", "Bearer " + gtoken);
        } else {
          console.error("missing token in API");
        }
      }
    } else if (withGoogleToken) {
      let gtoken = await this.getCurrentToken();
      if (gtoken) {
        headers = headers.set("Authorization", "Bearer " + gtoken);
      } else {
        console.error("missing token in API");
      }
    }

    if (withGoogleCookie) {
      headers = headers.set("Cookie", "G_ENABLED_IDPS=google");
    }

    let options = { headers };
    // console.log("headers from source")
    // console.log(options)
    return options;
  }

  /* ####################################### LOGIN ################################### */

  getJWT() {
    return this.auth.jwt;

    // return this.gedLoginService.getJWT(); // ag
    return "";
  }

  getCurrentToken(): Promise<any> {
    return this.auth.getTokenAsync();
  }

  getToken() {
    return this.auth.token;

    // return this.googleLoginService.getToken(); // ag
    return "";
  }

  createAuthorizationHeader(headers: Headers, token) {
    headers.set("Authorization", "Bearer " + token);
    return headers;
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.status != 404)
      // Traitement de l'erreur avec errorHandler
      return throwError(new Error(error.message));
  }
}
