
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchGenericService } from '../../services/search-generic.service';
import { Crumb } from 'src/modules/app-common/components/bread-crumb/bread-crumb.component';
import { SearchTopComponent } from '../../components/search-top/search-top.component';
import { TranslateService } from "@ngx-translate/core";

const $theme = environment.themeUI;

// atob for utf8 string

function decode64(str) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}


@Component({
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.scss']
})
export class SearchContainerComponent implements OnInit, AfterViewInit {

  @ViewChild(SearchTopComponent) appSearchTop: SearchTopComponent;

  keyword = '';
  bc: any[] = [];

  resultGroup = ''
  searchResponse = [];

  isToggled: boolean = false;

  constructor(
    private searchGenericService: SearchGenericService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.route.params.forEach(routeParam => {
      let queryParams1 = null;

      if (routeParam['query64']) {
        try {
          // decode query parameters
          queryParams1 = JSON.parse(decode64(routeParam['query64']));

        } catch (error) {
          console.error("error in search url param ");
        }
      }

      if (queryParams1) {
        this.keyword = queryParams1["q"];

        // exec search query
        this.execSearch(queryParams1);
      }
      else {
        // decode query parameters and execute search query
        this.route.queryParams.forEach(queryParams => {
          this.keyword = queryParams["q"];
          // exec search query
          this.execSearch(queryParams);
        });
      }
    });
    this.route.queryParams.forEach(queryParams => {
      // this.setBC(group);
      let _All = this.translate.instant('All');
      let search = this.translate.instant('Search');

      this.bc.push(new Crumb(search, (event, self) => {
        // this.appSearchTop.unselectGroup();
        this.router.navigate(['/search'], { queryParams: { q: this.keyword } });
      }));
      this.bc.push(new Crumb(_All, (event, self) => {
        // this.appSearchTop.unselectGroup();
        this.router.navigate(['/search'], { queryParams: { q: this.keyword } });
      }));
    });
  }

  ngAfterViewInit(): void {
    this.route.queryParams.forEach(queryParams => {
      let group = queryParams['group'] || '';
      this.bc = [];
      // this.setBC(group);
      let search = this.translate.instant('Search');
      this.bc.push(new Crumb(search));
      let _All = this.translate.instant('All');
      this.bc.push(new Crumb(_All, (event, self) => {
        // this.appSearchTop.unselectGroup();
        this.router.navigate(['/search'], { queryParams: { q: this.keyword } });
      }));


      // this.setBC(group);
      if (group) {
        let groupDesc = this.appSearchTop.getGroup(group);
        let _Group = this.translate.instant(groupDesc.label);

        this.bc.push(new Crumb(_Group, (event, self) => {
          // this.appSearchTop.setGroup(group);
          this.router.navigate(['/search'], { queryParams: { q: this.keyword, group: group } });
        }));
      }
    });
  }

  // setBC(group=null): void {
  //   this.bc = [];

  //   let _All = this.translate.instant('All');
  //   this.bc.push(new Crumb(_All, (event,self) => {
  //     // this.appSearchTop.unselectGroup();
  //     this.router.navigate(['/search'],{queryParams: {q:this.keyword}});
  //   }));

  //   if(group) {
  //     let groupDesc = this.appSearchTop.getGroup(group);
  //     let _Group = this.translate.instant(groupDesc.label);

  //     this.bc.push(new Crumb(_Group, (event,self) => {
  //       // this.appSearchTop.setGroup(group);
  //       this.router.navigate(['/search'],{queryParams: {q:this.keyword,group: group}});
  //     }));
  //   }
  // }

  async execSearch(queryParams?: {}) {
    // get text query
    let query = queryParams['q'];

    // get current group
    let group = queryParams['group'] || '';

    this.searchResponse = await this.searchGenericService.execSearch(query, [], group, queryParams);

    this.setSelectedGroup(group);
  }

  setSelectedGroup(group: string): void {
    this.resultGroup = group || '';

    /*
    this.route.queryParams.forEach(queryParams=>{
      this.resultGroup = (queryParams["group"])? queryParams["group"] : '' ;
    })
    */

  }

  toggleRightSidebar(): void {
    this.isToggled = !this.isToggled;
  }

  newSearch(): void {
    this.router.navigate(['/search'], { queryParams: { q: "salma", group: 'gdrive' } })
  }

}
