import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
  name: 'formManagerSelectSelectedValue'
})
export class FormManagerSelectSelectedValue implements PipeTransform {

  transform(
    data: Array<Record<'label' | 'value', string>>,
    value?: string | number
  ): Record<'label' | 'value', string> {
    return data?.reduce((
      acc: Record<'label' | 'value', string>,
      {
        label,
        value: val
      }: Record<'label' | 'value', string>
    ): Record<'label' | 'value', string> => {
      return (value === label || value === val) ? {
        label,
        value: val
      } : acc;
    }, {
      label: '',
      value: ''
    });
  }

}
