import { SearchFacet,SearchFacetEntry } from "src/modules/engines/models/search-facet";
import { search_config } from "src/environments/config/search_config";

export class SearchGdriveFacet extends SearchFacet
{
    _values: {}
    categoryId : string;

    constructor(obj : SearchFacetEntry){
        super(obj);
        this.engine = 'gdrive';

        this.categoryId = this._getCategoryIdFromConfig(search_config,this.engine);

        this._values = {};
    }

    // get config for this engine, from global search config
    _getCategoryIdFromConfig(search_config,engine) : string
    {
        return search_config.categories
            .find( cat => cat.engines.includes(engine) )
            .id;
    }

    processResults(facetsByType,results)
    {
    }

    setValuesFromResults(type,results)
    {
        // filter results by document type
        let resultsOfType = results
            .filter(item =>
                (item?.typeDocument || '').includes(type)
            );

        let formValues = new Set<string>();
        let facetId = this.getId();

        resultsOfType.forEach(item=>
        {
            let v = item?.properties?.[facetId] ||
            item?.[facetId] ||
            '';

            if(v && !(['-',' '].includes(v)))
            {
                let aV = v.split && v.split(',') || [v];
                aV.forEach(v => {
                    formValues[v.toLowerCase()] = v;
                });
            }
            else
                formValues[''] = 'Empty value';

        });

        // add values in Set (unique value)
        this.values = formValues;
    }

    addValue(item)
    {
        let facetId = this.getId();

        let v = item?.properties?.[facetId] ||
            item?.[facetId] ||
            '';

        if(v && !(['-',' '].includes(v)))
        {
            let aV = v.split && v.split(',') || [v];
            aV.forEach(v => {
                this._addValue(v,v.toLowerCase());
            });
        }
    }

    _addValue(v,k=null) {
        k = k || v.toLowerCase(v);
        this._values[k] = v;
        super._addValue(v);
    }

    getRouteParam()
    {
        // if(this.getSelectedValue()!='' && this.getSelectedValue()!=null){
            if(this.getId() == 'type')
            {
                return ` /${this.getSelectedValue()}`;
            }

            return ` /${this.getId()}=${this.getSelectedValue()}`;
        // }
        // else{
        //     return ;
        // }

    }
}

