import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentPageComponent } from './containers';

const routes: Routes = [
  {
    path: ':docId',
    component: DocumentPageComponent,
  },
  {
    path: ':extention/:docId',
    component: DocumentPageComponent,
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
