<layout-header [breadcrumb]="breadcrumb"></layout-header>
<div class="layout">
  <aside>
    <div class="top-icons">
      <ng-container *ngFor="let item of icons">
        <div class="submenu-container">
        <aurax-btn-icon [label]="item?.label" [icon]="item?.icon | iconAsset" [link]="item?.link"
        [badge]="item?.badge" 
        [class] ="item?.class" ></aurax-btn-icon> 
          <ul class="submenu" *ngIf="item.label==='Spaces'">
            <app-spaces-list [spaces]="spaces"></app-spaces-list>
          </ul>
        </div>
        </ng-container>
    </div>
    <aura-dropdown position="top-left" [items]="avatarActions" size="lg">
    <aurax-avatar-rounded
      [image]="profilePic"></aurax-avatar-rounded>
</aura-dropdown>
  </aside>
  <section class="section" id="lytcontent">
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <path d="M 0 0 L 40 0 C 17.909 0 0 17.909 0 40 Z" style=" fill: white;" />
    </svg>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <app-footer></app-footer>
  </section>
</div>