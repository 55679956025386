import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { availableLocales, EAvailableLocaleName, TAvailableLocale, TAvailableLocales } from "src/assets/i18n";


@Injectable({
  providedIn: 'root'
})
export class AppI18nService {

  private get _availableLocales(): TAvailableLocales {
    return availableLocales;
  }

  private get _currentLocale(): EAvailableLocaleName {
    return this._availableLocales.reduce((acc: EAvailableLocaleName, {
      languageName
    }: TAvailableLocale) => {
      return this._navigatorLanguage.startsWith(languageName) ? languageName : acc;
    }, EAvailableLocaleName.EN);
  }

  private get _navigatorLanguage(): string {
    return window.navigator.language;
  }

  constructor(private readonly _translateService: TranslateService) { }

  initLanguage(): void {
    const currentLocale: EAvailableLocaleName = this._currentLocale;

    this._translateService.setDefaultLang(currentLocale);
    this._translateService.use(currentLocale);
  }

}
