import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ListItem } from 'src/design-system/core/interfaces/list-item';
import { TableColumn, TableRow } from 'src/design-system/core/interfaces/table';
import { environment } from 'src/environments/environment';

const $theme = environment.themeUI;

@Component({
   selector: 'app-tasks',
   templateUrl: $theme + '/tasks.component.html',
   styleUrls: [$theme + '/tasks.component.scss'],
})
export class TasksComponent implements OnInit {
   activeTab: string = 'table';
   globalMenu: {
      label: string;
      callback: Function;
   }[] = [
      {
         label: 'Sort by name',
         callback: function () {
            console.log('Sort by name');
         },
      },
      {
         label: 'Sort by date',
         callback: function () {
            console.log('Sort by date');
         },
      },
      {
         label: 'Sort by type',
         callback: function () {
            console.log('Sort by type');
         },
      },
   ];
   urgentList: ListItem[] = [
      {
         id: 1,
         title: 'Word Doc',
         text: 'Lorem ipsum',
         icon: 'bi bi-file-earmark-word',
         menuItems: [
            {
               label: 'Edit',
               callback: function (id: number) {
                  console.log('Edited Item' + id);
               },
            },
            {
               label: 'Delete',
               callback: function (id: number) {
                  console.log('Deleted Item' + id);
               },
            },
            {
               label: 'View',
               callback: function (id: number) {
                  console.log('View Item' + id);
               },
            },
         ],
      },
      {
         id: 2,
         title: 'PDF Doc',
         text: 'Lorem ipsum',
         image: '/assets/images/icon-pdf.svg',
         icon: 'bi bi-file-earmark-pdf',
         iconColor: '#e94748',
         menuItems: [
            {
               label: 'View',
               callback: function (id: number) {
                  console.log('View Item' + id);
               },
            },
         ],
      },
      {
         id: 3,
         title: 'PDF Document',
         text: 'Lorem ipsum',
         icon: 'bi bi-file-earmark-pdf',
         iconColor: '#e94748',
         menuItems: [
            {
               label: 'Edit',
               callback: function (id: number) {
                  console.log('Edited Item' + id);
               },
            },
            {
               label: 'View',
               callback: function (id: number) {
                  console.log('View Item' + id);
               },
            },
         ],
      },
      {
         id: 7,
         title: 'Excel Document',
         text: 'Lorem ipsum',
         image: '/assets/images/icon-excel.svg',
         icon: 'bi-file-earmark-spreadsheet-fill',
         iconColor: '#11b56a',
         menuItems: [
            {
               label: 'Edit',
               callback: function (id: number) {
                  console.log('Edited Item' + id);
               },
            },
            {
               label: 'View',
               callback: function (id: number) {
                  console.log('View Item' + id);
               },
            },
         ],
      },
   ];

   columns: TableColumn[] = [
      {
         id: 'title',
         type: 'TextWithIcon',
      },
      {
         id: 'date',
         type: 'Date',
         label: 'Date',
      },
      {
         id: 'assignedby',
         type: 'Text',
         label: 'Assigned by',
      },
      {
         id: 'assignedto',
         type: 'Text',
         label: 'Assigned to',
      },
      {
         id: 'doctype',
         type: 'Text',
         label: 'Document type',
      },
      {
         id: 'version',
         type: 'Text',
         label: 'Version',
      },
      {
         id: 'menu',
         type: 'Menu',
      },
   ];
   data: TableRow[][] = [
      [
         {
            id: 'title',
            value: 'Content instagram',
            iconImg: '/assets/images/icon-word.svg',
         },
         {
            id: 'date',
            value: '12/10/2022',
         },
         {
            id: 'assignedby',
            value: 'Simo ghazdali',
         },
         {
            id: 'assignedto',
            value: 'Abdel amerda',
         },
         {
            id: 'doctype',
            value: 'Word office',
         },
         {
            id: 'version',
            value: '1.0.0',
         },
         {
            id: 'menu',
            value: '',
            menuItems: [
               {
                  label: 'Edit',
                  callback: function () {
                     console.log('Edit');
                  },
               },
               {
                  label: 'Delete',
                  callback: function () {
                     console.log('Delete');
                  },
               },
            ],
         },
      ],
      [
         {
            id: 'title',
            value: 'Content instagram',
            iconImg: '/assets/images/icon-excel.svg',
         },
         {
            id: 'date',
            value: '12/10/2022',
         },
         {
            id: 'assignedby',
            value: 'Simo ghazdali',
         },
         {
            id: 'assignedto',
            value: 'Abdel amerda',
         },
         {
            id: 'doctype',
            value: 'Excel office',
         },
         {
            id: 'version',
            value: '1.0.0',
         },
         {
            id: 'menu',
            value: '',
            menuItems: [
               {
                  label: 'Edit',
                  callback: function (row: TableRow) {
                     console.log(row);
                  },
               },
               {
                  label: 'Delete',
                  callback: function (row: TableRow) {
                     console.log(row);
                  },
               },
            ],
         },
      ],
      [
         {
            id: 'title',
            value: 'Content instagram',
            iconImg: '/assets/images/icon-pdf.svg',
         },
         {
            id: 'date',
            value: '12/10/2022',
         },
         {
            id: 'assignedby',
            value: 'Younes',
         },
         {
            id: 'assignedto',
            value: 'Yassine',
         },
         {
            id: 'doctype',
            value: 'PDF',
         },
         {
            id: 'version',
            value: '1.0.0',
         },
         {
            id: 'menu',
            value: '',
            menuItems: [
               {
                  label: 'Edit',
                  callback: function () {
                     console.log('Edit');
                  },
               },
               {
                  label: 'Delete',
                  callback: function () {
                     console.log('Delete');
                  },
               },
            ],
         },
      ],
   ];

   constructor(private titleService: Title) {
      this.titleService.setTitle('Tasks');
   }
   ngOnInit(): void {}
   toggleView(view: string) {
      this.activeTab = view;
   }
}
