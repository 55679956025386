import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

export class TUserData
{
  displayName : string;
  email: string;
  photoUrl: string;
  
   
};

@Injectable()
export class User
{
    data: TUserData;

    constructor(data : TUserData)
    {
      this.data  = data;

      this.initPicture(data);
    }

    getDisplayName(){
        return this.data.displayName
    }
    getEmail(){
        return this.data.email
    }
    getPicture(){
        return this.data.photoUrl
    }

    initPicture(data: TUserData)
    {
        
        this.data.photoUrl = data.photoUrl || environment.defaultPicture
    }
}
