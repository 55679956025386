import { environment } from "src/environments/environment";

import { User, TUserData } from "src/modules/user/models/user/user.model";

export class RecentContact extends User
{
  constructor(data: TUserData)
  {

    super(data);
    this.getDisplayProperties();
  }

  displayProperties;

  getDisplayProperties()
  {
    var self = this;
    this.displayProperties = [
      {
        title: this.getDisplayName(),
        text: this.getEmail(),
        image: this.getPicture(),
        menuItems: [
          {
            label: "Edit",
            callback: function (id: number) {
              console.log("Edited Item" + id);
            },
          },
          {
            label: "View",
            callback: function (id: number) {
              console.log("View Item" + id);
            },
          },
        ],
      },
    ];
    return this.displayProperties;
  }
}
