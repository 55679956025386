import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GDocService } from 'src/modules/app-common/services';
import { DocumentService } from 'src/modules/documents/services/document.service';
import { SpaceContent } from 'src/modules/spaces/models/space/space-content.model';
import { SpaceService } from 'src/modules/spaces/services';
import { TaskService } from 'src/modules/tasks/services/task.service';
import { Gcontact } from 'src/modules/workspace/models/contact/gcontact.model';
import { Event } from 'src/modules/workspace/models/event/event.model';
import { SearchService } from '../../services/search/search.service';

// OBSOLETE : not used

const searchRoute = environment.searchRoute

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})

export class SearchPageComponent implements OnInit {
  NomStr = environment.stringsFile.NomStrg;
  PropriétaireStr = environment.stringsFile.Propriétaire;
  DatemodificatonStr = environment.stringsFile.Datemodificaton;
  TypeStr = environment.stringsFile.Type;
  EtatStr = environment.stringsFile.Etat;
  ActionsStr = environment.stringsFile.Actions;

  dataSource = new MatTableDataSource();
  tableContent;
  displayedColumns: string[] = ['name', 'Owner', 'type', 'menu'];
  displayHeaderValeur: string[] = [this.NomStr, this.PropriétaireStr, this.TypeStr, ' '];

  columnsToDisplay: string[] = this.displayedColumns.slice();

  constructor(
    private route: ActivatedRoute,
    private router: Router, private gdocService: GDocService, private searchService: SearchService,
    private documentService: DocumentService,
    private spaceService: SpaceService, private taskService: TaskService) { }

  keywords;
  searchResults: any = [];
  searchTable: any = [];
  searchQuery: any = [];
  gpeoples: Gcontact[] = [];
  gcontact: Gcontact[] = [];
  gcontact2: Gcontact[] = [];
  ged: SpaceContent[] = [];
  gdrive: SpaceContent[] = [];
  events: Event[] = [];
  filterListes: any = [];

  isLoading = true;
  @ViewChild('drawer') drawer: MatDrawer;

  ngOnInit() {
    // useful when we relaunch another search while on search page
    this.isLoading = true;

    //this.searchResults=[]
    this.route.queryParams.forEach(queryParams => {
      this.keywords = queryParams["q"] || ''
      this.searchQuery = this.keywords.match(' /')

      let folderId = queryParams["searchIn"];

      if (folderId != 'Tasks') {
        this.search(this.keywords, folderId)
      }
      else {
        //this.searchTasks()
      }
    })
  }

  filterCentent

  search(keywords, folderId) {
    let temp = [];

    if (folderId == undefined || folderId == localStorage.driveId) {
      folderId = null;
    }

    this.searchService.searchRes(this.keywords, folderId)
      .then(data => {
        this.isLoading = false;
        this.searchResults = data;

        let searchResTable = [];
        this.tableContent = data['gdrive']
        this.filterCentent =
        {
          items: data['gdrive'],
          facets: data['facets']
        }

        for (let item of this.searchResults?.gdrive) {
          this.gdrive.push(new SpaceContent(item));
        }

        let i = 0;

        for (let item of this.tableContent) {
          let element = new SpaceContent(item);

          this.tableContent[i] = element;

          searchResTable.push(element);

          this.searchTable = searchResTable

          i++;
        }

        for (let item of this.searchResults?.gcal) {
          this.events.push(new Event(item))
        }

        for (let item of this.searchResults?.gpeople) {
          this.gpeoples.push(new Gcontact(item));
        }

        for (let item of this.searchResults?.gcontact) {
          this.gcontact.push(new Gcontact(item))
        }

        for (let item of this.searchResults?.gcontact2) {
          this.gcontact2.push(new Gcontact(item))
        }


        this.dataSource = this.searchTable

      });
  }

  toContact(email) {
    let id = email.slice(email.indexOf('/'));
    window.open(`${environment.contactLink}/person+${id}`, '_blank');
  }

  parents = []


  //recursive function : get parents list of current space
  async getOneParent(id) {
    let dataparent = await this.gdocService.getFolderParent(id);

    if (dataparent != null) {
      let parent = { id: dataparent, name: '' };
      let parentFolder = await this.gdocService.getFile(parent.id);
      parent.name = parentFolder["name"];
      this.parents.unshift(parent);
      if (parentFolder.parents) {
        this.getOneParent(parent.id);
      }
    }
  }

  // ROW SELECTION METHODS
  selectedRowIds: Set<number> = new Set<number>();
  selectedRowId: number;
  selectedRow = null;

  onRowDblClick(row) {
    this.selectedRow = row;
    let url = this.documentService.getRoutebyMimeType(row.mimeType) + row.id;
    this.router.navigateByUrl(url);
  }

  selectedDocument;
  rowClick(row) {
    if (row.iconLink.includes("folder")) {
      this.router
        .navigateByUrl("/", { skipLocationChange: true })
        .then(() => {
          this.router.navigate(
            ["/s/space/" + row.id],
            {
              queryParams: { d: localStorage.host_domain },
            });
        });
    }
    else {
      this.spaceService.openDocTab(row);
    }
  }

  toExternalLink(f) {
    if (f.type.includes("Folder")) {
      window.open("https://drive.google.com/drive/folders/" + f.id, "_blank");
    }
    else {
      this.gdocService
        .getFile(f.id)
        .then()
        .then((data) => {
          let doc = data;

          window.open(doc.webViewLink, "_blank");
        });
    }
  }

  openDoc() {
    let row = this.selectedDocument;

    this.spaceService.openDoc(row);
  }

  toMailSearch(email) {
    window.open('https://mail.google.com/mail/u/0/?tab=rm&ogbl#search/' + email, '_blank');
  }

  onRowClick(row) {
    this.showDocDetails = true;

    this.selectedRow = row;
    this.selectedDocument = row;
    this.previewLink += this.selectedDocument.id;

    if (row.iconLink.includes('folder')) {
      this.router.navigate(['/s/space/' + row.id], { queryParams: { d: localStorage.host_domain } })
    }
    else {
      this.selectedRow = row;
      let url = this.documentService.getRoutebyMimeType(row.mimeType) + row.id;
      this.router.navigateByUrl(url);
    }
  }

  rowParent(parentId) {
    if (parentId.iconLink.includes('folder')) {
      this.gdocService.getMyDriveId().then((res) => {
        if (parentId.id == res) {
          this.router.navigate(["/s/space/root"]);
        }
        else {
          if (parentId.id == null) {
            this.router.navigate(["/workspace/main"]);
          }
          else {
            this.router.navigate(['/s/space/' + parentId.id], { queryParams: { d: localStorage.host_domain } })
          }
        }
      });
    }
    else {
      this.gdocService.getMyDriveId().then((res) => {
        if (parentId.parents == res) {
          this.router.navigate(["/s/space/root"])
        }
        else {
          if (parentId.parents == null) {
            this.router.navigate(["/workspace/main"]);
          }
          else {
            this.router.navigate(['/s/space/' + parentId.parents], { queryParams: { d: localStorage.host_domain } });
          }
        }
      });
    }
  }


  docinGed = true;
  isDocument = true;

  rowIsSelected(row) {
    //return this.selectedRowIds.has(id);
    return this.selectedRow == row;
  }

  selected;
  closerDrawer() {
    this.drawer.close();
  }

  toFilter;

  openDrawer() {
    this.drawer.open()
  }

  filterResults = []

  doSomething(filteredSearch) {
    this.dataSource = filteredSearch
  }

  showLinks = true;

  doSomething2(showLinks) {
    this.showLinks = showLinks;
  }

  resetFilters() {
    this.dataSource = this.searchResults;
  }

  clearSearch() {
    this.keywords = '';

    this.route.queryParams.forEach(queryParams => {
      let id = queryParams["searchIn"];
      this.router.navigate(['/s/space/' + id], { queryParams: { d: localStorage.host_domain } })
    });
  }

  toLink(p) {
    window.open(p, '_blank');
  }

  eventSearch(p) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(searchRoute, { queryParams: { q: p, d: localStorage.host_domain } });
      });

  }

  toContactInfo(email) {
    window.open(`${environment.contactLink}` + email, '_blank');
  }

  toContacts() {
    window.open(`${environment.contactLink}`, '_blank');
  }

  toContactDirectory() {
    window.open('https://contacts.google.com/directory', '_blank');
  }

  toContactOthers() {
    window.open('https://contacts.google.com/frequent', '_blank');
  }

  toDrive() {
    window.open(`${environment.drivelink}`, '_blank');
  }

  toEvents() {
    window.open(`${environment.calendarLink}`, '_blank');
  }

  showDocDetails = false;
  previewLink = 'https://drive.google.com/thumbnail?sz=w360&id=';
}
