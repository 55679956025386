import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuraModule } from '@presencesoft/aura';
import { BreadcrumbModule } from 'xng-breadcrumb';

import { LayoutModule } from '@layout/layout.module';


@NgModule({
  exports:[
    AuraModule,
    BreadcrumbModule,
    CommonModule,
    FormsModule,
    LayoutModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  imports: [
    AuraModule,
    BreadcrumbModule,
    CommonModule,
    FormsModule,
    LayoutModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class DesignSystemModule { }
