export class Document {
    docId?: String;
    ownerToken?: String;
    owner?: String;
    content?: String;
    docName?: String;
    client?: String;
    modifDate?: String;

    fileInfos;

    constructor(fileInfos, doc?)
    {
        let document = doc;
        document.docId = fileInfos.id;
        this.fileInfos = fileInfos;
        return document;
    }

    getName() {
      return this.fileInfos.name;
    }
}
