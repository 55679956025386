import { ContactService } from "src/modules/contact/services/contact.service";
import { TableColumn } from "src/design-system/core/interfaces/table";
import { SearchEngine } from "src/modules/engines/models/search-engine";
import { GcalEvent } from "src/modules/workspace/models/event/gcal-event.model";
import { SearchGcalService } from "../services/search-gcal.service";


export class SearchGcalEngine extends SearchEngine 
{
    searchGcalService: SearchGcalService
    contactService
    facets = [];

    constructor(searchGcalService?,clientContexts?){
        super();
        this.searchGcalService = searchGcalService;
        this.contactService = ContactService.instance
    }

    setEngine()
    {
        this.engine = "gcal";
    }

    setResults(engineResponse)
    {
        let jsonResults = engineResponse?.items || []
        this.results = jsonResults.map(item=>new GcalEvent(item))
        this.setDisplayProperties(this.results);
        this.getColumns();
        return this.results;

    }
    displayProperties;

    setDisplayProperties(results)
    {
        this.displayProperties = results.map(item=>item.getDisplayProperties())
    }

    columns: TableColumn[];

    getColumns()
    {
        this.columns = [
            {
              id: 'name',
              type: 'TextWithIcon'
            },
            {
              id: 'date',
              type: 'Text',
              label: 'Date'
            },
            {
               id: 'Menu',
               type: 'Menu',
               label: 'Actions',
            }
         ];

        return this.columns;
    } 

    getDisplayProperties()
    {
        return this.displayProperties;
    }

    getResults()
    {
        return this.results;
    }

    setEngineOptions()
    {
        this.engineOptions = {engine: this.getEngine()}
    }

    setFacets(engineResponse)
    {
        let jsonFacets = engineResponse;
        return this.facets;
    }

    getFacets()
    {
        return this.facets;
    }
}