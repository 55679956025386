import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IEvent } from '../../models';
import { EmailToNamePipe } from 'src/modules/explorer/pipes/email-to-name.pipe';
import { ShortNamePipe } from 'src/modules/explorer/pipes/short-name.pipe';

@Component({
  selector: 'app-flagging-status',
  standalone: true,
  imports: [CommonModule,
    MatTooltipModule],
  templateUrl: './flagging-status.component.html',
  styleUrls: ['./flagging-status.component.scss']
})
export class FlaggingStatusComponent implements OnInit, OnChanges {
  @Input() event: IEvent

  TEXT = {
    flag: 'Flagged',
    verify: 'Verified',
    null: ''

  }

  RESPONSE_MAP = {
    //verify
    //1
    'Information is well presented/respect templates': 'Information is well presented/respect templates.',
    'Information is up-to-date': 'Information is up-to-date.',
    'Information is accurate and correct': 'Information is accurate and correct.',
    //2
    "Information is well presented/respect templatesInformation is up-to-date": "info is well presented & up-to-date.",
    "Information is well presented/respect templatesInformation is accurate and correct": "info is well presented & accurate.",
    "Information is up-to-dateInformation is accurate and correct": "info is up-to-date & accurate.",
    //3
    "verify": "info is well presented, up-to-date & accurate.",


    //flag
    'Information is not well presented/do not respect templates': "Information is not well presented/do not respect templates.",
    "Information is outdated/needs to be refreshed": "Information is outdated/needs to be refreshed.",
    'Information is inaccurate or incorrect': 'Information is inaccurate or incorrect.',
    //2
    "Information is not well presented/do not respect templatesInformation is outdated/needs to be refreshed": "info is not well presented & outdated.",
    "Information is not well presented/do not respect templatesInformation is inaccurate or incorrect": " info is not well presented & inaccurate.",
    "Information is outdated/needs to be refreshedInformation is inaccurate or incorrect": "info is outdated & inaccurate.",
    //3
    "flag": " info is not well presented, outdated & inaccurate.",
  }
  constructor(private shortNamePipe: ShortNamePipe,

    private datePipe: DatePipe) { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('this.event : ', this.event)
  }

  ngOnInit(): void {
    console.log('this.event : ', this.event)
  }

  tooltipText() {
    if (!this.event)
      return;
    if (!this.event.options.length)
      return;

    const prefix = this.TEXT[this.event.type] + ' by ' + this.shortNamePipe.transform(this.event?.user?.firstName || '', this.event?.user?.lastName || '') + '. ' + `(${this.datePipe.transform(this.event.date)}): `
    if (this.event.options.length == 1)
      return prefix + this.event.options.map(e => e.option).join('')

    if (this.event.options.length == 2)
      return prefix + this.RESPONSE_MAP[this.event.options.map(e => e.option).join('')]

    if (this.event.options.length == 3)
      return prefix + this.RESPONSE_MAP[this.event.type]




  }


}
