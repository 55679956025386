<ng-container *ngIf="!isLoading">
    <div class = 'title'>{{'flaggedContent' | translate}}</div>

    <div class="row list hide">
        <div class="col">
           <aura-table-row
                data-title="{{'flaggedContent' | translate}}"
                [disableHead]="true"
                [columns]="columns"
                [data]="fllagedContentTableData" ></aura-table-row>
        </div>
    </div>
</ng-container>

<ng-template #myTemRef>
    <span class="tag">{{'flagged' | translate}}</span>
</ng-template>