import { TableColumn } from "src/design-system/core/interfaces/table";
import { SearchEngine } from "src/modules/engines/models/search-engine";
import { SearchGcontact2Service } from "../services/search-gcontact2.service";
import { Gcontact2Person } from "./gcontact2-person";


export class SearchGcontact2Engine extends SearchEngine {


    searchGcontact2Service: SearchGcontact2Service;
    facets = [];

    constructor(searchGcontact2Service?,clientContexts?){
        super();
        this.searchGcontact2Service = searchGcontact2Service
    }

    setEngine(){
        this.engine = "gcontact2";
    }

    
    setResults(engineResponse){
        let jsonResults = engineResponse?.results || []
        this.results = jsonResults.map(item=>new Gcontact2Person(item))
        this.setDisplayProperties(this.results);
        this.getColumns();
        return this.results;

    }
    displayProperties;

    setDisplayProperties(results){
        this.displayProperties = results.map(item=>item.getDisplayProperties())
    }

    columns: TableColumn[]
    getColumns(){
        this.columns = [
            {
              id: 'name',
              type: 'TextWithIcon'
            },
            {
              id: 'mail',
              type: 'Text',
              label: 'Mail'
            },
            {
               id: 'Menu',
               type: 'Menu',
               label: 'Actions',
            }
         ];

        return this.columns;
    } 


    getDisplayProperties(){
        return this.displayProperties;
    }

    getResults(){
        return this.results;
    }

    setEngineOptions(){
        this.engineOptions = {engine: this.getEngine()}
    }

    setFacets(engineResponse){
        let jsonFacets = engineResponse;



        return this.facets;
    }

    getFacets(){
        return this.facets;
    }
}