import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-folder-share',
  templateUrl: './folder-share.component.html',
  styleUrls: ['./folder-share.component.scss']
})
export class FolderShareComponent implements OnInit {

  
  constructor(public dialogRef: MatDialogRef<FolderShareComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    ) { }

  ngOnInit(): void {
  }

}
