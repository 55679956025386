import { Component, EventEmitter, Output, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchGenericService } from '../../services/search-generic.service';

@Component({
  selector: 'app-search-facets',
  templateUrl: './search-facets.component.html',
  styleUrls: ['./search-facets.component.scss']
})
export class SearchFacetsComponent implements OnInit, OnChanges{

  @Input('facets') facets = [];
  @Input('keyword') keyword;
  @Input('group') group;

  facetsForm: Array<any>;
  text : string;
  selected;

  get isFacetsFormEmpty(): boolean {
    return !this.facetsForm || this.facetsForm.length === 0;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchGenericService: SearchGenericService)
  {}

  ngOnInit(): void
  {
    let {text,selected} = this.parseQueryString(this.keyword);

    this.facetsForm = this.createFacetsControls(selected,this.facets);
    this.text = text;
  }

  ngOnChanges(changes: SimpleChanges): void
  {
    if(changes.facets)
    {
      this.facets = changes.facets.currentValue;

      let {text,selected} = this.parseQueryString(this.keyword);

      this.selected = selected;
      this.facetsForm = this.createFacetsControls(selected,this.facets)
      this.text = text;
    }
  }

  // extract selected facets from query string ("text /facet1=value1 /facet2=value2" )
  parseQueryString(q)
  {
    let selected = {};

    if(!q)
      return {text:'',selected};

    let aFacets = q.split(' /');
    let text = aFacets[0];

    aFacets = aFacets.slice(1);

    aFacets.forEach(f =>
    {
      let aF = f.split('=');
      if(aF.length==2)
      {
        selected[aF[0]] = aF[1];
      }
      else
      {
        selected['type']= aF[0];
      }
    });

    return { text, selected };
  }

  // create query string from selected values and changed field
  buildSearchString(fname,value,selected)
  {
    let aQ = [this.text];
    selected[fname] = value;

    for (let f in selected) {
      let v = selected[f];

      if(v)
      {
        if(f == 'type')
          aQ.push('/'+v);
        else
          aQ.push('/'+f+"="+v);
      }
    }

    return aQ.join(" ");
  }

  createFacetsControls(selected,facets)
  {
    let uniqueLabels = new Set();
    let facetsForm = (facets || []).map(f=>
    {
      let fname = f.getId();
      let label = f.getLabel();
      if (uniqueLabels.has(label)) {
        // Si le label existe déjà, éviter de redoubler l'élément
        return null;
    }

      uniqueLabels.add(label);
      let selectedValue;
      let selectedOption;
      if(selected[fname])
      {
        selectedValue = selected[fname] || null;
        selectedOption = {label:selectedValue, value:selectedValue || null};
        f.setSelectedValue(selectedValue);
      }
      else
      {
        selectedOption =  null;
        selectedValue = null;
      }

      let control = new FormControl(
          selectedValue,
          [Validators.required]
        );

      let formGroup = new FormGroup(
        {
          [fname]: control
        });

      let options = Array.from(f.getValues())
      .sort((a : string,b: string) => a==b ? 0 : (a.toLowerCase() > b.toLowerCase()) ? 1 : -1)
      .map(item=>({label:item,value:item}));

      return  {
        id:fname,
        facetObject: f,
        selectedValue,
        selectedOption,
        options: options,
        control,
        formGroup:formGroup,
        label: label,
        placeholder: label
      };
    }).filter(item => item !== null);

    return facetsForm;
  }

  onChangeFacetValue(event,f)
  {
     
    let value = event && event.value || null;


    if(f.control)
      f.control.setValue(value);

    let facetObject = f.facetObject;
    let fname = facetObject.getId();

    let query = this.buildSearchString(fname,value,this.selected);
    this.router.navigate(['/search'],{queryParams:{q:query,group:this.group}});

    return;
    /*

    let facetsParamCurrent = facetObject.getRouteParam();
    let facetsParamNew;


    if(event && event.value!='')
    {
      f.selectedValue = event.value;
      facetObject.selectedValue = event.value;
      facetsParamNew = facetObject.getRouteParam();
    }
    else
    {
      facetObject.unsetSelectedValue();
      facetsParamNew = facetObject.getRouteParam();
    }

    // remove previous value
    if(this.keyword.includes(facetsParamCurrent))
    {
      this.keyword = this.keyword.replace(facetsParamCurrent,'');
    }

    // add new value
    if(!this.keyword.includes(facetsParamNew))
    {
      this.keyword+=facetsParamNew
    }

    console.log('newQuery',this.keyword);

    this.router.navigate(['/search'],{queryParams:{q:this.keyword,group:this.group}});
    */
  }

  /*
  setSelectedFacet(f)
  {
    let facets = this.keyword.split(' /').slice(1);
    console.log("facets", facets)

    let typeFacet = facets.find(item=>!(item.includes('=')));

    if(f.getLabel()!='type')
    {
      facets = facets
        .filter(item=>
          item.includes('=')
        )
        .map(facet=>
          ({label: facet.split('=')[0],
            value: facet.split('=')[1]
          }));

      let selected = facets.find(item=>
        item.label==f.getLabel())?.value || '';

      return (selected!='')?
        {label: selected,value:selected}
        : null;
    }
    else
    {
      if(typeFacet!=undefined && typeFacet!='')
        return {
          label:typeFacet,
          value:typeFacet
        }
    }

    return null;
  }
  */

  resetFilters()
  {
    this.closePanel();

    this.router.navigate(['/search'],{queryParams:{q:this.text,group:this.group}});
  }

  // close panel
  @Output() emitState: EventEmitter<any> = new EventEmitter();
  closePanel(){
    this.emitState.emit({closePanel: true})
  }
}
