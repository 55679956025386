import { FieldDesc } from "../field-desc/field-desc.model";
import { FormField } from "../form-field/form-field.model";

export class FormFieldDate extends FormField{


	constructor(desc,data){
		super(desc, data);
	}

    
	update(v) {
		let date = new Date(v);
		// let date = Date.parse(v);
		this.set(date.toISOString())
		//this._data = date.toISOString();
		return this.data();
	}

	toString() {
		if(!this.data())
			return '- / - / -';

		let [y,m,d] = this.data().split('T')[0].split('-');
		return d+' / '+m+' / '+y;
	}
}
