import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailToName',
  standalone: true
})
export class EmailToNamePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return '';
    }

    // Check if it's an email
    if (value.includes('@')) {
      // Extract the part before "@"
      const namePart = value.split('@')[0];

      // Remove "." and make it capital case
      const transformedName = namePart.split('.').map(word => this.capitalize(word)).join(' ');


      return transformedName;
    } else {
      // If it's a name, return it as is
      return value;
    }
  }
  private capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

}
