import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SpaceLinkPipe } from '../../pipes/space-link.pipe';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-space-element',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SpaceLinkPipe, RouterModule],
  templateUrl: './space-element.component.html',
  styleUrls: ['./space-element.component.scss']
})
export class SpaceElementComponent implements OnInit {
  @Input()
  space

  icons = {
    space: 'gg-folder',
    group: 'gg-organisation'
  }
  constructor() { }

  ngOnInit(): void {
  }

}
export interface SpaceItem {
  name: string; //"All groups",
  path: string; //'/s/groups/all',
  type: 'space' | 'group'
  // icon: 'gg-organisation',
}