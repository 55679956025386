import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { search_config } from '../../../../environments/config/search_config';

@Component({
  selector: 'app-search-top',
  templateUrl: './search-top.component.html',
  styleUrls: ['./search-top.component.scss']
})

export class SearchTopComponent implements OnInit,OnChanges
{
  @Input("searchResponse") searchResponse;
  @Input("resultGroup") resultGroup;
  @Input("keyword") keyword;

  category = ''
  categories = search_config.categories.sort((a,b)=>a.order-b.order);
  searchResponseObservable;

  constructor(private router: Router, private route: ActivatedRoute) { }

  selectedGroup;
  groupById = {}

  ngOnInit(): void
  {
    this.selectedGroup =
      (this.searchResponse.results || [])
        .find(group => group.id==this.resultGroup );

    this.categories.forEach(group =>
    {
      
      this.groupById[group.id] = group;
    });


  }

  facets= []
  typeDocument: any ;
  filterLabels = []


  ngOnChanges(changes: SimpleChanges): void
  {


    if(changes.searchResponse)
    {


      this.selectedGroup =
        (this.searchResponse.results || [])
          .find(group=>group.id==this.resultGroup)

      this.searchResponseObservable = of(this.searchResponse.results);


      this.route.queryParams.forEach(queryParams=>
      {
        let cat = queryParams['group'];
        this.category = cat;

        if(this.selectedGroup)
        {
          this.selectedGroup.facets = this.searchResponse.facets;
        }

        this.facets = this.setSelectedFacets(this.facets);

      });
    }
  }

  setGroup(group)
  {
    this.router.navigate(['/search'],{queryParams: {q:this.keyword,group: group}});
  }

  getGroup(groupId)
  {
    return this.groupById[groupId];
  }

  unselectGroup()
  {
    this.keyword = (this.keyword.indexOf(" /")!=-1) ?
        this.keyword.slice(0,this.keyword.indexOf(" /")) :
        this.keyword;

    this.router.navigate(['/search'],{queryParams: {q:this.keyword}});
  }

  setSelectedFacets(facets)
  {
    let selectedValue = this.keyword?.split(' /').slice(1);

    return facets;
  }

  searchFacetsEmit(event)
  {
    if(event.closePanel==true)
    {
      this.closePanel();
    }
  }

  // ====== panel open / close ==========
  openFacetsPanel()
  {
    this.toggleRightSidebar()
  }

  isToggled: boolean = false;
  toggleRightSidebar()
  {
     this.isToggled = !this.isToggled;
  }

  closePanel()
  {
    this.isToggled = false;
  }

}
