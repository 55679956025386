import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { FilterObject } from "../../models/filter-object/filter-object.model";
import { SearchService } from "../../services/search/search.service";
import { SearchPageComponent } from "../search-page/search-page.component";

const searchRoute = environment.searchRoute

export interface selectedFilter {
  selectedLabel: string;
  selectedValue: string;
}

@Component({
  selector: "app-search-filters",
  templateUrl: "./search-filters.component.html",
  styleUrls: ["./search-filters.component.scss"],
})
export class SearchFiltersComponent implements OnInit, OnChanges {

  allValues = environment.stringsFile.allValues;
  searchValue = "";
  homePage = "/workspace/main";
  @Input("searchResults") searchResults2;
  @Input("filterCentent") filterCentent;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.filters =[]

  }
  searchResults
  constructor(
    private drawer: SearchPageComponent,
    private searchService: SearchService,
    private searchPage: SearchPageComponent,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  filterLabels = []
  itemLenght = false;
  isSelected = false;
  isType = true;
  //Initialize the list of filter options
  ngOnChanges(changes: SimpleChanges)
  {

    if( this.filterCentent)
    {
      this.searchResults = this.filterCentent['items']
    }

    this.filterLabels =
    [
      { label: "Type", value: "type" },
      { label: "Client", value: "client" },
      { label: "State", value: "state" },
    ];

    if(this.filterCentent && this.filterCentent['facets'] && this.filterCentent['facets'][0]['spaces'])
    {
      let facets = this.filterCentent['facets'][0]['spaces'] ;

      Object.values(facets).forEach(item=>
      {
        let valuesArray:any[] = item['facets']['fields'].split(',') ;
        valuesArray.forEach(lab =>
        {
          if(lab.includes("_"))
          {
            var newStr   =lab.replace("_" , " ");
            this.filterLabels.push({label: lab , value: lab });
          }
          else
            this.filterLabels.push({ label: lab , value: lab});
        });

      })
    }

    const uniq = new Set(this.filterLabels.map(e => JSON.stringify(e)));
    const res = Array.from(uniq).map(e => JSON.parse(e));
    this.filterLabels = res;

    if (changes["filterCentent"])
    {
      let variab = changes["searchResults"];

      //this.toSearchTable = this.searchResults

      if (this.searchResults != undefined)
      {
        for (let label of this.filterLabels)
        {
          let temp: FilterObject =
          {
            label: label.label,
            values: [],
            selectedValue: ""
          };

          let tempo;
          if (label.label)
          {
            if (label.label === "partners" || label.label === "other_partners")
            {
              let  partners = this.searchResults && this.searchResults
                .map((element) => element.properties[label.value])
                .filter((value, index, self) =>
                    self.indexOf(value) === index &&
                    value != "" &&
                    value != undefined
                  );

              let values= []
              let tempovalues= []

              Object.values(partners).forEach((item  :string)=>
              {
                if((Object.values(item)).includes(','))
                {
                  values.push(...item.split(','))
                }
                else
                {
                  values.push(item)
                }
              });

              const uniq = new Set(values.map(e => JSON.stringify(e)));
              const res = Array.from(uniq).map(e => JSON.parse(e));
              tempo = res;
            }
            else
            {
              tempo = this.searchResults
              ?.map((element) => element.properties[label.value])
              .filter(
                (value, index, self) =>
                  self.indexOf(value) === index &&
                  value != "" &&
                  value != undefined
              );
            }

            temp.selectedValue == tempo.value;
          }

          if (label.label == "Type")
          {
              tempo = this.searchResults
                ?.map((element) => element[label.value])
                .filter(
                  (value, index, self) =>
                    self.indexOf(value) === index &&
                    value != "" &&
                    value != undefined
                );
              temp.selectedValue == tempo.value;
          }

          temp.values = tempo;

          if (tempo.length > 0)
          {
            if (temp.values.length == 1 )
            {
              // && temp.values["0"] !=="0"
              this.isSelected = true;
              this.filters.push(temp);
            }
            else if (temp.values.length > 1)
            {
              temp.values.push(this.allValues);
              this.filters.push(temp);
            }
          }
        }
      }
    }

    let curentSearch = this.route.snapshot.queryParams["q"]

    if(curentSearch.indexOf("/") === -1 )
    {
      this.isType=false;
    }
  }
  searchValues = [];
  filters = [];

  ngOnInit(): void
  {
  }

  remove_(lab)
  {
    var newStr;

    if(lab.includes('_'))
    {
      newStr   =lab.replace("_" , " ");
    }
    else
    {
      newStr = lab;
    }

    return newStr
  }

  closeSearch()
  {
    this.router.navigateByUrl('workspace/main')
  }

  clearSearch()
  {
    this.searchPage.clearSearch();
  }

  closeDrawer()
  {
    this.drawer.closerDrawer();
  }

  filterList: FilterObject[];
  selectedFilterList = {};

  empty = null;
  toSearchTable = [];
  @Output() emitState: EventEmitter<any> = new EventEmitter();
  typeList = [];
  stateList = [];
  clientList = [];

  emptyValue = "Pas de valeur";

  resetFilters()
  {
    let Query;
    this.searchValue = "";
    this.route.queryParams.subscribe((params) => {
      if (params.q.includes(" /")) {
        Query = params.q.slice(0, params.q.indexOf(" /"));
      }
    });

    if (Query)
        //this.router.navigate(searchRoute, {queryParams: {q: Query, d: localStorage.host_domain}});
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
        this.router.navigate(["/search"], {
          queryParams: { q: Query, d: localStorage.host_domain },
        })
      );

    this.searchPage.resetFilters();
    this.toSearchTable = this.searchResults;
    for (let ob of this.filters) {
      ob.selectedValue = "Pas de valeur";
    }

    this.selectedFilterList = {};
  }

  searchs(label, keywords)
  {
    let query;
    let fullQuery;

    this.route.queryParams.subscribe((params) =>
    {
      query = params.q;
    });

    if (label == "Type")
    {
      fullQuery = query + " /" + keywords;
    }
    else
    {
      fullQuery = query +" /"+ label+ "=" + keywords;
    }

    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
      this.router.navigate(["/search"], {
        queryParams: { q: fullQuery, d: localStorage.host_domain },
      })
    );
  }

  filterQuery;

  filterBy(label, valeur, max)
  {
    this.filterQuery = this.route.snapshot.queryParams["q"];
    let Query;
    let empty = this.allValues;


    if (valeur === empty)
    {
      Query = true;
      return Query;
    }
    else if (max)
    {
      if (max.length === 1)
        Query = true;
        return Query;
      }
      else if (this.filterQuery.includes(" /"+label))
      {
        Query = this.filterQuery.indexOf(valeur) !== -1;
        return Query ? true : false;
      }
      else if (this.filterQuery.includes(" /"+label))
      {
        Query = this.filterQuery.indexOf(valeur) !== -1;
        return Query ? true : false;
      }
      else if (this.filterQuery.includes(" /" + valeur) && label === "Type")
      {
        Query = this.filterQuery.indexOf(valeur) !== -1;
        return Query ? true : false;
      }
      return Query;
    }
}
