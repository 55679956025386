import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ListItem } from 'src/design-system/core/interfaces/list-item';
import { environment } from 'src/environments/environment';
import { GDocService, GedService } from 'src/modules/app-common/services';
import { DocumentService } from 'src/modules/documents/services/document.service';
import { SpaceService } from 'src/modules/spaces/services';
import { WorkplaceService } from '../../services/workplace.service';


const themePath = environment.themePath;

@Component({
   selector: 'app-recent-docs',
   templateUrl: `./${themePath}/recent-docs.component.html`,
   styleUrls: [`./${themePath}/recent-docs.component.scss`]
})

export class RecentDocsComponent implements OnInit, OnChanges {
   @Input('docRecents') recentFiles
   @Input('title') title

   static instance: RecentDocsComponent;

   linkdrive = environment.drivelink;
   displayProperties: ListItem[] = [];
   recentDocs: ListItem[] = [];
   spaceDesactivated: boolean =  !environment.ESPACE_ACTIVATED;


   constructor(private gdocService: GDocService,
      private spaceService: SpaceService,
      private gedService: GedService,
      private router: Router,
   ) {
      RecentDocsComponent.instance = this;
   }

   ngOnInit(): void {
   }

   ngOnChanges(changes: SimpleChanges): void {
      Object.values(this.recentFiles).forEach(item => {
         this.setDisplayProperties(item['displayProperties']);
      });
   }

   setDisplayProperties(results) {
      this.displayProperties.push(results[0])
   }

   openDocum(doc) {
      this.spaceService.openDocTab(doc);
   }

   toExternalLink(f) {
      window.open(f.webViewLink, '_blank');
   }

   toDrive() {
      window.open(this.linkdrive, '_blank');
   }

   rowParent(parentId) {
      this.gdocService.getMyDriveId().then((res) => {
         if (parentId.parents == res) {
            this.router.navigate(["/s/space/root"]);
         }
         else {
            if (parentId.parents == null) {
               this.router.navigate(["/workspace/main"]);
            }
            else {
               this.router.navigate(['/s/space/' + parentId.parents], { queryParams: { d: localStorage.host_domain } });
            }
         }
      });
   }


   async backToSpace(docId) {
      let gedDocument = await this.gedService.getSpaceDoc(docId);
      if (gedDocument) {
         let url;
         if (!this.spaceDesactivated) {
           url = "s/space/" + gedDocument['uid'];
         }
         else {
           url = "explorer/folder/" + gedDocument['uid'];
         }
         this.router.navigate([url]);
      }
   }


   rowParentList(parentId) {
      this.gdocService.getMyDriveId().then((res) => {
         if (parentId == res) {
            this.router.navigate(["/s/space/root"]);
         }
         else {
            if (parentId == null) {
               this.router.navigate(["/workspace/main"]);
            }
            else {
               this.router.navigate(['/s/space/' + parentId], { queryParams: { d: localStorage.host_domain } });
            }
         }
      });
   }
}
