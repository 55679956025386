import { FlaggingService } from './../../services/flagging.service';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GDocService, GedService, } from 'src/modules/app-common/services';
import { DocumentService } from '../../services/document.service';
import { Location } from '@angular/common';
import { WorkflowService } from 'src/modules/workflow/services';
import { WorkflowAction } from 'src/modules/workflow/models';

import { Workflow, WorkflowRole } from "src/modules/workflow/models";
import { PDocEventsService } from 'src/modules/p-doc-envents/p-docs-events.service';
import { AuthService } from 'src/modules/user/services';
import { filter, tap } from 'rxjs/operators';
import { Crumb } from 'src/modules/app-common/components/bread-crumb/bread-crumb.component';
import { IEvent, IOption } from '../../models';

const themePath = environment.themePath;

@Component({
  selector: 'app-document-page',
  templateUrl: `./${themePath}/document-page.component.html`,
  styleUrls: [`./${themePath}/document-page.component.scss`]
})
export class DocumentPageComponent implements OnInit
{

  @ViewChild('rightDrawer') public rightDrawer: MatDrawer;
  @ViewChild('toggledDrawer') public toggledDrawer: MatDrawer;
  @Output() emitState: EventEmitter<any> = new EventEmitter();

  showAddDoc :boolean = false
  title;
  path='/'
  docId;
  docData
  history: string[] = [];
  isToggled :boolean = false;
  docInfo :boolean = false;
  documentCurent ;
  inGed = false;
  currentTab: number = 0;
  step: number = 0;
  reloadDoc = false;
  isSideBarOpened: boolean = false;
  moreDetailsModal: boolean = false;
  doUnlike: boolean = false;
  deleteDocModal : boolean = false;
  detacheDocModal : boolean = false;
  docDocumentModal: boolean = false;
  isLoading : boolean = true;
  currentName : string;
  bc: Crumb[] = [];
  workflowActive: boolean = false;
  waitWorkflow: boolean = false;
  selctedWindow: number = 0;
  spaceDesactivated: boolean =  !environment.ESPACE_ACTIVATED;
  docIsPublished : boolean = false;
  lastFlaggingEvent: IEvent = null
  flagModal : boolean = false;
  flagOptions: IOption[] = [];
  verifyOptions: IOption[] = [];
  verifyModal: boolean= false;
  currentUser
  isFlagSubmitButtonEnabled() {
    return this.flagOptions.some(option => option.selected)
  }
  isVerifySubmitButtonEnabled() {
    return this.verifyOptions.every(option => option.selected)
  }
  onSubmitFlag() { 
    this.flaggingService.flag(this.docId, 

      this.flagOptions
      .filter(option => !!option?.selected)
      .map(option=>{
        delete option.selected
        return option
      })
      ).pipe(tap(event => {
      this.flagModal = false
      this.updateFlagginEvent(event)
    }
      )).subscribe()
  }

  initFlagOptions() {
    this.flagOptions = this.flaggingService.flagOptions.map(option => {
      return { option, selected: false }
    })
  }
  onSubmitVerify() {
    this.flaggingService.verify(this.docId,
      this.verifyOptions
        .filter(option => !!option?.selected)
        .map(option => {
          delete option.selected
          return option
        })
    ).pipe(tap(event => {
      this.verifyModal = false
      this.updateFlagginEvent(event)
    }
    )).subscribe()

  }

  initVerifyOptions() {
    this.verifyOptions = this.flaggingService.verifyOptions.map(option => {
      return { option, selected: false }
    })
  }
  dataDelete={
    function: 'delete',
    title: environment.stringsFile.deleteDocTitle,
    subtitle : environment.stringsFile.deleteDocSubtitle,
    parentComponent:this,
    inGed: this.inGed
  }

  dataDetach={
    function: 'detach',
    title: environment.stringsFile.detachDocTitle,
    subtitle : environment.stringsFile.detachDocSubtitle,
    parentComponent:this,
  }
  dataAdd = null
  docSpace: boolean;
  spaceParent: any;
  typeDoc : string ;
  isDocLinks : boolean = false;
  canEdit:boolean = false;
  canDelete:boolean = false;
  isExtention: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private gdocService: GDocService,
    private documentService:DocumentService ,
    private gedService: GedService ,
    private cdr: ChangeDetectorRef,
    private workflowService: WorkflowService,
    private location: Location,
    private pDocsEventsService: PDocEventsService,
    private auth: AuthService,
    private flaggingService: FlaggingService

    )
  {
    this.router.events.subscribe((event) =>
    {
      if (event instanceof NavigationEnd)
      {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  iseditMeta : boolean = false;

  toggleEdit(event: Event) {
    event.stopPropagation();
    this.iseditMeta = !this.iseditMeta;
    this.cdr.detectChanges();
    // Manually trigger change detection
  }
  flag() {
    this.flagModal = true
  }
  verify() {
    this.verifyModal = true

    // this.flaggingService.verify(this.docId, null).pipe(tap(event => {
    //   this.lastFlaggingEvent = event
    // }
    // )).subscribe()
  }
  updateFlagginEvent(event) {
    this.lastFlaggingEvent = event
  }
  resetFlag() {
    this.flagModal = false
  }
  ngOnInit(): void
  {
    this.auth.profileSubject.pipe(
      tap(profile => this.currentUser = profile)
    ).subscribe()
    this.initFlagOptions()
    this.initVerifyOptions()

    this.route.params.forEach((params: any) =>
    {
      if ((params?.type === "grid" || params?.type === "table") &&  params?.flagged !== "flagged") {
        this.currentTab = 2;
      }
      if (params?.extention === "extention" ) {
        this.isExtention = true;
      }
      console.log('param is : ', params)
      if (params?.docId) {
        this.flaggingService.status(params?.docId).pipe(tap(
          events => {
            this.updateFlagginEvent(events[0])
          }
        )).subscribe()
      }
      let id = params["docId"];
      this.getDocCurent(id);
      this.SpaceDos(id);


      this.gdocService.getFile(id)
        .then(data =>
        {
          if(data)
          {
            this.canEdit = data.capabilities.canEdit;
            this.canDelete = data.capabilities.canDelete;
            this.docData = data ;
            this.docId = data["id"];
            this.title = data["name"];
            this.titleService.setTitle(this.title);
            this.path += this.title +''+ this.documentService.getDocReader(data.mimeType,true);

            localStorage.setItem('path',this.path);
            localStorage.setItem('file',data);
            localStorage.setItem("webViewLink",data.webViewLink); //line not needed; a revoir

            const bcString = localStorage.getItem('bc'); // Retrieve the JSON string from localStorage
            if (bcString) {
              this.bc = JSON.parse(bcString);
              this.bc.push(
                new Crumb(this.title, (event, self) => {
                })
              );// Parse the JSON string into a crumb object
            }
          }
      })

    });

    let section = document.getElementById('lytcontent');
    if (section)
    {
      section.style.zIndex = '1030';
    }
  }

  Navigate(elem: HTMLElement)
  {
    elem.scrollIntoView({ behavior: 'smooth' });
  }

  openDialogInfo()
  {
    this.moreDetailsModal === true;

    this.route.params.forEach((param) =>
    {
      if (param["docId"])
      {
        //this.parentId = "root";
        this.route.queryParams.subscribe((params) =>
        {
          this.router.navigate([], {
            queryParams: { view: 'Infos' },
            queryParamsHandling: "merge",
          });
        });
      }
    });
  }


  toggleGedBar()
  {
    this.rightDrawer.close();
    //this.toggledDrawer.open();
    this.isToggled = true;
  }
  isShowBtn : boolean = true;


  getWorkflowActive(){
    if(this.documentCurent?.content?.data?.uid){
      this.workflowService
        .getsteps(this.documentCurent?.content?.data?.uid)
        .then((data) => {
          if(data.content?.options?.can_edit === false){
            this.docIsPublished =true;
          }
          if (Object.keys(data?.content).length > 0) 
          { 
            this.workflowActive = true;
          }else{
            this.waitWorkflow = true; 
          }
        });
    }else{
      this.waitWorkflow = true; 
    }
  }

  //get Curent document avec son workflow
 async getDocCurent(id: string)
 {
    this.documentCurent = await this.gedService.getDoc(id , null);
    this.getWorkflowActive();
    this.showWorkflow(id);

    if(this.documentCurent?.content?.data != null && typeof this.documentCurent?.content?.data == "object")
    {
        this.typeDoc = this.documentCurent?.content?.data?.type_document.html;
        this.inGed = true;
        this.isLoading = false;
        this.isShowBtn = false
    }
    else
    {
      this.inGed = false;
      this.isLoading = false;
      this.isShowBtn = true
    }
  }

   //open toggled Drawer
  openGedBar()
  {
    //this.rightDrawer.open();
    this.toggledDrawer.close();
    this.isToggled = false;
  }

  closeDocInfo()
  {
    this.docInfo = false;
  }

  openDocInfo()
  {
    this.docInfo = true;
  }

  updateDocBar(event)
  {
    this.reloadDoc = event?.reloadDoc || false;
  }

   //to back a last page
  goback()
  {
    this.route.params.subscribe(params => {
      if (params['flagged'] === "flagged") {
        // "flagged" est un route parameter, effectuez l'action correspondante
        this.router.navigate(['../../../'], { relativeTo: this.route });
      } else {
        // "flagged" n'est pas égal à "flagged", utilisez queryParams
        this.route.queryParams.subscribe(queryParams => {
          const encodedSearchParam = queryParams['search'];
          // Effectuez l'action correspondante avec "search" comme queryParams
          this.router.navigate(['../../'], { relativeTo: this.route, queryParams: { 'search': encodedSearchParam } });
        });
      }
    });
    
  }

  onSend(event: any)
  {
  }

  openPageHome()
  {
    this.router.navigateByUrl("/workspace/main");
  }

  goToGedApp()
  {
    this.router.navigateByUrl("/workspace/main/file/"+this.docId);
  }

  activeAccountMenu: boolean = false;

  toggleAccountMenu()
  {
   this.activeAccountMenu = !this.activeAccountMenu;
  }

  toggleSearch()
  {
    this.activeAccountMenu = false;
  }

 openInDrive()
 {
   //replace drive url with the real folder url
   this.route.params.forEach(params =>
    {
      let id = params["docId"];

      this.gdocService.getFile(id)
        .then(data =>
        {
          let doc = data;
          window.open(doc.webViewLink, "_blank");
        });
   })
 }

  detachFromGed()
  {
    this.documentService.detachFromGed()
  }

  deleteDoc()
  {
    this.documentService.deleteDoc(this.inGed)
  }

 updateDoc(event)
 {

   if(event.reloadDoc)
   {
     if(event.reloadDoc==true)
     {
        this.emitState.emit({reloadDoc:true});
     }
   }
 }

  actionsMenuItems = [];
  workflow : Workflow = null;

  showWorkflow(id: string) {

    this.workflowService.getWorkflow(id)
      .then((data) => {
        this.workflow = data;

        const actions = this.workflow.getActions();
        actions.forEach(action => {
          if (action.inMenu)
            this.actionsMenuItems.push(action);
        })
      });
  }

  /** execute workflow action */
  execAction(action: WorkflowAction): void
  {
    // display loading spinner
    this.isLoading = true;

    let docInfos = this.documentCurent.content.data;

    // then execute action
    this.workflowService
      .execAction(action, docInfos)
      .then((res) => {
        let url = this.router.url;

        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this.router.navigate([url]);
          });
      });
  }

  getData(data)
  {
    this.dataAdd = data
  }

  getDocLinks(event){
    this.isDocLinks = event;
  }

  async SpaceDos(docId)
  {
    let gedDocument = await this.gedService.getSpaceDoc(docId);


    if(gedDocument)
    {
      this.auth.profileSubject
      .pipe(
        filter(value => !!value),
        tap(user => {
          this.currentName = user.name
        }))
      .subscribe();
      this.docSpace = true;
      this.spaceParent  = gedDocument
      this.pDocsEventsService.viewDocumentEvent(
        this.documentCurent?.content?.data?.name,
        this.currentName,
        gedDocument.name
      );


    }
  }

  async backToSpace()
  {
    if (this.spaceParent)
    {
      let url = "s/space/" + this.spaceParent["uid"];
      this.router.navigate([url]);
    }
  }

  async backToFolder()
  {
    let parentId = await this.gdocService.getDocumentParent(this.docId);
    if (parentId) {
      let url;
      if (!this.spaceDesactivated) {
        url = "s/space/" + parentId;
      }
      else {
        url = "explorer/folder/" + parentId;
      }
      this.router.navigate([url]);
    }
  }
  detectChanges(){
    this.currentTab = 2;
    this.selctedWindow = this.currentTab - 1;
    this.cdr.detectChanges();
  }

  formateLongTitle(title) {

    // Définit la longueur maximale du titre avant de le raccourcir
    const maxLength = 40; // Change cette valeur selon tes besoins

    // Vérifie si la longueur du texte dépasse la limite définie
    if (title?.length > maxLength) {
      // Raccourcis le texte et ajoute des points de suspension à la fin
      const shortenedTitle = title.substring(0, maxLength - 3) + '...';

      // Remplace le contenu du titre par le titre raccourci
      return shortenedTitle;
    }

    return title;
  }
  
  goToOverview() {
    this.currentTab = 0;
  }

}
